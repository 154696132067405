import { onAuthStateChanged } from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import { auth, db } from "..";
import { useNavigate } from "react-router-dom";
import { collection, doc, onSnapshot, query, where } from "firebase/firestore";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { userActions, userSelector } from "../redux/slices/userSlice";
import { battlesActions } from "../redux/slices/battlesSlice";
import { processBattles } from "./Utils-Auth";
import { personalUserActions } from "../redux/slices/personalUserSlice";
import { displayToast } from "../helpers/app-helpers";

const AuthUserContext = createContext({
  authUser: null,
  isLoading: true
})

export default function useFirebaseAuth() {
  const [authUser, setAuthUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const reduxUser = useAppSelector(userSelector);

  const authStateChanged = async(user) => {
    setIsLoading(true);
    if (!user) {
      setAuthUser(null);
      setIsLoading(false);
      navigate('/');
      return;
    }
    setAuthUser({
      uid: user.uid,
      email: user.email
    })
    setIsLoading(false);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, authStateChanged);
    return () => {
      unsubscribe();
    }
  }, [])

  useEffect(() => {
    let userSub;
    let personalUserSub;
    let battlesSub;

    if (!authUser && userSub) userSub();
    if (authUser && !userSub) {
      userSub = onSnapshot(doc(db, "users", authUser.uid), async(result) => {
        console.log('user sub')
        if (result.data()) {
          if (result.data().level > reduxUser.level && reduxUser.xp !== 0) displayToast(dispatch, `Level Up! You are now level ${result.data().level}. Well Done!`, 5000);
          dispatch(userActions.setUser(result.data()));
          try {
            personalUserSub = onSnapshot(doc(db, "users", authUser.uid, "personalUsers", authUser.uid), async(doc) => {
              console.log('personalUser sub');
              if (doc.data()) dispatch(personalUserActions.setPersonalUser(doc.data()));
            })
          } catch (error) {
            console.error(error);
            userSub();
          }
        }
      })
    }

    if (!authUser && battlesSub) battlesSub();
    if (authUser && !battlesSub) {
      const q = query(collection(db, "battles"), where("userIds", "array-contains", authUser.uid));
      battlesSub = onSnapshot(q, (collection) => {
        let battles = [];
        collection.forEach((battle) => {
          battles.push(battle.data());
        });
        console.log('battles sub')
        battles.sort((a, b) => +new Date(b.startDate) - +new Date(a.startDate))
        dispatch(battlesActions.setBattles(battles));
        const onError = (() => battlesSub());
        processBattles(battles, authUser.uid, dispatch, onError);
      })
    }

    return () => {
      if (userSub) userSub();
      if (personalUserSub) personalUserSub();
      if (battlesSub) battlesSub();
    };
  }, [authUser])
  
  return {
    authUser,
    isLoading
  }
}

export function AuthUserProvider({ children }) {
  const auth = useFirebaseAuth();
  return <AuthUserContext.Provider value={auth}>{children}</AuthUserContext.Provider>
}

export const useAuth = () => useContext(AuthUserContext);