import { PersonalUserType } from "../types/PersonalUserType";

const KEY = 'pvp-health-local-storage'

export const saveToLocalStorage = (state: any) => {
  try {
    const consent = getConsentFromLocalStorage();
    localStorage.setItem(KEY, JSON.stringify({...state, consent}));
  } catch (e) {
    console.error(e);
  }
};

export const loadFromLocalStorage = () => {
  try {
    const stateStr = localStorage.getItem(KEY);
    return stateStr ? JSON.parse(stateStr) : undefined;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export const getConsentFromLocalStorage = () => {
  try {
    const stateStr = localStorage.getItem(KEY);
    const parsed =  stateStr ? JSON.parse(stateStr) : undefined;
    return parsed?.consent;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export const setConsentToLocalStorage = (consent: 'ALL' | 'NECESSARY') => {
  try {
    const currentStorage = loadFromLocalStorage();
    if (currentStorage) currentStorage.consent = consent;
    try {
      localStorage.setItem(KEY, JSON.stringify(currentStorage));
    } catch (error) {
      console.error(error);
    }
  } catch (error) {
    console.error(error); 
  }
};

export const deleteLocalStorage = () => {
  try {
    localStorage.removeItem(KEY);
  } catch (error) {
    console.error(error); 
  }
};

export const removeUserFromIDB = (personalUser: PersonalUserType) => {
  const indexedDB =
  window.indexedDB
  //@ts-ignore
  || window.mozIndexedDB
  //@ts-ignore
  || window.webkitIndexedDB
  //@ts-ignore
  || window.msIndexedDB
  //@ts-ignore
    || window.shimIndexedDB;

  const request = indexedDB.open('pvp-health', 1);
  request.onerror = function (event) {
    console.error("An error occurred withIndexedDB");
    console.error(event);
  }

  request.onsuccess = function() {
    const idb = request.result;
    const transaction = idb.transaction("personalUsers", "readwrite");
    const store = transaction.objectStore("personalUsers");

    store.delete(personalUser.id)
  }
}