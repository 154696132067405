import React, { ReactNode } from 'react';
import './Toast.scss';
import { useAppDispatch } from '../../redux/hooks';
import { appDataActions } from '../../redux/slices/appDataSlice';

const Toast: React.FC<{
  children: ReactNode;
  id: string;
}> = ({
  children,
  id
}) => {

  const dispatch = useAppDispatch();

  return (
    <div className="toast" onClick={() => dispatch(appDataActions.removeToast(id))}>
      {children}
    </div>
  )
}

export default Toast;