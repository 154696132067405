import { doc, getDoc, setDoc, writeBatch } from "firebase/firestore";
import { db } from "..";
import { BattleType } from "../types/BattleType";
import { UserType } from "../types/UserType";
import { PersonalUserType } from "../types/PersonalUserType";

export const createUser = async(userId: string, username: string) => {
  const user: UserType = {
    id: userId,
    username,
    lowerUsername: username.toLowerCase(),
    avatar: {
      id: '1',
      colors: {
        hairSecondary: '#26262c',
        cardBackground: '#7d7736',
        shoes: '#1e4670',
        clothes: '#26588c',
        hairMain: '#845d43',
        clothesShadows: 'color-mix(in srgb, #26588c, black 20%)',
        shoesHighlights: 'color-mix(in srgb, #1e4670, white 30%)',
        skin: '#eab276',
        eyes: '#735326',
        cardBackgroundShadow: 'color-mix(in srgb, #7d7736, black 10%)',
        skinShadow: 'color-mix(in srgb, #eab276, black 15%)',
      }
    },
    level: 1,
    xp: 0
  }

  const personalUser: PersonalUserType = {
    id: userId,
    topics: [],
    fcmToken: false,
    targetNotification: false,
    hideWelcomeMessage: false
  }

  const batch = writeBatch(db);
  const usernameDoc = doc(db, "usernames", username.toLowerCase());
  const userDoc = doc(db, "users", userId);
  const personalUserDoc = doc(db, "users", userId, "personalUsers", userId);

  batch.set(usernameDoc, {username})
  batch.set(userDoc, user);
  batch.set(personalUserDoc, personalUser)

  return await batch.commit();
}

export const addBattleToDB = async(battle: BattleType) => {
  let returnValue;
  try {
    await setDoc(doc(db, "battles", battle.id), battle)
      .then(async() => {
        await getDoc(doc(db, "battles", battle.id))
          .then((data) => {
            returnValue = data.exists();
          })
      })
  } catch (error) {
    console.error(error);
  }
  return returnValue;
}