import Resizer from 'react-image-file-resizer';
import { createPhotoMetadata, displayToast } from '../../../../../helpers/app-helpers';
import { ref, uploadBytes } from 'firebase/storage';
import { storage } from '../../../../..';
import { BattleType } from '../../../../../types/BattleType';


export const onFileSubmit = async(file: any, dispatch: any, setNewPhoto: any, setTempPhotoURL: any) => {
  if (file && file['type'].split('/')[0] === 'image') {
    const resized = await handlePhotoResizing(file);
    setNewPhoto(resized);
    const imageSrc = URL.createObjectURL(resized);
    setTempPhotoURL(imageSrc)
  }
  else displayToast(dispatch, 'Only image files are accepted.')
}

export const handlePhotoResizing = async(photo: any) => {
  let resized = photo;
  while (resized.size > 700000) {
    resized = await resizeFile(photo) as Blob;
  }
  return resized;
}

const resizeFile = (file: any) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "JPEG",
      75,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });
}

export const uploadPhoto = async(
  newPhoto: File,
  uuid: any,
  dispatch: any,
  battle: BattleType
) => {
  const metadata: any = createPhotoMetadata(battle.challenger.id, battle.opponent.id);
  const targetConfirmationsRef = ref(storage, `targetConfirmations/${newPhoto.name + uuid()}`);

  try {
    const uploaded = await uploadBytes(targetConfirmationsRef, newPhoto, metadata);
    if (uploaded) {     
      displayToast(dispatch, 'Photo uploaded');
      return uploaded;
    } else {
      displayToast(dispatch, 'Image failed to save. Please try again.');
    }
  } catch (error) {
    console.error('error', error)
    displayToast(dispatch, 'Image failed to save. Please try again.');
  }
}