import { getToken, onMessage } from "firebase/messaging";
import { cloudMessaging, db } from "..";
import { doc, setDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStoreFcmToken } from "../database/read";
import { PersonalUserType } from "../types/PersonalUserType";

const FCM_TOKEN_COLLECTION = "fcmTokens";

async function requestNotificationsPermission(personalUser: PersonalUserType) {
  const permission = await Notification.requestPermission()
    if (permission === 'granted') {
      await saveMessagingDeviceToken(personalUser);
    } else {
      console.log('Unable to get permission to notify.')
    }
}

export async function saveMessagingDeviceToken(personalUser: PersonalUserType) {
  const vapidKey = window.location.href.includes('pvphealth')
    ? "BIGDVoyLUZz3DUGEFXhsn1He99NT6xVoAtiNqkXBeUGTaO1q6w_s_pYhzx2LWvYSFhYfmKIOqzkxZ5PzUaWjlwo"
    : "BK42RVj1e_4StwFyKkGJNwUt_9G0bIQZHA4qgEplzjLQNzEiCDofS4jE53p3X0nw5byg5heTNXSTGzGgEnuzgpU"
  const msg = await cloudMessaging()
  let fcmToken;
  try {
    fcmToken = await getToken(msg, {vapidKey: vapidKey})
  } catch (error) {
    console.error(error);
    return;
  }

  if (fcmToken) {
    let storedFcmToken;
    const result = await getStoreFcmToken(personalUser.id) as any;
    if (result) storedFcmToken = result.fcmToken;
    if (fcmToken !== storedFcmToken && storedFcmToken) {
      console.log('Diff: ', fcmToken, storedFcmToken)
      await temporarilyUnsubscribeUserFromAllTopics(storedFcmToken, personalUser);
    }

    let tokenRef: any;
    try {
      tokenRef = doc(db, FCM_TOKEN_COLLECTION, personalUser.id);
    } catch (error) {
      console.error('error', error)
    }

    await setDoc(tokenRef, { fcmToken });
    if (fcmToken !== storedFcmToken && storedFcmToken) {
      await resubscribeUserToAllTopics(fcmToken, personalUser);
    }
    onMessage(msg, (message) => {
      console.log('onMessage', message)
      if (
        message?.data
        && (
          personalUser.topics.includes(message.data.topic)
          || personalUser.id === message.data.targetUserId
        )
      ) {
        try {
          new Notification(message.data.title, {body: message.data.body})
        } catch (error) {
          console.error('Mobile foreground notification not')
        }
      }
    })
  } else {
    requestNotificationsPermission(personalUser);
  }
  return fcmToken;
}

const temporarilyUnsubscribeUserFromAllTopics = async(token: string, personalUser: PersonalUserType) => {
  if (personalUser.topics.length > 0) {
    try {
      const func = httpsCallable(getFunctions(), 'temporarilyUnsubscribeUserFromAllTopics');
      await func({token, personalUser});
    } catch (error) {
      console.error(error);
    }
  }
}

const resubscribeUserToAllTopics = async(token: string, personalUser: PersonalUserType) => {
  if (personalUser.topics.length > 0) {
    try {
      const func = httpsCallable(getFunctions(), 'resubscribeUserToAllTopics');
      await func({token, personalUser});
    } catch (error) {
      console.error(error);
    }
  }
}

export async function subscribeUserToTopic(token: string, userId: string, remainingTopics: string[], topic: string) {
  try {
    const func = httpsCallable(getFunctions(), 'subscribeUserToTopic');
    await func({token, userId, remainingTopics, topic});
  } catch (error) {
    console.error(error)
  }
}

export async function unsubscribeUserFromTopic(token: string, userId: string, remainingTopics: string[], topic: string) {
  try {
    const func = httpsCallable(getFunctions(), 'unsubscribeUserFromTopic');
    await func({token, userId, remainingTopics, topic});
  } catch (error) {
    console.error(error)
  }
}

export async function testTopic(data: any) {
  try {
    const func = httpsCallable(getFunctions(), 'testTopic');
    await func(data);
  } catch (error) {
    console.error(error)
  }
}