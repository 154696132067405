import React from 'react';
import './BattleSummary.scss';
import CreateBattleCard from '../create-battle-card/CreateBattleCard';
import { BattleCreationType } from '../../../../types/BattleCreationType';
import TargetsSummary from '../targets-summary/TargetsSummary';
import { TargetType } from '../../../../types/TargetType';

const BattleSummary: React.FC<{
  battle: BattleCreationType;
  setTargetToEdit: (target: TargetType) => void;
}> = ({
  battle,
  setTargetToEdit
}) => {

  return (
    <CreateBattleCard firstLine="Battle Summary">
      <p><span>Start Date:</span> {battle.startDate}</p>
      {battle.numDays && <p><span>Number of Days:</span> {battle.numDays}</p>}
      {battle.prize && <p><span>Prize/Forfeit:</span> {battle.prize}</p>}
      {battle.description && <p><span>Description:</span> {battle.description}</p>}

      <TargetsSummary setTargetToEdit={setTargetToEdit}/>
    </CreateBattleCard>
  )
}

export default BattleSummary;