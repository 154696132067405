import React from 'react';
import './TargetsSummary.scss';
import { useAppSelector } from '../../../../redux/hooks';
import { appDataSelector } from '../../../../redux/slices/appDataSlice';
import { TargetType } from '../../../../types/TargetType';
import TempTarget from './temp-target/TempTarget';

const TargetsSummary: React.FC<{
  setTargetToEdit: (target: TargetType) => void;
}> = ({
  setTargetToEdit
}) => {

  const { tempTargets } = useAppSelector(appDataSelector);

  const onTargetEdit = (target: TargetType) => {
    setTargetToEdit(target);
    // setTargetEditMode(true);
    // setActiveSection('TARGETS');
  }

  return (
    <div className="targets-summary">
      <h5>Targets</h5>
      {tempTargets.map((target: TargetType, index: number) => {
        return (
          <TempTarget
            key={index}
            target={target}
            edit={() => onTargetEdit(target)}
          />
        )}
      )}
    </div>
  )
}

export default TargetsSummary;