import React from 'react';
import './Opponents.scss';
import Card from '../../../ui-components/card/Card';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { battleCreationActions } from '../../../redux/slices/battleCreation';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ChevronRight  } from '../../../ui-components/icon/svgs/chevronRight.svg';
import { appDataSelector } from '../../../redux/slices/appDataSlice';
import { ContestantType } from '../../../types/ContestantType';

const Opponents = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { previousOpponents } = useAppSelector(appDataSelector);

  const onOpponentClick = (opponent: ContestantType) => {
    dispatch(battleCreationActions.addOpponent(opponent));
    navigate('/start-new-battle/start-new-battle-with');
  }

  return (
    <Card
      backUrl="/start-new-battle"
      variant="MENU"
      title="Start New Battle"
    >
      <div className="opponents">
        <p>Choose Opponent</p>

        {previousOpponents.length > 0 && (
          <div className="opponents__opponents-list">
            {previousOpponents.map((opponent) => {
              return (
                <div
                  key={opponent.id}
                  className="opponents__opponents-list__opponent"
                  onClick={() => onOpponentClick(opponent)}
                >
                  {opponent.username}
                  <ChevronRight />
                </div>
              )
            })}
          </div>
        )}

        {previousOpponents.length < 1 && <p>No opponents found.</p>}
      </div>
    </Card>
  )
}

export default Opponents;