import React from 'react';
import './TempTarget.scss';
import { TargetType } from '../../../../../types/TargetType';
import { ReactComponent as Trash  } from '../../../../../ui-components/icon/svgs/trash.svg'
import { ReactComponent as Edit  } from '../../../../../ui-components/icon/svgs/edit.svg'
import { ReactComponent as Copy  } from '../../../../../ui-components/icon/svgs/copy.svg'
import { useAppDispatch } from '../../../../../redux/hooks';
import { appDataActions } from '../../../../../redux/slices/appDataSlice';

const TempTarget: React.FC<{
  target: TargetType
  edit: (event: any) => void
}>  = ({
  target,
  edit
}) => {

  const dispatch = useAppDispatch();

  return (
    <div className="temp-target">
      <p>{target.title}</p>
      <div className="temp-target__icons">
        <Copy fill="var(--dark)" onClick={() => dispatch(appDataActions.duplicateTempTarget(target))} />
        <Edit fill="var(--dark)" onClick={edit} />
        <Trash onClick={() => dispatch(appDataActions.deleteTempTargetById(target.id))} />
      </div>
    </div>
  )
}

export default TempTarget;