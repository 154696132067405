export const defaultAvatarColors = {
  cardBackgroundShadow: "color-mix(in srgb, #544e12, black 10%)",
  hairSecondary: "#26262c",
  skinShadow: "color-mix(in srgb, #eab276, black 15%)",
  cardBackground: "#544e12",
  hairMain: "#845d43",
  skin: "#eab276",
  eyes: "#5b4820",
  shoes: "#323349",
  shoesHighlights: "color-mix(in srgb, #323349, white 30%)",
  clothes: "#36344b",
  clothesShadows: "color-mix(in srgb, #36344b, black 20%)"
}