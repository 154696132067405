import React, { useState } from 'react';
import './RepeatBattle.scss';
import Card from '../../../../ui-components/card/Card';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { battleCreationActions, battleCreationSelector } from '../../../../redux/slices/battleCreation';
import { convertCurrentBattleToArrays, displayToast } from '../../../../helpers/app-helpers';
import { BattleCreationType } from '../../../../types/BattleCreationType';
import { battlesSelector } from '../../../../redux/slices/battlesSlice';
import { BattleType } from '../../../../types/BattleType';
import { userSelector } from '../../../../redux/slices/userSlice';
import BattleLine from '../../../../components/battle-line/BattleLine';
import { useNavigate } from 'react-router-dom';
import { appDataActions } from '../../../../redux/slices/appDataSlice';

const RepeatBattle = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const battleCreation = useAppSelector(battleCreationSelector);
  const battles = useAppSelector(battlesSelector);
  const user = useAppSelector(userSelector);

  const [showBetweenSelected, setShowBetweenSelected] = useState(false);

  const onBattleClick = async(battle: BattleType) => {

    if (battle) {
      const battleCreationObj: BattleCreationType = {
        title: battle.title,
        startDate: battle.startDate,
        numDays: battle.numDays,
        description: battle.description,
        prize: battle.prize,
        teamBattle: battle.teamBattle,
        challenger: {
          id: user.id,
          username: user.username,
          hp: 1,
          zeroHpDate: null
        },
        opponent: battleCreation.opponent
      }

      dispatch(battleCreationActions.setBattleWithoutOpponent(battleCreationObj));
      generateTempTargets(battle);
      navigate('/create-battle');
    } else {
      displayToast(dispatch, "An error occurred or battle details are missing. Please try again.");
    }
  }

  const generateTempTargets = (battle: BattleType) => {
    const convertedBattle = convertCurrentBattleToArrays([battle], battle.id);
    dispatch(appDataActions.clearTempTargets())
    const hasDays = convertedBattle.days.length > 0;
    const hasBlockTargets = convertedBattle.blockTargets.length > 0;

    if (hasDays) {
      for (const target of convertedBattle.days[0].targets) {
        dispatch(appDataActions.addTempTarget({
          id: target.id,
          title: target.title,
          description: target.description ? target.description : '',
          format: target.format,
          frequency: target.frequency,
          isPhotoRequired: target.isPhotoRequired
        }));
      }
    }
    if (hasBlockTargets) {
      for (const target of convertedBattle.blockTargets) {
        dispatch(appDataActions.addTempTarget({
          id: target.id,
          title: target.title,
          description: target.description ? target.description : '',
          format: target.format,
          frequency: target.frequency,
          isPhotoRequired: target.isPhotoRequired
        }));
      }
    }
  }  

  return (
    <>
      <Card
        backUrl="/start-new-battle/start-new-battle-with"
        variant="MENU"
        title="Start New Battle"
      >
        <div className="repeat-battle">
          <h4>Previous Battles</h4>


          {battles.length > 0 && (
            <div className="repeat-battle__battle-list">
              <input type="checkbox" checked={showBetweenSelected} name="betweenSelected" onChange={() => setShowBetweenSelected(!showBetweenSelected)} />
              <label htmlFor="betweenSelected">Only show battles with {battleCreation.opponent.username}</label>
              {battles.map((battle) => {
                if (showBetweenSelected && battle.userIds.includes(battleCreation.opponent.id)) {
                  return (
                    <BattleLine
                      key={battle.id}
                      battle={battle}
                      onBattleClick={() => onBattleClick(battle)}
                    />
                  )
                } else if (!showBetweenSelected) {
                  return (
                    <BattleLine
                      key={battle.id}
                      battle={battle}
                      onBattleClick={() => onBattleClick(battle)}
                    />
                  )
                }
              })}
            </div>
          )}

          {battles.length < 1 && <p>No battles found.</p>}
        </div>
      </Card>
    </>
  )
}

export default RepeatBattle;