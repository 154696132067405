import { TargetFormat } from "../enums/enums";

export const convertBooleanToString = (input: any) => {
  if (input === true) return 'true';
  if (input === false) return '';
  else return input;
}

export const getExistingWinner = (userTarget: any, otherUserTarget: any) => {
  let winner;
  const userTargetInput = convertBooleanToString(userTarget.input);
  const otherUserTargetInput = convertBooleanToString(otherUserTarget.input);

  if (userTargetInput > otherUserTargetInput) winner = userTarget.userId;
  else if (userTargetInput < otherUserTargetInput) winner = otherUserTarget.userId;
  else winner = 'draw';

  return winner;
}

export const getContestantHp = (userId: string, existingWinner: string, input: any, otherUser: any, format: TargetFormat) => {
  let plusMinus = {
    user: 0,
    otherUser: 0 
  }
  const numberValue = format === TargetFormat.NUMBER ? 2 : 1;
  const userInput = convertBooleanToString(input);
  const otherUserInput = convertBooleanToString(otherUser.input);
  
  if (existingWinner === 'draw') {
    if (userInput > otherUserInput) {
      plusMinus.user += 1;
      plusMinus.otherUser -= 1;
    }
    else if (userInput < otherUserInput) {
      plusMinus.user -= 1;
      plusMinus.otherUser += 1;
    }
  } 

  if (existingWinner === userId) {
    if (userInput < otherUserInput) {
      plusMinus.user -= numberValue;
      plusMinus.otherUser += numberValue;
    }
    if (userInput === otherUserInput) {
      plusMinus.user -= 1;
      plusMinus.otherUser += 1;
    }
  }

  if (existingWinner === otherUser.userId) {
    if (userInput > otherUserInput) {
      plusMinus.user += numberValue;
      plusMinus.otherUser -= numberValue;
    }
    if (userInput === otherUserInput) {
      plusMinus.user += 1;
      plusMinus.otherUser -= 1;
    }
  }

  return plusMinus;
}