import dayjs from "dayjs";
import { convertBattleToArrays } from "../../../helpers/app-helpers";
import { BattleType } from "../../../types/BattleType";
import { TargetType } from "../../../types/TargetType";
import { BattleStatus } from "../../../enums/enums";


export const findTargets = (battles: BattleType[]) => {
  let targets = [];
  let blockTargets = [];
  const today = dayjs().format('YYYY-MM-DD');

  for (const originalBattle of battles) {
    const battle = convertBattleToArrays(originalBattle);
    let battleTargets = [];
    let dayKey = '';
    for (const el of battle.days) {
      if (el.date === today) {
        battleTargets.push(...el.targets);
        dayKey = el.dayKey;
      };
    }

    targets.push(...battleTargets.map((el: TargetType) => {
      return {...el, battle, dayKey}
    }));

    for (const blockTarget of battle.blockTargets) {
      blockTargets.push({...blockTarget, battle})
    }
  }

  return {
    targets,
    blockTargets
  };
}

export const getPendingBattles = (battles: BattleType[]) => {
  return battles.filter(battle => battle.status === BattleStatus.PENDING);
}