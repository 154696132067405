import React, { useState } from 'react';
import './StartNewBattle.scss';
import { useNavigate } from 'react-router-dom';
import Card from '../../ui-components/card/Card';
import NavigationButton from '../../ui-components/navigation-button/NavigationButton';

const StartNewBattle = () => {

  const navigate = useNavigate();

  const [showDefaultBtn, setShowDefaultBtn] = useState(true);

  return (
    <Card
      title="Start New Battle"
      backUrl="/home"
      onMouseOut={() => setShowDefaultBtn(true)}
    >
      <NavigationButton
        isDefault={showDefaultBtn}
        onMouseOver={() => setShowDefaultBtn(false)}
        onClick={() => navigate('/start-new-battle/opponents')}
      >
        Previous Opponents
      </NavigationButton>

      <NavigationButton
        onMouseOver={() => setShowDefaultBtn(false)}
        onClick={() => navigate('/start-new-battle/add-contestant')}
      >
        Add a New Opponent
      </NavigationButton>
    </Card>
  )
}

export default StartNewBattle;