import React, { useEffect, useRef, useState } from 'react';
import './BattleCalender.scss';
import DayWidget from './day-widget/DayWidget';
import { ContestantType } from '../../../types/ContestantType';
import { BattleType } from '../../../types/BattleType';
import { DayType } from '../../../types/DayType';
import { TargetType } from '../../../types/TargetType';
import TargetLine from '../../../components/target-line/TargetLine';
import { useNavigate } from 'react-router-dom';
import cornerPaperLB from './cornerPaperLB.png';
import cornerPaperRB from './cornerPaperRB.png';
import cornerPaperRT from './cornerPaperRT.png';
import cornerPaperLT from './cornerPaperLT.png';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { appDataActions } from '../../../redux/slices/appDataSlice';
import { BattleStatus, TargetFormat } from '../../../enums/enums';
import BattleCompletePage from '../../battle-complete-page/BattleCompletePage';
import Avatar from '../../../components/avatar/Avatar';
import { userSelector } from '../../../redux/slices/userSlice';
import HealthBar from '../../../components/health-bar/HealthBar';
import { displayToast, getCurrentUserContestantObj } from '../../../helpers/app-helpers';
import { UserType } from '../../../types/UserType';
import Checkbox from '../day-view/battle-card/checkbox/Checkbox';
import dayjs from 'dayjs';
import { ContestantTargetType } from '../../../types/ContestantTargetType';
import { ReactComponent as Plus } from '../../../ui-components/icon/svgs/plus.svg';
import { ReactComponent as ChevronUp } from '../../../ui-components/icon/svgs/chevronUp.svg';
import { ReactComponent as Calendar } from '../../../ui-components/icon/svgs/calendar.svg';
import { ReactComponent as Camera } from '../../../ui-components/icon/svgs/camera.svg';
import { ReactComponent as Hash } from '../../../ui-components/icon/svgs/hash.svg';
import { ReactComponent as CheckboxTarget } from '../../../ui-components/icon/svgs/checkbox.svg';
import { ReactComponent as Target } from '../../../ui-components/icon/svgs/target.svg';
import { ReactComponent as Present } from '../../../ui-components/icon/svgs/present.svg';
import { ReactComponent as Book } from '../../../ui-components/icon/svgs/book.svg';
import Spinner from '../../../ui-components/spinner/Spinner';
import Input from '../../../ui-components/input/Input';
import { updateBlockCheckboxTarget, updateBlockNumberTarget } from '../../../database/update';
import { convertBooleanToString } from '../../../database/database-utils';

const BattleCalender: React.FC<{
  days?: DayType[];
  blankDays?: number;
  battle: BattleType
  otherContestant: ContestantType;
  otherUser: UserType;
  setActiveView: (event: 'CALENDER' | 'DAY') => void
}> = ({
  days,
  blankDays,
  battle,
  otherContestant,
  otherUser,
  setActiveView
}) => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const textareaRef = useRef<any>();
  const user = useAppSelector(userSelector);
  const hasDays = battle.days.length > 0;
  const hasBlockTargets = battle.blockTargets.length > 0;
  const isUserChallenger = user.id === battle?.challenger.id;

  const [sortedDays, setSortedDays] = useState<DayType[]>([]);
  const [userBlockToShowId, setUserBlockToShowId] = useState<string>('');
  const [otherContestantBlockToShowId, setOtherContestantBlockToShowId] = useState<string>('');
  const [isUpdatingTarget, setIsUpdatingTarget] = useState(false);
  const [blockTargets, setBlockTargets] = useState<TargetType[]>([])

  useEffect(() => {
    dispatch(appDataActions.setBackgroundClass('app__background app__background--cabin'));
    return () => {dispatch(appDataActions.setBackgroundClass('app__background'))};
  }, [])

  useEffect(() => {
    if (hasDays) {
      const daysCopy = JSON.parse(JSON.stringify(battle.days));
      const sortedDays: DayType[] = daysCopy.sort((a: DayType, b: DayType) => +new Date(a.date) - +new Date(b.date));
      setSortedDays(sortedDays);
    }

    if (textareaRef.current) textareaRef.current.style.height = textareaRef.current.scrollHeight + "px";
  }, [days])

  useEffect(() => {
    if (hasBlockTargets) sortAndSetBlockTargets();
  }, [battle.blockTargets])

  const sortAndSetBlockTargets = () => {
    const targets = [...battle.blockTargets];
    setBlockTargets(targets.sort((a: TargetType, b: TargetType) => a.id.localeCompare(b.id)));
  }

  const onBlockTargetChange = async(input:any, target: TargetType, hideNumberCheck: boolean, photo: string, battle: BattleType) => {
    let stillUpdating = true;
    setTimeout(() => {
      if (stillUpdating) setIsUpdatingTarget(true);
    }, 500);
    const success = await updateBlockCheckboxTarget(convertBooleanToString(input), battle, target, user, hideNumberCheck, photo);
    if (!success) displayToast(dispatch, 'Failed to update target. Please try again.')
    stillUpdating = false;
    setIsUpdatingTarget(false);
  }

  const onAddClick = async(event: any, target: TargetType, battle: BattleType) => {
    event.preventDefault();
    let stillUpdating = true;
    setTimeout(() => {
      if (stillUpdating) setIsUpdatingTarget(true);
    }, 500);
    const success = await updateBlockNumberTarget(event.target[0].value, battle, target, user);
    if (success) displayToast(dispatch, 'Entry recorded. Well done!')
    if (!success) displayToast(dispatch, 'Failed to update target. Please try again.')
    stillUpdating = false;
    event.target.reset();
    setIsUpdatingTarget(false);
  }
  

  return (
    <div className="calendar-view">

      {isUpdatingTarget && (
        <>
          <div className="full-screen-mask" />
          <div className="day-view__spinner">
            <Spinner />
          </div>
        </>
      )}
      
      {hasDays && (
        <div className="battle-calendar-cont">
          <div
            className="battle-calendar battle-view-card"
            style={{
              gridTemplateColumns: `repeat(7, 1fr)`
            }}
          > 
            <img className="battle-view-card__LB" src={cornerPaperLB} alt="corner"/>
            <img className="battle-view-card__RB" src={cornerPaperRB} alt="corner"/>
            <img className="battle-view-card__RT" src={cornerPaperRT} alt="corner"/>
            <p>Mon</p>
            <p>Tue</p>
            <p>Wed</p>
            <p>Thu</p>
            <p>Fri</p>
            <p>Sat</p>
            <p>Sun</p>
            {[...Array(blankDays)].map((e, i) => <DayWidget key={i} battle={battle}></DayWidget>)}
            {sortedDays.map((day: DayType, index: number) => {
              return (
                <DayWidget
                  setActiveView={(view) => setActiveView(view)}
                  day={day}
                  battle={battle}
                  key={day.date}
                ></DayWidget>
              )
            }
            )}
          </div>

          <div className="battle-calendar-title battle-view-card-title"><img className="battle-view-card-title__nail" src={cornerPaperLT} alt="corner"/>Daily Targets</div>
        </div>
      )}

      {hasBlockTargets && user.id && (
        <div className="battle-block-targets-cont">
          <div className="battle-block-targets-cont__content battle-view-card">
            <img className="battle-view-card__LB" src={cornerPaperLB} alt="corner"/>
            <img className="battle-view-card__RB" src={cornerPaperRB} alt="corner"/>
            <img className="battle-view-card__RT" src={cornerPaperRT} alt="corner"/>

            <div className="battle-block-targets-cont__content__grid">
              <div className="battle-block-targets-cont__content__grid__contestant">
                
                <h4>Your Targets</h4>
                {blockTargets.map((target: any) => {
                  const contestantTarget: ContestantTargetType = target[user.id];
                  const accumulatedTotal = target[user.id].inputArray?.reduce((sum: number, el: any) => sum + +el.value, 0);
                  const isCheckbox = target.format === TargetFormat.CHECKBOX;
                  const endDate = dayjs(battle.startDate).add(battle.numDays - 1, 'day').format('DD MMM');

                  if (target.format === TargetFormat.CHECKBOX) {
                    return (
                      <div className="battle-details__block-target-line">
                        <div className="dashboard__battle" key={target.id}>
                          <div className="dashboard__battle__row">
                            <Checkbox
                              checked={!!contestantTarget.input}
                              inputValue={contestantTarget.input}
                              variant={target.format}
                              target={target}
                              battle={battle}
                              contestantTarget={contestantTarget}
                              isUser={true}
                              onChange={(input: any, hideNumberCheck: boolean, photo: string) => onBlockTargetChange(input, target, hideNumberCheck, photo, battle)}
                            />
          
                            <div className="dashboard__battle__text">
                              <p className="dashboard__battle__text__title">{target.title}</p>
                              <div className="dashboard__battle__icon-line">
                                {isCheckbox && (
                                  <div>
                                    <CheckboxTarget style={{transform: "scale(0.6)"}} />
                                    <p>Checkbox</p>
                                  </div>
                                )}
                                {!isCheckbox && (
                                  <div>
                                    <Hash style={{transform: "scale(0.6)"}}/>
                                    <p>Number</p>
                                  </div>
                                )}
                                <p> | {endDate}</p>
                                {target.isPhotoRequired && (
                                  <div>
                                    |
                                    <Camera
                                      stroke="var(--dark)"
                                      style={{width: "24px", height: "24px", transform: "scale(0.6)"}}
                                    />
                                    <p style={{color: "var(--dark)"}}>Photo</p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    )
                  }

                  if (target.format === TargetFormat.NUMBER) {
                    return (
                      <div className="battle-details__block-target-line battle-details__block-target-line--number">
                        <div className="dashboard__battle" key={target.id}>
                          <div className="dashboard__battle__row" onClick={() => setUserBlockToShowId(userBlockToShowId ? "" : target.id + user.id)}>
                            <div
                              className={
                                userBlockToShowId !== target.id + user.id ? "icon battle-details__icon--pulse" : "icon"
                              }
                              style={{marginRight: "4px", alignSelf: "center", background: userBlockToShowId !== target.id + user.id ? "var(--main)" : "transparent"}}
                            >
                              {userBlockToShowId !== target.id + user.id && <Plus stroke="var(--light)" width="24px" height="24px" style={{transform: "scale(0.9)"}}/>}
                              {userBlockToShowId === target.id + user.id && <ChevronUp stroke="var(--main)" width="24px" height="24px" style={{opacity: "0.5"}} />}
                            </div>
          
                            <div className="dashboard__battle__text">
                              <p className="dashboard__battle__text__title">{target.title}</p>
                              <div className="dashboard__battle__icon-line">
                                {isCheckbox && (
                                  <div>
                                    <CheckboxTarget style={{transform: "scale(0.6)"}} />
                                    <p>Checkbox</p>
                                  </div>
                                )}
                                {!isCheckbox && (
                                  <div>
                                    <Hash style={{transform: "scale(0.6)"}}/>
                                    <p>Number</p>
                                  </div>
                                )}
                                <p> | {endDate}</p>
                                {target.isPhotoRequired && (
                                  <div>
                                    |
                                    <Camera
                                      stroke="var(--dark)"
                                      style={{width: "24px", height: "24px", transform: "scale(0.6)"}}
                                    />
                                    <p style={{color: "var(--dark)"}}>Photo</p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
        
          
                        </div>
                          {userBlockToShowId === target.id + user.id &&  (
                            <div className="dashboard__battle__extend">
                              <p style={{opacity: "0.7"}}><strong>Running Total: {accumulatedTotal}</strong></p>
                              <form onSubmit={(event) => onAddClick(event, target, battle)}>
                                <Input
                                  name="block-number-input"
                                  type="number"
                                  placeholder="Enter..."
                                />
                                <button type="submit" className="dashboard__battle__extend__submit">+ Add</button>
                              </form>
                              {target[user.id].inputArray.length > 0 && (
                                <>
                                  <p style={{opacity: "0.5"}}>Previous entries:</p>
                                  {target[user.id].inputArray.map((el: any) => {
                                    return (
                                      <p>{dayjs(el.date).format('DD MMM')}: {el.value}</p>
                                    )
                                  }).reverse()}
                                </>
                              )}
                            </div>
                          )}
                      </div>
                    )
                  }
                })}
              </div>

              <div
                className="battle-block-targets-cont__content__grid__contestant"
                style={{
                  transform: "scale(0.7)",
                  transformOrigin: "left"
                }}
              >
                <h4>{otherContestant.username} Targets</h4>
                {blockTargets.map((target: any) => {
                  const contestantTarget: ContestantTargetType = target[otherContestant.id];
                  const accumulatedTotal = target[otherContestant.id].inputArray?.reduce((sum: number, el: any) => sum + +el.value, 0);
                  const isCheckbox = target.format === TargetFormat.CHECKBOX;
                  const endDate = dayjs(battle.startDate).add(battle.numDays - 1, 'day').format('DD MMM');

                  if (target.format === TargetFormat.CHECKBOX) {
                    return (
                      <div className="battle-details__block-target-line">
                        <div className="dashboard__battle" key={target.id}>
                          <div className="dashboard__battle__row">
                            <Checkbox
                              checked={!!contestantTarget.input}
                              inputValue={contestantTarget.input}
                              variant={target.format}
                              target={target}
                              battle={battle}
                              contestantTarget={contestantTarget}
                              isUser={false}
                              onChange={() => null}
                            />
          
                            <div className="dashboard__battle__text">
                              <p className="dashboard__battle__text__title">{target.title}</p>
                              <div className="dashboard__battle__icon-line">
                                {isCheckbox && (
                                  <div>
                                    <CheckboxTarget style={{transform: "scale(0.6)"}} />
                                    <p>Checkbox</p>
                                  </div>
                                )}
                                {!isCheckbox && (
                                  <div>
                                    <Hash style={{transform: "scale(0.6)"}}/>
                                    <p>Number</p>
                                  </div>
                                )}
                                <p> | {endDate}</p>
                                {target.isPhotoRequired && (
                                  <div>
                                    |
                                    <Camera
                                      stroke="var(--dark)"
                                      style={{width: "24px", height: "24px", transform: "scale(0.6)"}}
                                    />
                                    <p style={{color: "var(--dark)"}}>Photo</p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }

                  if (target.format === TargetFormat.NUMBER) {
                    return (
                      <div className="battle-details__block-target-line battle-details__block-target-line--number">
                        <div className="dashboard__battle" key={target.id}>
                          <div className="dashboard__battle__row" onClick={() => setOtherContestantBlockToShowId(otherContestantBlockToShowId ? "" : target.id + otherContestant.id)}>
                            <div
                              className="icon"
                              style={{marginRight: "4px", alignSelf: "center"}}
                            >
                              {otherContestantBlockToShowId !== target.id + otherContestant.id && <ChevronUp stroke="var(--main)" width="24px" height="24px" style={{transform: "rotate(180deg)", opacity: "0.5"}}/>}
                              {otherContestantBlockToShowId === target.id + otherContestant.id && <ChevronUp stroke="var(--main)" width="24px" height="24px" />}
                            </div>
          
                            <div className="dashboard__battle__text">
                              <p className="dashboard__battle__text__title">{target.title}</p>
                              <div className="dashboard__battle__icon-line">
                                {isCheckbox && (
                                  <div>
                                    <CheckboxTarget style={{transform: "scale(0.6)"}} />
                                    <p>Checkbox</p>
                                  </div>
                                )}
                                {!isCheckbox && (
                                  <div>
                                    <Hash style={{transform: "scale(0.6)"}}/>
                                    <p>Number</p>
                                  </div>
                                )}
                                <p> | {endDate}</p>
                                {target.isPhotoRequired && (
                                  <div>
                                    |
                                    <Camera
                                      stroke="var(--dark)"
                                      style={{width: "24px", height: "24px", transform: "scale(0.6)"}}
                                    />
                                    <p style={{color: "var(--dark)"}}>Photo</p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
        
          
                        </div>
                          {otherContestantBlockToShowId === target.id + otherContestant.id && (
                            <div className="dashboard__battle__extend">
                              <p style={{opacity: "0.7"}}><strong>Running Total: {accumulatedTotal}</strong></p>
                              {target[otherContestant.id].inputArray.length > 0 && (
                                <>
                                  <p style={{opacity: "0.5"}}>Previous entries:</p>
                                  {target[otherContestant.id].inputArray.map((el: any) => {
                                    return (
                                      <p>{dayjs(el.date).format('DD MMM')}: {el.value}</p>
                                    )
                                  })}
                                </>
                              )}
                            </div>
                          )}
                      </div>
                    )
                  }
                })}
              </div>
            </div>

            <p style={{marginTop: "12px", fontSize: "14px", opacity: "0.7"}}>Each Block Target is worth {battle.numDays}HP (1HP for every day of the battle) and will be added at the end of the battle.</p>
          </div>

          <div className="battle-calendar-title battle-view-card-title"><img className="battle-view-card-title__nail" src={cornerPaperLT} alt="corner"/>Block Targets</div>
        </div>
      )}

      <div className="calendar-view__battle-details-cont">
        <div className="battle-details battle-view-card">

          <div className="battle-details__back-card" onClick={() => navigate("/battles")}>Back</div>

          <img className="battle-view-card__LB" src={cornerPaperLB} alt="corner"/>
          <img className="battle-view-card__RB" src={cornerPaperRB} alt="corner"/>
          <img className="battle-view-card__RT" src={cornerPaperRT} alt="corner"/>

          {battle.status === BattleStatus.COMPLETE && (
            <div className="battle-details__entry">

            </div>
          )}

          <div className="battle-details__entry">
            <BattleCompletePage battle={battle} otherContestant={otherContestant}/>
          </div>

          {otherUser && (
            <div
              className="battle-details__head-to-head"
              style={
                battle.result.winner.id === otherUser.id 
                  ? {flexDirection: "row-reverse"}
                  : {flexDirection: "row"}
              }
            >
              <div>
                <h4>{user.username}</h4>
                <Avatar id={user.avatar.id} newColors={user.avatar.colors} />
                <HealthBar
                  username={user.username}
                  maxHp={hasDays ? battle.days[0].targets.length * 2 : 1}
                  currentHp={battle.status === BattleStatus.COMPLETE
                    ? (isUserChallenger ? battle.result.challengerHp : battle.result.opponentHp)
                    : getCurrentUserContestantObj(user.id, battle).hp
                  }
                  showPulse={battle.status === BattleStatus.COMPLETE ? false : true}
                  showExclamation={false}
                  battle={battle}
                />
              </div>
              <div>
                <h4>{otherUser.username}</h4>
                <Avatar id={otherUser.avatar.id} newColors={otherUser.avatar.colors} />
                <HealthBar
                  username={otherUser.username}
                  maxHp={hasDays ? battle.days[0].targets.length * 2 : 1}
                  currentHp={battle.status === BattleStatus.COMPLETE
                    ? (isUserChallenger ? battle.result.opponentHp : battle.result.challengerHp)
                    : otherContestant.hp
                  }
                  showPulse={battle.status === BattleStatus.COMPLETE ? false : true}
                  showExclamation={false}
                  battle={battle}
                />
              </div>
            </div>
          )}

          <div className="battle-details__entry-container">
            <div className="battle-details__entry">
              <Calendar />
              <p className="battle-details__entry__title">Finish Date</p>
              <p>{dayjs(battle.startDate).add(battle.numDays - 1, 'day').format("DD MMM YYYY")}</p>
            </div>

            <div className="battle-details__entry">
              <Present />
              <p className="battle-details__entry__title">Prize/Forfeit</p>
              <p>{battle.prize ? battle.prize : "n/a"}</p>
            </div>
          </div>


          <div>
            <div className="battle-details__targets-title">
              <Target />
              <p>Targets</p>    
            </div>
            {hasDays && battle && battle.days.length > 0 && battle.days[0].targets.map((target: TargetType) => {
              return (
                <TargetLine
                  key={target.id}
                  target={target}
                />
              )
            })}

            {hasBlockTargets && battle && blockTargets.map((target: TargetType) => {
              return (
                <TargetLine
                  key={target.id}
                  target={target}
                />
              )
            })}
          </div>

          {battle.description && (
            <div>
              <div className="battle-details__description-title">
                <Book />
                <p>Description/Rules</p>
              </div>
              <textarea
                ref={textareaRef}
                value={battle.description}
                readOnly 
              />
            </div>
          )}

        </div>

        <div className="battle-details-title battle-view-card-title"><img className="battle-view-card-title__nail" src={cornerPaperLT} alt="corner"/>Details</div>
      </div>


    </div>
  )
}

export default BattleCalender;