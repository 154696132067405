import React, { useEffect, useState } from 'react';
import './Spinner.scss';

interface Props {
  style?: React.CSSProperties;
  className?: string;
  variant?: 'DARK' | 'LIGHT';
}

const Spinner: React.FC<Props> = ({
  style,
  className,
  variant = 'DARK'
}) => {

  const [classList, setClassList] = useState('spinner');

  useEffect(() => {
    setClassList(`spinner ${className} ${variant === 'LIGHT' ? 'spinner--light' : 'spinner--dark'}`);
  }, [])

  return (
    <div
      className={classList}
      style={style}
    >
      <div className={"box box-one"} />
      <div className={"box box-two"} />
      <div className={"box box-three"} />
    </div>
  )
};

export default Spinner;