import React from 'react';
import './IndvTargetSummary.scss';
import CreateBattleCard from '../create-battle-card/CreateBattleCard';

const IndvTargetSummary: React.FC<{activeTarget: any}> = ({activeTarget}) => {

  return (
    <CreateBattleCard firstLine="Target Summary">
      <p><span>Title:</span> {activeTarget.title}</p>
      {activeTarget.format && <p><span>Format:</span> {activeTarget.format.title}</p>}
      {activeTarget.isPhotoRequired && <p>Photo Required</p>}
    </CreateBattleCard>
  )
}

export default IndvTargetSummary;