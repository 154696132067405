import React, { useEffect, useState } from 'react';
import './Footer.scss';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import { battlesSelector } from '../../redux/slices/battlesSlice';
import { ReactComponent as Home  } from '..//../ui-components/icon/svgs/home.svg';
import { ReactComponent as Swords  } from '..//../ui-components/icon/svgs/swords.svg';
import { ReactComponent as PlusCircle  } from '..//../ui-components/icon/svgs/plusCircle.svg';
import { ReactComponent as User  } from '..//../ui-components/icon/svgs/user.svg';
import { BattleType } from '../../types/BattleType';
import { getPendingBattles } from '../../pages/home-page/dashboard/Dashboard-Utils';

const Footer = () => {

  const navigate = useNavigate();
  const rawBattles = useAppSelector(battlesSelector);
  
  const [pendingBattles, setPendingBattles] = useState<BattleType[]>([]);

  useEffect(() => {
    setPendingBattles(getPendingBattles(rawBattles));
  }, [rawBattles])

  return (
    <div className="footer">
      <div className="footer__content">
        <div
          className="icon--large"
          onClick={() => navigate('/home')}
        >
          <Home />
        </div>

        <div
          className="icon--large footer__content__swords"
          onClick={() => navigate('/battles')}
        >
          <Swords />
          {pendingBattles.length > 0 && <div className="footer__content__swords__notification-dot" />}
        </div>

        <div
          className="icon--large"
          onClick={() => navigate('/start-new-battle')}
        >
          <PlusCircle />
        </div>

        <div
          className="icon--large"
          onClick={() => navigate('/profile')}
        >
          <User />
        </div>
      </div>
    </div>
  )
}

export default Footer;