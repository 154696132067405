import React from 'react';
import './Ancillary.scss';
import Button from '../ui-components/button/Button';

const Guide: React.FC<{onClose: () => void}> = ({onClose}) => {

  return (
    <div className="ancillary">
      <h1>Guide</h1>
      <p className="ancillary__close" onClick={onClose}>X</p>

      <h3>What is PvP Health?</h3>
      <p>PvP Health is an app created to help you grow your fitness and health. It was designed to encourage a playful competition between friends to improve their fitness.</p>

      <h3>How dose it work?</h3>
      <p>In a nutshell, PvP Health is a way to record your pre-defined achievements against a friend (opponent). Achievements, or targets as we call them here, are measured every day and a tally counted. The battle is won by the player who wins the most days.</p>

      <h3>What are targets?</h3>
      <p>Targets are the measurement system used in PvP Health. There are two different frequencies for targets:</p>
      <ul>
        <li>Daily Target: A target that can be performed/achieved/measured daily.</li>
        <li>Block Target: A target that would be hard to perform/accomplish/measure daily. Block targets are measured and won at the end of a battle. If a block target is achieved or won then 1HP is added to the contestant for each day of the battle.</li>
      </ul> 
      <p>There are two types of targets:</p>
      <ul>
        <li>Checkbox: A simple checkbox that indicates if the target has been met for the day.</li>
        <li>Number Input: A box to input a number where the highest number will win. i.e. Number of km run, number of vegetables eaten.</li>
      </ul>
      <p>Each type of target also has the option for photo confirmation. If the target is set to have photo confirmation then a photo/image must be uploaded to complete the target. This photo/image will be viewable by the other player to ensure the target is completed to a satisfactory level.</p>
      <p>Battles can consist of many targets and of different frequencies and types. <strong>The more targets, the better the battle.</strong></p>

      <h3>How is a winner calculated?</h3>
      <p>There is an order as to how a winner is calculated. The order goes, KO &gt; most HP &gt; days won &gt; accumulated number inputs.</p>

      <h3>How to win by KO?</h3>
      <p>A KO (Knock Out) occurs when a players HP (Health Points) drops to 0 or below and the battle ticks over to the next day.</p>
      <p>For example: Challenger A's HP drops to 0. Challenger A will be able to increase their HP by completing a target, however, they must do this before the end of the day. If Challenger A does not increase their HP above 0 then they will be 'Knocked Out' and lose the battle.</p>

      <h3>How to win by HP</h3>
      <p>If the battle reaches the end without either player being knocked out then, after any block target HP is added, the remaining HP for each player is checked and the player with the most HP wins.</p>
      
      <h3>How to win by days</h3>
      <p>Should both players have equal HP at the end of the battle then the calculation goes on to the number of days won. At the end of each day, the number of targets each player has completed is totalled. The player with the most completed targets wins the day (or the day is marked a draw). At the end of the battle, the player with the most day wins will be the winner.</p>
      
      <h3>How to win by accumulated number inputs</h3>
      <p>If at the end of a battle both players are equal on HP and days won, then the count will go down to the accumulated number input on number inputs. What?</p>
      <p>Example: Both players are equal on Days and Targets however the total number of km that Player A has ran is greater than Player B and therefore Player A wins the battle.</p>

      <h3>How do HP work?</h3>
      <p>HP = Hit Points</p>
      <p>HP is another way for you to beat your opponent in a battle. If you manage to reduce your opponents HP to 0 and keep it that way overnight then you will win the battle.</p>
      <p>By completing a daily target, your HP will increase by 1 and your opponents will decrease by 1. Keep in mind that your HP can go above and below 0 which will be indicated above your health bar.</p>
      <p>Block Target HP is calculated as 1HP for each day of the battle. i.e. If the battle is 7 days long and a contestant completes the block target, then they will gain 7HP at the end of the battle.</p>
      <p>Initial HP is calculated by taking the number of daily targets in a day and multiplying this by 2. This means that a battle cannot be won in 1 day (unless it is a 1 day battle), and if a player simply has an off day then they won't be knocked out straight away.</p>
      <p>If a battle has no daily targets then initial HP is set to 1. This will not change until the end of the battle, meaning a win by KO is impossible without daily targets.</p>

      <h3>How does leveling work?</h3>
      <p>Leveling in PvP Health does NOT have an affect on your or your opponents performance. A player's level is purely a rough indication of how experienced they are and how well they have performed in previous battles.</p>
      <p>Leveling up occurs when a player gains a certain amount of XP (Experience Points). Each level requires more XP to reach than the previous level.</p>
      <p>XP is distributed as follows:</p>
      <ul>
        <li>Winning a battle: +10xp</li>
        <li>Drawing a battle: +3xp</li>
        <li>Losing a battle: +1xp</li>
      </ul>
      
      <Button onClick={onClose}>
        Close
      </Button>
    </div>
  )
}

export default Guide;