import React from 'react';
import './Ancillary.scss';
import Button from '../ui-components/button/Button';

const PrivacyPolicy: React.FC<{onClose: () => void}> = ({onClose}) => {

  return (
    <div className="ancillary">
      <h1>PrivacyPolicy</h1>
      <p className="ancillary__close" onClick={onClose}>X</p>

      <p><i>Updated 26 February 2024</i></p>

      <p>PvP Health (“company”, “I”, “we” or “us”) is dedicated to respecting the privacy of your personal information, and this privacy policy describes what information is collected from you on pvp-health.app (hereinafter the “website/app”) and how it is used. The term “you” refers to anyone who uses, visits and/or views the website.</p>

      <p>By visiting and using the website/app, you accept and agree to be bound by this privacy policy. Your continued use of the website/app after posting of any changes to our Privacy Policy constitutes your acceptance of those changes and updates. You must not access or use the website/app if you do not wish to be bound by this Privacy Policy.</p>

      <h3>CHILDREN'S PRIVACY</h3>

      <p>We respect the privacy of children and “child” means an individual under the age of 13. This policy is in accordance with the Children's Online Privacy Protection Act (“COPPA”). This website/app information and content are only intended for people over the age of 18. This website/app is not intended for children under the age of 13. Without prior parental or guardian consent, we do not knowingly collect, use, or disclose personal information from children under the age of 13. If you believe that personal information was collected without parental or guardian consent from a child under the age of 13, please contact us to have that information deleted. </p>

      <h3>WHAT INFORMATION WE COLLECT AND HOW IT IS USED</h3>

      <p>When you access the website/app, you may provide certain personally identifiable information including but not limited to your name, email address and images you upload.</p>

      <p>This information is collected when you register on the site, subscribe to notifications, contact us, enter any other information on the website/app to communicate with us.</p>

      <p>Your personal information is used to personalize your experience, improve the website/app to better serve you, provide customer service support, efficiently process your requests, tailor advertisements to you, elicit reviews of services or products, provide you offers, promotions and to follow up with you through correspondence (email, notifications). We may also use this information to provide you offers and promotions from our partners and/or our affiliates in exchange for a commission without additional cost to you.</p>

      <p>Additionally, like other website/apps, this website/app automatically collects certain information about you through Log Data and Google Analytics. Log Data is information about your computer's Internet Protocol Address, which is your “IP” address, browser information, Internet Service Provider's information, your operating system, and your browser type. Similarly, Google Analytics collects certain information about your location, browsing history, the pages you visit, the equipment you used to access the website/app, traffic patterns, and other general patterns related to your use of the website/app.</p>

      <p>This information is used to analyze website/app statistics related to user behavior and interests, improve our performance and your use of the website/app and to further enhance our products and services offered to you.</p>

      <h3>IMAGES AND ENTERED INFORMATION</h3>

      <p>All information you enter/upload on the website/app, including but not limited to battle setup information and images are retained indefinitely. This is so we can provide a full history of battles you have participated in. This information is not shared with any third party.</p>

      <h3>USE OF COOKIES </h3>

      <p>The website/app may use cookies to facilitate your use of the website/app. Cookies are files with small amounts of data including an anonymous unique identifier that a website/app sends to your computer's hard drive when you are viewing the website/app. Just like other website/apps, we automatically collect some non-personally identifiable information including but not limited to your IP address, geographic location, language preference, date and time of visitors.</p>

      <p>If you have an account and you log in to this website/app, we will set a temporary cookie to determine if your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser.</p>

      <p>We may use cookies for various reasons such as optimizing and personalizing your browsing experience, checking our website/app analytics, saving your preferences and settings for future use, serving ads based on your liking and interests, and affiliate marketing.</p>

      <p>This information is only collected to better serve and understand your user experience on the website/app.  You have the option of turning off cookies on your computer should you wish to do so. If you choose to do that, you may not be able to view all the features and content of this website/app.</p>

      <h3>USE OF WEB BEACONS AND PIXELS</h3>

      <p>In conjunction with the use of cookies, third parties may also use web beacons, which are also known as clear GIFs, web bugs or pixel tags to collect general information about your use of our website/app. They monitor user activity and are used to track customer behavior data. This information may be relevant to third parties such as the ad networks used on our website/app to tailor the advertising based on your behavior and interests.</p>

      <p>We may use social media pixels to track and collect general information about your use in compliance with different social media sites (Facebook, Twitter, Pinterest, and others) for the purpose of promoting products, tracking conversions, remarketing, running target advertisements and so forth.</p>

      <p>Third parties like Facebook may use their own cookies, web beacons and other technologies to collect and receive information from our website/app for the purpose of providing target advertisements. You may see our ads on Facebook or YouTube after you have visited our website/app.</p>

      <h3>THIRD-PARTY LINKS AND USE</h3>

      <p>We may include, offer or advertise third party links, products or services on the website/app. Once you click on a third-party link and leave this website/app, you are no longer bound by our Privacy Policy and Terms and Conditions.</p>

      <p>Articles on this website/app may include embedded content (e.g. videos, images, advertisements, etc.). Embedded content from other websites/apps behaves in the exact same way as if the visitor has visited the other website/app. These websites/apps may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracing your interaction with the embedded content if you have an account and are logged in to that website/app.</p>

      <p>We may use Google AdSense advertising along with any other third-party advertising on the website/app. Google is a third party that also uses cookies to serve ads on the website/app for the purpose of providing a positive user experience. Third-party vendors such as Google use cookies to serve ads based on a user's prior visits to the website/app. You can opt-out of Google by visiting their privacy policy and ad settings.</p>

      <p>We have no control over these third parties and they have their own privacy policies. Neither are we responsible for the activities and practices of these third parties. You should contact them directly and read their privacy policies for any questions. You also agree that your use of these third-party websites/apps is solely at your risk.</p>

      <h3>DISCLOSURE OF YOUR INFORMATION</h3>

      <p>As a general rule, we do not disclose your personal information to third parties without your consent with the exception of the following circumstances:</p>
      <ul>
        <li>We may disclose your information to our trusted third parties that work with us such as our website/app hosting partners, email marketing service provider, other service providers that assist in the operation of the website/app, and any other affiliates and subsidiaries we rely upon to provide you products and services offered here.</li>
        <li>We may disclose your information in order to comply with state or federal regulations related to copyright infringement lawsuits or any other legal claims related to the website/app.</li>
        <li>We may disclose your information to our successor and/or acquiring party in the event of a merger, acquisition, restructuring, dissolution or partial sale in the future. However, your personal information will be transferred to the acquiring party in accordance with this privacy policy.</li>
      </ul>

      <h3>GDPR VISITOR RIGHTS</h3>

      <p>Under the GDPR, if you are within the European Union, you are entitled to certain rights and information listed below.</p>

      <p>We will retain any information you choose to provide to us until the earlier of:</p>

      <ul>
        <li>You ask us to delete the information by sending a request to kwamtek@outlook.com. Please note that such requests may result in you no longer being able to access paid or free content previously provided to you.</li>
        <li>Our decision to cease using our existing data providers.</li>
        <li>The Company decides to no longer be in business or continue to offer the services.</li>
        <li>The data is no longer needed to provide you service, is too costly to maintain further retention, or the Company finds it outdated.</li>
        <li>You have the right to request access to your data that we store and have the ability to access your personal data.</li>
      </ul>

      <p>You have the right to either rectify or erase your personal data. You have the right to verify the accuracy of your personal data and have it corrected or removed completely.</p>

      <p>You have the right to seek restrictions on the processing of your data. When you restrict the processing of your data, we can store your data but cannot process it further.</p>

      <p>You have the right to object to the processing of your data in certain circumstances including but not limited to direct marketing, profiling, scientific or historical research purposes, statistical purposes, automated decision making and profiling and tasks based on legitimate interests or in the public interest/exercise of official authority.</p>

      <p>You have the right to the portability of your data. You have the right to request your personal data from us, receive it and transfer it to another controller.</p>

      <p>You have the right to withdraw consent at any time. If you have provided consent to the Company's processing of your personal data, you have the right to withdraw that consent any time without affecting the lawfulness of processing based upon consent that occurred prior to your withdrawal of consent.</p>

      <p>You have the right to lodge a complaint with a supervisory authority that has jurisdiction over issues related to the General Data Protection Regulation.</p>

      <p>We require only the information that is reasonably necessary to enter into a contract with you. We will not require you to provide consent for any unnecessary processing as a condition of entering into a contract with us.</p>

      <h3>CALIFORNIA CONSUMER PRIVACY ACT (CCPA) COMPLIANCE AND YOUR LEGAL RIGHTS</h3>

      <p>If you live in California, you have additional rights under California law, and we comply with the CCPA because we may have visitors from California on our website/app. Here are your legal rights:</p>

      <ul>
        <li>You have the right to know whether your personal information is sold or disclosed to third parties.</li>
        <li>You have the right to say no to the sale of your personal information.</li>
        <li>You also have the right to access your personal information, which we will provide within 30 days of such request.</li>
        <li>You have the right to know what personal information is collected from you and how it is used, which we explained in this Privacy Policy</li>
        <li>You have the right to have your personal information deleted</li>
        <li>You have the right to equal service, price and no discrimination</li>
        <li>You have the right to data portability and right to request your personal information and use it for your own purposes</li>
        <li>Pursuant to California's “Shine the Light Act,” you are permitted to request information about the manner in which we share certain categories of information with third parties for their marketing use. We may disclose your personal information to our affiliates or other related third parties such as service providers, vendors for their use in marketing to you, so we can provide the products and/or services offered on this website/app to you. When we disclose such information, your personal information is still kept confidential and between us and that third party. It is not used for any other purpose that's not permitted under the laws.</li>
      </ul>

      <p>Please be advised we DO NOT SELL YOUR PERSONAL INFORMATION to third parties and have never sold your personal information. We do not intend to sell your personal information in the future either.</p>

      <p>Under the CCPA, you still have the right to opt-out of such sales and send us a “do not sell my information” request. If you would like to exercise any of your rights under California law, please submit a verifiable consumer request to us by sending us an email at kwamtek@outlook.com. Only you, as the person registered with the California Secretary of State, or someone you authorize to act on your behalf, can make such verifiable consumer requests related to your personal information.</p>

      <p>Your verifiable consumer request must provide sufficient information that allows us to verify that you are the person you are claiming to be or that you are the authorized representative of such person about whom we had collected personal information. You must describe your request with enough details such as your first and last name, address and your country that allow us to properly understand the request and respond to it. Please note we cannot respond to your request or provide you with personal information unless we first verify your identity or authority to make such a request and confirm that the personal information relates to you. We will make all attempts to respond to your request within 30 days of receipt.</p>

      <p>This privacy notice for California residents supplements the information included in the previous sections of this privacy policy. California and Delaware law also requires us to state whether we honor “Do Not Track” settings in your browser regarding targeted advertising and we do not monitor or respond to Do Not Track browser requests.</p>

      <h3>SECURITY</h3>

      <p>The security of your personal information is important to us, and we strive to follow generally commercial industry standards to protect your personal information submitted to us voluntarily and automatically. However, no method of transmission over the Internet is 100% secure and we cannot guarantee the absolute security of your information.</p>

      <p>By using this website/app, you agree to hold us harmless for any security breach and for any unauthorized use of your personal information by third parties. You also agree that we cannot be held responsible for any disclosure of your information through our website/app without our knowledge and consent.</p>

      <h3>PRIVACY POLICY UPDATES </h3>

      <p>This privacy policy is effective as of 27 February 2024 and will be updated and modified as needed. You are responsible for visiting this page periodically to check for future updates to this policy. Any modifications to this privacy policy will be effective upon our publishing of the new terms, and your continued use of our website/app after the posting of any updates constitutes your acceptance of our modified privacy policy.</p>

      <h3>CONTACT</h3>

      <p>For any questions or comments regarding the privacy policy, please contact us at kwamtek@outlook.com.</p>
    
      <Button
        variant="primary"
        onClick={onClose}
      >
        Close
      </Button>
    </div>
  )
}

export default PrivacyPolicy;