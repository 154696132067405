import React, { ReactNode } from 'react';
import './Option.scss';

export interface SelectOption {
  title: string;
  value: string | number | boolean;
  disabled?: boolean;
}

const Option: React.FC<{
  value: SelectOption;
  disabled?: boolean | undefined;
  style?: React.CSSProperties;
  variant: 'DARK' | 'LIGHT';
  onSelect?: (event:any) => void;
  children?: ReactNode
}> = ({
  value,
  disabled,
  style,
  variant,
  onSelect,
  children
}) => {

  const getClassName = () => {
    let className = 'select-option ';

    if (disabled) {
      className = className.concat('select-option--disabled ')
    }

    if (variant === 'DARK') {
      className = className.concat('select-option--dark ')
    }
    return className;
  }

  const className = getClassName();

  const onOptionSelect = () => {
    if (!disabled) {
      onSelect?.(value);
    }
  }

  return (
    <div
      className={className}
      style={style}
      onClick={onOptionSelect}
    >{children}</div>
  )
}

export default Option;