import React, { useState } from 'react';
import './ColorPicker.scss';

const ColorPicker: React.FC<{
  previousColor: string;
  label: string;
  // colorChange?: (event: any) => void
  saveColorChange: (event: any) => void
}> = ({
  previousColor,
  label,
  // colorChange,
  saveColorChange
}) => {

  const [newColor, setNewColor] = useState<string>();

  const onColorChange = (color: string) => {
    setNewColor(color);
    // if (colorChange) colorChange(color);
  }

  return (
    <div className="color-picker">
      <label htmlFor="profile-color-picker">{label}</label>
      <input
        id="profile-color-picker"
        type="color"
        defaultValue={newColor ? newColor : previousColor}
        onChange={(event) => onColorChange(event.target.value)}
        onBlur={(event) => saveColorChange(event.target.value)}
      />
      <p className="color-picker__click">Click to change</p>
    </div>
  )
}

export default ColorPicker;