import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "..";
import { BattleStatus } from "../enums/enums";
import { getFunctions, httpsCallable } from "firebase/functions";
import { ContestantTargetType } from "../types/ContestantTargetType";
import { AvatarType } from "../types/AvatarType";
import { BattleType } from "../types/BattleType";
import dayjs from 'dayjs';
import { convertBooleanToString, getContestantHp, getExistingWinner } from "./database-utils";
import { TargetType } from "../types/TargetType";
import { UserType } from "../types/UserType";
import { getOtherContestant } from "../helpers/app-helpers";

export const updateBattleStatus = async(battleId: string, newStatus: BattleStatus, onError?: any) => {
  const battleRef = doc(db, "battles", battleId);
  let success = false;

  try {
    await updateDoc(battleRef, {status: newStatus})
      .then(async(data) => {
        await getDoc(doc(db, "battles", battleId))
          .then((data) => {
            if (data.data()?.status === newStatus) success = true
          })
      })
  } catch (error) {
    console.error(error);
    onError();
  }
  return success;
}

export const updateBattleStatusAndResult = async(battleId: string, newStatus: BattleStatus, result: any, onError: any) => {
  const battleRef = doc(db, "battles", battleId);
  try {
    await updateDoc(battleRef, {
      status: newStatus,
      result: result
    });

    if (newStatus === BattleStatus.COMPLETE) {
      const func = httpsCallable(getFunctions(), 'updateXp');
      func({battleId});
    }
  } catch (error) {
    console.error('Update battle error: ', error)
    onError();
  }
}

export const updateAvatar = async(userId: string, avatar: AvatarType) => {
  const userRef = doc(db, "users", userId);
  let success = true;
  try {
    await updateDoc(userRef, {avatar: avatar});
  } catch (error) {
    console.error(error);
    success = false;
  }
  return success;
}

export const updateTarget = async(
  battleId: string,
  dayKey: string,
  targetKey: string,
  input: string,
  userId: any,
  otherUserId: string,
  hiddenNumberCheck: boolean,
  battle: BattleType,
  target: TargetType,
  photo: string = ''
) => {
  const convertedInput = convertBooleanToString(input)
  // @ts-ignore
  const contestantTarget: ContestantTargetType = {
    photo,
    input: convertedInput,
    hide: hiddenNumberCheck
  };
  contestantTarget.photo = photo;
  contestantTarget.input = convertedInput;
  contestantTarget.hide = hiddenNumberCheck;
  contestantTarget.userId = userId;
  
  const contestantTargetString = `days.${dayKey}.${targetKey}.${userId}`;
  const isChallenger = battle.challenger.id === userId;
  const contestantObjectString = isChallenger ? 'challenger' : 'opponent';
  const otherContestantObjectString = isChallenger ? 'opponent' : 'challenger';

  const contestantObject = isChallenger ? battle.challenger : battle.opponent;
  const otherContestantObject = isChallenger ? battle.opponent : battle.challenger;

  const existingWinner = getExistingWinner(target[userId], target[otherUserId]);
  const plusMinus = getContestantHp(userId, existingWinner, convertedInput, target[otherUserId], target.format);
  let contestantHp = contestantObject.hp + plusMinus.user;
  let otherContestantHp = otherContestantObject.hp + plusMinus.otherUser;

  const targetRef = doc(db, "battles", battleId);
  let success = false;

  try {
    await updateDoc(targetRef,
      {
        [contestantTargetString]: contestantTarget,
        [contestantObjectString]: {
          ...contestantObject,
          hp: contestantHp,
          zeroHpDate: contestantHp < 1 ? dayjs().format('YYYY-MM-DD') : null
        },
        [otherContestantObjectString]: {
          ...otherContestantObject,
          hp: otherContestantHp,
          zeroHpDate: otherContestantHp < 1 ? dayjs().format('YYYY-MM-DD') : null
        }
      }

    )
      .then(async() => {
        success = true;
        if (input) {
          try {
            const func = httpsCallable(getFunctions(), 'sendTargetUpdateNotification');
            func({otherUserId});
          } catch (error) {
            console.error(error)
          }
        }
      })
  } catch (error) {
    console.error(error);
  }
  return success;
}

export const updateBlockCheckboxTarget = async(input: any, battle: BattleType, target: TargetType, user: UserType, hiddenNumberCheck: boolean, photo: string = '') => {
  const battleRef = doc(db, "battles", battle.id);
  const contestantTargetString = `blockTargets.${target.targetKey}.${user.id}`;
  const convertedInput = convertBooleanToString(input)
  // @ts-ignore
  const contestantTarget: ContestantTargetType = {
    photo,
    input: convertedInput,
    hide: hiddenNumberCheck
  };
  contestantTarget.photo = photo;
  contestantTarget.input = convertedInput;
  contestantTarget.hide = hiddenNumberCheck;
  contestantTarget.userId = user.id;
  
  const otherUserId = getOtherContestant(user.id, battle).id;

  let success = false;

  try {
    await updateDoc(battleRef, {[contestantTargetString]: contestantTarget})
      .then(async() => {
        success = true;
        if (input) {
          try {
            const func = httpsCallable(getFunctions(), 'sendTargetUpdateNotification');
            func({otherUserId});
          } catch (error) {
            console.error(error)
          }
        }
      })
  } catch (error) {
    console.error(error);
  }

  return success;
}

export const updateBlockNumberTarget = async(input: any, battle: BattleType, target: TargetType, user: UserType) => {
  const battleRef = doc(db, "battles", battle.id);
  const contestantTargetString = `blockTargets.${target.targetKey}.${user.id}`;
  // @ts-ignore
  const contestantTarget: ContestantTargetType = {
    photo: '',
    inputArray: [
      ...target[user.id].inputArray,
      {
        value: input,
        date: dayjs().format('YYYY-MM-DD')
      }
    ],
    hide: false,
    userId: user.id
  };
  
  const otherUserId = getOtherContestant(user.id, battle).id;

  let success = false;

  try {
    await updateDoc(battleRef, {[contestantTargetString]: contestantTarget})
      .then(async() => {
        success = true;
        if (input) {
          try {
            const func = httpsCallable(getFunctions(), 'sendTargetUpdateNotification');
            func({otherUserId});
          } catch (error) {
            console.error(error)
          }
        }
      })
  } catch (error) {
    console.error(error);
  }

  return success;
}

export const updatePersonalUserBoolean = async(userId: string, field: string, value: boolean) => {
  const ref = doc(db, "users", userId, "personalUsers", userId);
  let success = false;
  try {
    await updateDoc(ref, {[field]: value})
      .then(async() => {
        try {
          await getDoc(doc(db, "users", userId))
            .then((data) => {
              if (data.data()?.[field] === value) success = true;
            })
        } catch (error) {
          console.error(error);
        }
      })
  } catch (error) {
    console.error(error);
  }
  return success;
}