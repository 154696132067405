import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { UserType } from '../../types/UserType';

const initialState: UserType = {
  id: '',
  username: '',
  lowerUsername: '',
  avatar: {
    id: '1',
    colors: null
  },
  level: 1,
  xp: 0
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      return {...action.payload};
    },
    updateUser: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    updateField: (state, action: PayloadAction<{
      field: string,
      value: any
    }>) => {
      const {field, value} = action.payload
      return {
        ...state,
        [field]: value
      }
    },
    resetState: (state) => {
      return {...initialState};
    }
  },
})

export const userSelector = (state: RootState) => state.user;
export const userActions = userSlice.actions;