import React, { useState } from 'react';
import './TargetLine.scss';
import { TargetFrequency, TargetType } from '../../types/TargetType';
import { ReactComponent as Eye  } from '../../ui-components/icon/svgs/eye.svg';
import { ReactComponent as EyeLine  } from '../../ui-components/icon/svgs/eyeLine.svg';
import { ReactComponent as Camera } from '../../ui-components/icon/svgs/camera.svg';
import { ReactComponent as DayTarget } from '../../ui-components/icon/svgs/dayTarget.svg';
import { ReactComponent as BlockTarget } from '../../ui-components/icon/svgs/blockTarget.svg';
import { ReactComponent as Hash } from '../../ui-components/icon/svgs/hash.svg';
import { ReactComponent as CheckboxTarget } from '../../ui-components/icon/svgs/checkbox.svg';
import { ReactComponent as Book } from '../../ui-components/icon/svgs/book.svg';
import { TargetFormat } from '../../enums/enums';

const TargetLine: React.FC<{target: TargetType}> = ({
  target
}) => {

  const [viewDescription, setViewDescription] = useState(false);

  return (
    <div className="target-line-container">
      <div
        className="target-line-container__target-line"
        onClick={() => setViewDescription(!viewDescription)}
      >
        <div className="target-line-container__target-line__start">

            <p className="target-line-container__target-line__start__title">{target.title}</p>
            <div className="target-line-container__target-line__start__icon-row">
              {target.frequency === TargetFrequency.DAILY && (
                <div>
                  <DayTarget />
                  <p>Daily Target</p>
                </div>
              )}
              {target.frequency === TargetFrequency.BLOCK && (
                <div>
                  <BlockTarget />
                  <p>Block Target</p>
                </div>
              )}
              {target.format === TargetFormat.CHECKBOX && (
                <div>
                  <CheckboxTarget />
                  <p>Checkbox</p>
                </div>
              )}
              {target.format === TargetFormat.NUMBER && (
                <div>
                  <Hash style={{transform: "scale(0.6)"}}/>
                  <p>Number Input</p>
                </div>
              )}
              {target.isPhotoRequired && (
                <div>
                  <Camera
                    stroke="var(--dark)"
                    style={{width: "24px", height: "24px", transform: "scale(0.6)"}}
                  />
                  <p style={{color: "var(--dark)"}}>Photo</p>
                </div>
              )}
            </div>

        </div>

        {target.description && (
          <div className="target-line-container__target-line__icons">       
            {!viewDescription && (
              <Eye
                fill="var(--dark)"
                style={{transform: "translateY(1px)"}}
              />
            )}

            {viewDescription && (
              <EyeLine
                fill="var(--dark)"
              />
            )}
          </div>
        )}
      </div>

      {target.description && viewDescription && (
        <div className="target-line-container__description-cont">
          <div className="target-line-container__description-cont__top-line"><Book />Description</div>
          <textarea
            readOnly
            defaultValue={target.description}
            className="target-line-container__description"
          />
        </div>
      )}
    </div>
  )
}

export default TargetLine;