import React, { useRef, useState } from 'react';
import './PhotoInputModal.scss';
import { v4 as uuid } from 'uuid';
import Button from '../../../../../../ui-components/button/Button';
import { useAppDispatch } from '../../../../../../redux/hooks';
import { onFileSubmit, uploadPhoto } from '../Utils-Checkbox';
import { BattleType } from '../../../../../../types/BattleType';

const PhotoInputModal: React.FC<{
  battle: BattleType;
  confirmEvent: 'UPLOAD' | 'RETURN'
  onClose: () => void;
  onUpload: (event: string) => void;
  onReturn: (file: File, tempPhotoURL: string) => void;
}> = ({
  battle,
  confirmEvent,
  onClose,
  onUpload,
  onReturn
}) => {

  const dispatch = useAppDispatch();
  const addPictureRef = useRef<any>();
  const takePhotoRef = useRef<any>();
  const [newPhoto, setNewPhoto] = useState<any>(null);
  const [tempPhotoURL, setTempPhotoURL] = useState<string>('');
  const [isUploading, setIsUploading] = useState(false);
  
  const onAddPictureClick = () => {
    addPictureRef.current.click();
  }
  
  const onTakePhotoClick = () => {
    takePhotoRef.current.click();
  }

  const onUploadPhoto = async() => {
    setIsUploading(true);
    const uploadedPhoto = await uploadPhoto(newPhoto, uuid, dispatch, battle);
    if (uploadedPhoto) onUpload(uploadedPhoto.metadata.fullPath);
    setIsUploading(false);
    onClose();
  }
  
  return (
    <>
      <div className="full-screen-mask" onClick={onClose}/>

      <div className="photo-input-modal">
        <h3 className="photo-input-modal__header">Photo Required</h3>
        <div className="photo-input-modal__content">

          <p>To check this target you must add a photo.</p>

          <input
            name="photoInput"
            accept="image/*"
            type="file"
            capture="environment"
            ref={takePhotoRef}
            onChange={(event => onFileSubmit(
              event.target.files?.[0],
              dispatch,
              setNewPhoto,
              setTempPhotoURL
            ))}
          />

          <input
            name="photoInput"
            accept="image/*"
            type="file"
            ref={addPictureRef}
            onChange={(event => onFileSubmit(
              event.target.files?.[0],
              dispatch,
              setNewPhoto,
              setTempPhotoURL
            ))}
          />

        {newPhoto && <img src={tempPhotoURL} alt="Target confirmation" />}

        {newPhoto && <p className="photo-input-modal__upload-text">Use photo <span>{newPhoto?.name}</span>?</p>}
        </div>

        {!newPhoto && (
          <div className="photo-input-modal__btns">
            <Button variant="text" onClick={onAddPictureClick}>Add From Gallery</Button>
            <Button variant="text" onClick={onTakePhotoClick}>Take Photo</Button>
          </div>
        )}


        {newPhoto && (
          <div className="photo-input-modal__btns">
            <Button variant="text" onClick={() => setNewPhoto(null)}>Discard</Button>
            <Button
              variant="text"
              isSpinning={isUploading}
              spinnerVariant="LIGHT"
              onClick={() => confirmEvent === "UPLOAD" ? onUploadPhoto() : onReturn(newPhoto, tempPhotoURL)}
            >Use</Button>
          </div>
        )}
      </div>
    </>
  )
}

export default PhotoInputModal;