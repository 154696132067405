import React, { useEffect, useState } from 'react';
import './CustomizeModal.scss';
import Button from '../../../ui-components/button/Button';
import ContestantCard from '../../../components/contestant-card/ContestantCard';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { userSelector } from '../../../redux/slices/userSlice';
import ColorPicker from '../../../ui-components/color-picker/ColorPicker';
import { updateAvatar } from '../../../database/update';
import { displayToast } from '../../../helpers/app-helpers';
import Spinner from '../../../ui-components/spinner/Spinner';

const CustomizeModal: React.FC<{
  setActiveView: (activeView: "" | "CUSTOMIZE" | "NOTIFICATIONS") => void;
}> = ({
  setActiveView
}) => {

  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelector);
  const [avatarArray, setAvatarArray] = useState<any>();
  const [displayAvatarArray, setDisplayAvatarArray] = useState<any>();
  const [showSave, setShowSave] = useState(false);

  useEffect(() => {
    const baseAvatars = [
      {id: '1', colors: user.avatar.colors},
      {id: '2', colors: user.avatar.colors},
      {id: '3', colors: user.avatar.colors},
      {id: '4', colors: user.avatar.colors}
    ]
    const index = baseAvatars.findIndex(el => el.id === user.avatar.id);
    if (index || index === 0) {
      baseAvatars.splice(index, 1);
      baseAvatars.splice(1, 0, user.avatar);
    }
    setAvatarArray(baseAvatars);
  }, [])

  useEffect(() => {
    if (avatarArray) setDisplayAvatarArray(avatarArray.slice(0, 3));
  }, [avatarArray])

  const onPreviousClick = () => {
    setShowSave(true);
    const arrCopy = [...avatarArray];
    const lastEl = arrCopy.pop();
    arrCopy.unshift(lastEl);
    setAvatarArray(arrCopy);
  }

  const onNextClick = () => {
    setShowSave(true);
    const arrCopy = [...avatarArray];
    const firstEl = arrCopy.shift();
    arrCopy.push(firstEl);
    setAvatarArray(arrCopy);
  }

  const onColorChange = (color: string, field: string) => {
    setShowSave(true);
    const arrCopy = JSON.parse(JSON.stringify(avatarArray));
    arrCopy[1].colors[field] = color;
    if (field === 'skin') arrCopy[1].colors.skinShadow = `color-mix(in srgb, ${color}, black 15%)`;
    if (field === 'clothes') arrCopy[1].colors.clothesShadows = `color-mix(in srgb, ${color}, black 20%)`;
    if (field === 'shoes') arrCopy[1].colors.shoesHighlights = `color-mix(in srgb, ${color}, white 30%)`;
    if (field === 'cardBackground') arrCopy[1].colors.cardBackgroundShadow = `color-mix(in srgb, ${color}, black 10%)`;
    setAvatarArray(arrCopy);
  }

  const onSave = async() => {
    const toSave = avatarArray[1];
    const success = await updateAvatar(user.id, {
      id: toSave.id,
      colors: toSave.colors
    });

    if (success) {
      displayToast(dispatch, 'Avatar Saved');
      setShowSave(false);
    }
    else displayToast(dispatch, 'Avatar failed to save. Please try again');
  }

  const onRevert = () => {
    const arrCopy = JSON.parse(JSON.stringify(avatarArray));
    arrCopy[1].colors = user.avatar.colors;
    setAvatarArray(arrCopy);
    setShowSave(false);
  }

  if (!displayAvatarArray) return (
    <div className="customize-modal modal">
      <Spinner
        variant="LIGHT"
        style={{marginLeft: "50%"}}
      />

      <Button
        onClick={() => setActiveView("")}
      >Back</Button>
    </div>
  )

  return (
    <div className="customize-modal modal">
      <div className="customize-modal__content">
        <h1>Customize</h1>

        <div className="avatar-carousel">
          {displayAvatarArray.map((avatar: any, index: number) => {
            let className = "avatar-carousel__card";
            if (index === 0) className = className.concat(" avatar-carousel__card--subLeft");
            if (index === 2) className = className.concat(" avatar-carousel__card--subRight");

            return (
              <div className={className} key={avatar.id}>
                <ContestantCard
                  username={user.username}
                  avatar={avatar}
                  cardUser={user}
                />
              </div>
            )
          })}
        </div>

        <div className="avatar-carousel__nav">
          <h2 onClick={onPreviousClick}>&#60;</h2>
          <h4>Change Avatar</h4>
          <h2 onClick={onNextClick}>&#62;</h2>
        </div>

        <h4 style={{marginTop: "24px"}}>Colours</h4>
        <div className="customize-modal__colors">
          <ColorPicker
            previousColor={avatarArray[1].colors.hairMain}
            label="Main Hair"
            saveColorChange={(event) => onColorChange(event, "hairMain")}
          />
          <ColorPicker
            previousColor={avatarArray[1].colors.hairSecondary}
            label="Secondary Hair"
            saveColorChange={(event) => onColorChange(event, "hairSecondary")}
          />
          <ColorPicker
            previousColor={avatarArray[1].colors.skin}
            label="Skin"
            saveColorChange={(event) => onColorChange(event, "skin")}
          />
          <ColorPicker
            previousColor={avatarArray[1].colors.eyes}
            label="Eyes"
            saveColorChange={(event) => onColorChange(event, "eyes")}
          />
          <ColorPicker
            previousColor={avatarArray[1].colors.clothes}
            label="Clothes"
            saveColorChange={(event) => onColorChange(event, "clothes")}
          />
          <ColorPicker
            previousColor={avatarArray[1].colors.shoes}
            label="Shoes"
            saveColorChange={(event) => onColorChange(event, "shoes")}
          />
          <ColorPicker
            previousColor={avatarArray[1].colors.cardBackground}
            label="Card Background"
            saveColorChange={(event) => onColorChange(event, "cardBackground")}
          />
        </div>
      </div>

      <div className="customize-modal__btn-cont">
        {!showSave && (
          <Button
            onClick={() => setActiveView("")}
          >Back</Button>
        )}
        {showSave && (
          <Button
            onClick={onRevert}
            variant="cancel"
          >Revert</Button>
        )}

        {showSave && (
          <Button
            onClick={onSave}
          >Save</Button>
        )}

      </div>
    </div>
  )
}

export default CustomizeModal;