import React, { useEffect, useState } from 'react';
import './CreateBattlePage.scss';
import Button from '../../../ui-components/button/Button';
import CreateBattleCard from './create-battle-card/CreateBattleCard';
import { useNavigate } from 'react-router-dom';
import FormElement from '../../../ui-components/form-element/FormElement';
import Input from '../../../ui-components/input/Input';
import { BattleStatus, TargetFormat } from '../../../enums/enums';
import Select from '../../../ui-components/select/Select';
import IndvTargetSummary from './indv-target-summary/IndvTargetSummary';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { appDataActions, appDataSelector } from '../../../redux/slices/appDataSlice';
import TargetsSummary from './targets-summary/TargetsSummary';
import { v4 as uuid } from 'uuid';
import { TargetFrequency, TargetType } from '../../../types/TargetType';
import { battleCreationActions, battleCreationSelector } from '../../../redux/slices/battleCreation';
import dayjs, { Dayjs } from 'dayjs';
import { checkCanContinue, createBlockTargets, createDates, createDays, createTempTargetFormatDropdownItem, createTempTargetFrequencyDropdownItem } from './Utils-CreateBattlePage';
import { BattleType } from '../../../types/BattleType';
import { userSelector } from '../../../redux/slices/userSlice';
import { addBattleToDB } from '../../../database/create';
import { displayToast } from '../../../helpers/app-helpers';
import BattleSummary from './battle-summary/BattleSummary';

const enum Stage {
  INITIAL = 'INITIAL',
  TARGET_TITLE = 'TARGET_TITLE',
  TARGET_FREQUENCY = 'TARGET_FREQUENCY',
  TARGET_FORMAT = 'TARGET_FORMAT',
  TARGET_DESCRIPTION = 'TARGET_DESCRIPTION',
  INITIAL_TWO = 'INITIAL_TWO',
  START_DATE = 'START_DATE',
  NUMBER_DAYS = 'NUMBER_DAYS',
  PRIZE = 'PRIZE',
  BATTLE_DESCRIPTION = 'BATTLE_DESCRIPTION',
  FINISH = 'FINISH'
}

const blankTempTarget = {
  title: '',
  frequency: {title: 'Daily', value: TargetFrequency.DAILY},
  format: {title: 'Checkbox', value: TargetFormat.CHECKBOX},
  isPhotoRequired: false
}

const CreateBattlePage = () => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user = useAppSelector(userSelector);
  const battleCreation = useAppSelector(battleCreationSelector);
  const { opponent, prize, numDays, description, startDate } = battleCreation;
  const { tempTargets } = useAppSelector(appDataSelector);
  const [stage, setStage] = useState('INITIAL');
  const [activeTarget, setActiveTarget] = useState<any>(blankTempTarget);
  const [showErrors, setShowErrors] = useState(false);
  const [startDates, setStartDates] = useState<any>([]);
  const [defaultStartDate, setDefaultStartDate] = useState<any>();
  const [isSendingRequest, setIsSendingRequest] = useState(false);
  const [isContinueModalOpen, setIsContinueModalOpen] = useState(false);

  useEffect(() => {
    if (tempTargets.length > 0) setStage(Stage.INITIAL_TWO);
    setStartDates(createDates);

    return () => {dispatch(appDataActions.clearTempTargets())}
  }, [])

  useEffect(() => {
    if (tempTargets.length < 1) setStage(Stage.INITIAL);
  }, [tempTargets])

  useEffect(() => {
    if (startDate) setDefaultStartDate(startDates.filter((date: any) => {
      return dayjs(date.value).format('YYYY-MM-DD') === startDate
    })[0]);
  }, [startDates])

  const onBackClick = (stage?: Stage) => {
    if (stage) setStage(stage);
    else navigate(-1);
  }

  const onNextClick = (stage: Stage, valField?: string) => {
    setShowErrors(true);
    const canContinue = checkCanContinue(errHandler, valField);
    
    if (canContinue) {
      if (stage === Stage.INITIAL_TWO) {
        if (activeTarget.id) {
          dispatch(appDataActions.updateTempTarget({
            ...activeTarget,
            format: activeTarget.format.value,
            frequency: activeTarget.frequency.value
          }));
        } else {
          dispatch(appDataActions.addTempTarget({
            ...activeTarget,
            format: activeTarget.format.value,
            frequency: activeTarget.frequency.value,
            id: uuid()
          }));
        }
        setActiveTarget(blankTempTarget);
      }

      setShowErrors(false);
      setStage(stage);
    }
  }

  const errHandler = {
    title: {
      criteria: activeTarget?.title?.length >= 1 && activeTarget?.title?.length <= 30,
      message: 'Must be between 1 and 30 characters'
    },
    frequency: {
      criteria: activeTarget?.frequency,
      message: 'Required'
    },
    format: {
      criteria: activeTarget?.format,
      message: 'Required'
    },
    description: {
      criteria: activeTarget?.description
        ? activeTarget?.description?.length <= 200
        : true,
      message: `Maximum of 200 characters.`
    },
    prize: {
      criteria: battleCreation.prize
        ? battleCreation.prize.length <= 15
        : true,
      message: 'Must be less than 15 characters. Further details may be added in the Description/Rules section below.'
    },
    startDate: {
      criteria: startDate && !dayjs(battleCreation.startDate).isBefore(dayjs(), 'day'),
      message: 'Required. Cannot be in the past.'
    },
    numDays: {
      criteria: +numDays >= 1 && numDays <= 31,
      message: 'Must be between 1 and 31'
    },
    battleDescription: {
      criteria: description.length <= 500,
      message: `Maximum of 500 characters.`
    }
  }

  const setTargetToEdit = (target: TargetType) => {
    setActiveTarget({
      ...target,
      format: createTempTargetFormatDropdownItem(target.format),
      frequency: createTempTargetFrequencyDropdownItem(target.frequency)
  });
    setStage(Stage.TARGET_TITLE);
  }

  const onStartDateChange = (date: Dayjs) => {
    const formattedDate = date.format('YYYY-MM-DD');
    dispatch(battleCreationActions.updateBattleField({field: 'startDate', value: formattedDate}));
  }

  const onBattleFieldChange = (update: any) => {
    dispatch(battleCreationActions.updateBattleField(update));
  }

  const onTextAreaChange = (text: string) => {
    onBattleFieldChange({field: "description", value: text})
  }
  
  const onSend = async() => {
    setIsSendingRequest(true);
    const id = battleCreation.id ? battleCreation.id :  uuid();
    const numDailyTargets = tempTargets.filter((target: TargetType) => {
      return target.frequency === TargetFrequency.DAILY
    }).length
    const hp = numDailyTargets ? numDailyTargets * 2 : 1;

    const battle: BattleType = {
      id,
      title: '',
      startDate,
      numDays,
      description,
      prize,
      status: BattleStatus.PENDING,
      teamBattle: false,
      userIds: [user.id, opponent.id],
      challenger: {
        id: user.id,
        username: user.username,
        hp,
        zeroHpDate: null
      },
      opponent: {
        ...opponent,
        hp,
        zeroHpDate: null
      },
      result: {
        winner: {
          id: '',
          username: ''
        },
        draw: false,
        challengerDayTally: 0,
        opponentDayTally: 0,
        challengerTargetTally: 0,
        opponentTargetTally: 0,
        kOWin: false,
        challengerHp: 0,
        opponentHp: 0
      },
      days: createDays(startDate, numDays, tempTargets, user.id, opponent.id),
      blockTargets: createBlockTargets(tempTargets, user.id, opponent.id)
    }

    const success = await addBattleToDB(battle);

    if (!success) {
      displayToast(dispatch, "Battle request failed to send. Please try again.")
      setIsSendingRequest(false);
      return;
    }

    displayToast(dispatch, "Battle request sent.");
    dispatch(appDataActions.clearTempTargets());
    dispatch(battleCreationActions.resetState());
    navigate('/home');
  }

  const onContinueFromTargetsClick = () => {
    const hasDailyTargets = tempTargets.filter((target: TargetType) => target.frequency === TargetFrequency.DAILY).length;
    if (!hasDailyTargets) {
      setIsContinueModalOpen(true);
    }
    else onNextClick(Stage.START_DATE);
  }

  const onContinueConfirm = () => {
    setIsContinueModalOpen(false);
    onNextClick(Stage.START_DATE);
  }

  if (stage === Stage.FINISH) {
    return (
      <div className="create-battle-page">
        <CreateBattleCard
            firstLine="Finished!"
          >
            <p>The battle is now ready to be sent to your opponent for review.</p>
            <p>Your opponent may accept, reject or edit the battle. If they edit any details then the request will come back to you for you to approve, decline or edit again.</p>
            <p>Click Complete when you are ready or go back to edit the battle.</p>
            
            <div className="create-battle-card__btn-cont">
              <Button
                variant="cancel"
                onClick={() => onBackClick(Stage.PRIZE)}
              >Back</Button>

              <Button
                variant="default"
                isSpinning={isSendingRequest}
                onClick={onSend}
              >Complete</Button>
            </div>
          </CreateBattleCard>

          <BattleSummary battle={battleCreation} setTargetToEdit={setTargetToEdit}/>
      </div>
    )
  }

  if (stage === Stage.BATTLE_DESCRIPTION) {
    return (
      <div className="create-battle-page">
        <CreateBattleCard
            firstLine="Description"
            stage="5/5"
          >
            <p>Add details here for anything that may require more of an explanation for the battle as a whole. (What exactly is the prize/forfeit? Names of moderators.)</p>
            
            <FormElement
              label="Description/Rules"
              errorMessage={showErrors && !errHandler.description.criteria && errHandler.description.message}
            >
              <textarea
                value={description}
                placeholder="Optional"
                onChange={(event) => onTextAreaChange(event.target.value)}
              />
              <p className="text-area-count">{description.length}/500</p>
            </FormElement>
            
            <div className="create-battle-card__btn-cont">
              <Button
                variant="cancel"
                onClick={() => onBackClick(Stage.PRIZE)}
              >Back</Button>

              <Button
                variant="default"
                onClick={() => onNextClick(Stage.FINISH, "battleDescription")}
              >Next</Button>
            </div>
          </CreateBattleCard>

          <BattleSummary battle={battleCreation} setTargetToEdit={setTargetToEdit}/>
      </div>
    )
  }

  if (stage === Stage.PRIZE) {
    return (
      <div className="create-battle-page">
        <CreateBattleCard
            firstLine="Prize/Forfeit"
            stage="4/5"
          >
            <p>If you would like to add a prize/forfeit to the battle then enter this below, otherwise click next.</p>
            <p>This is a summary of less than 16 characters. A detailed explanation may be added in the next section.</p>
            
            <FormElement
              label="Prize/Forfeit"
              errorMessage={showErrors && !errHandler.prize.criteria && errHandler.prize.message}
            >
              <Input
                name="battle-prize"
                placeholder="Optional"
                value={prize}
                onChange={(event) => onBattleFieldChange({field: "prize", value: event.target.value})}
              />
            </FormElement>

            <p className="create-battle-card__example-text">Examples: £10, Coffee, Make Dinner.</p>
            
            <div className="create-battle-card__btn-cont">
              <Button
                variant="cancel"
                onClick={() => onBackClick(Stage.NUMBER_DAYS)}
              >Back</Button>

              <Button
                variant="default"
                onClick={() => onNextClick(Stage.BATTLE_DESCRIPTION, "prize")}
              >Next</Button>
            </div>
          </CreateBattleCard>

          <BattleSummary battle={battleCreation} setTargetToEdit={setTargetToEdit}/>
      </div>
    )
  }

  if (stage === Stage.NUMBER_DAYS) {
    return (
      <div className="create-battle-page">
        <CreateBattleCard
            firstLine="Days"
            stage="3/5"
          >
            <p>How many days should this battle run for? This must be less than or equal to 31.</p>

            <FormElement
              label="Number of Days"
              errorMessage={showErrors && !errHandler.numDays.criteria && errHandler.numDays.message}
            >
              <Input
                name="battle-number-of-days"
                type="number"
                value={numDays > 0 ? numDays.toString() : ""}
                onChange={(event) => onBattleFieldChange({field: "numDays", value: event.target.value})}
              />
            </FormElement>
            
            <div className="create-battle-card__btn-cont">
              <Button
                variant="cancel"
                onClick={() => onBackClick(Stage.START_DATE)}
              >Back</Button>

              <Button
                variant="default"
                onClick={() => onNextClick(Stage.PRIZE, "numDays")}
              >Next</Button>
            </div>
          </CreateBattleCard>
          
          <BattleSummary battle={battleCreation} setTargetToEdit={setTargetToEdit}/>
      </div>
    )
  }

  if (stage === Stage.START_DATE) {
    return (
      <div className="create-battle-page">
        <CreateBattleCard
            firstLine="Start Date"
            stage="2/5"
          >
            <p>Now that the daily targets are set up, let's flesh out the battle details.</p>
            <p>When will this battle start?</p>

            <FormElement
              label="Start Date"
              errorMessage={showErrors && !errHandler.startDate.criteria && errHandler.startDate.message}
            >
              <Select
                name="startDate"
                options={startDates.map((el: any) => {
                  return (
                    {title: el.title, value: el.value}
                  )
                })}
                defaultValue={defaultStartDate}
                onChange={(event) => onStartDateChange(event.value)}
              />
            </FormElement>
            
            <div className="create-battle-card__btn-cont">
              <Button
                variant="cancel"
                onClick={() => onBackClick(Stage.INITIAL_TWO)}
              >Back</Button>

              <Button
                variant="default"
                onClick={() => onNextClick(Stage.NUMBER_DAYS, "startDate")}
              >Next</Button>
            </div>
          </CreateBattleCard>
      </div>
    )
  }

  if (stage === Stage.INITIAL_TWO) {
    return (
      <div className="create-battle-page">
        <CreateBattleCard
            firstLine="Create Battle"
            stage="1/5"
          >
            <p>Target added! You may add more targets or continue to the next stage.</p>

            <TargetsSummary setTargetToEdit={setTargetToEdit}/>
            <Button
              variant="white"
              size="small"
              onClick={() => setStage(Stage.TARGET_TITLE)}
            >+ Add Another Target</Button>
            
            <div className="create-battle-card__btn-cont">
              <Button
                variant="cancel"
                onClick={() => onBackClick()}
              >Cancel</Button>

              <Button
                variant="default"
                onClick={() => onContinueFromTargetsClick()}
              >Continue</Button>
            </div>
          </CreateBattleCard>

          {isContinueModalOpen && (
            <>
              <div className="full-screen-mask" />
              <div className="create-battle-page__modal">
                <h2>Continue?</h2>
                <p>You have not set up any Daily Targets. Although not essential, Daily Targets improve the effectiveness of a battle by keeping both contestants engaged and active.</p>
                <p>Do you wish to continue without setting up a Daily Target?</p>

                <div className="create-battle-page__modal__btns">
                  <Button
                    variant="text"
                    onClick={onContinueConfirm}
                  >
                    Continue
                  </Button>
                  <Button
                    variant="text"
                    style={{color: "var(--main)"}}
                    onClick={() => setIsContinueModalOpen(false)}
                  >
                    Back
                  </Button>
                </div>
              </div>
            </>
          )}
      </div>
    )
  }

  if (stage === Stage.TARGET_DESCRIPTION) {
    return (
      <div className="create-battle-page">
        <CreateBattleCard
            firstLine="Description"
            stage="4/4"
          >
            <p>Add a detailed description so both contestants know exactly what is expected to complete this target.</p>

            <FormElement
              label="Description"
              errorMessage={showErrors && !errHandler.description.criteria && errHandler.description.message}
            >
              <textarea
                style={{marginTop: "8px"}}
                placeholder="Optional"
                value={activeTarget.description}
                onChange={(event) => setActiveTarget({...activeTarget, description: event.target.value})}
              />
              <p className="text-area-count">{activeTarget.description?.length}/200</p>
            </FormElement>

            <div className="create-battle-card__btn-cont">
              <Button
                variant="white"
                onClick={() => onBackClick(Stage.TARGET_FORMAT)}
              >Back</Button>

              <Button
                variant="default"
                onClick={() => onNextClick(Stage.INITIAL_TWO, "description")}
              >Save</Button>
            </div>
          </CreateBattleCard>
          <IndvTargetSummary activeTarget={activeTarget} />
      </div>
    )
  }

  if (stage === Stage.TARGET_FORMAT) {
    return (
      <div className="create-battle-page">
        <CreateBattleCard
            firstLine="Target Type"
            stage="3/4"
          >
            <p>Select which type of target this should be.</p>
            <ul>
              <li><strong>Checkbox</strong>: A simple yes/no checkbox if target is complete.</li>
              <li><strong>Number</strong>: A number input field. This is good for targets such as Daily Steps, distance ran etc.</li>
            </ul>
            {(activeTarget.format.value === TargetFormat.CHECKBOX || activeTarget.frequency.value === TargetFrequency.DAILY) && <p>Check the photo confirmation box if you want to force a photo to be uploaded before the target can be completed.</p>}

            <FormElement
              label="Format"
              errorMessage={showErrors && !errHandler.format.criteria && errHandler.format.message}
            >
              <Select
                name="format"
                options={[
                  {title: "Checkbox", value: TargetFormat.CHECKBOX},
                  {title: "Number", value: TargetFormat.NUMBER}
                ]}
                defaultValue={activeTarget.format}
                onChange={(event) => setActiveTarget({...activeTarget, format: event})}
              />
            </FormElement>

            {
              (activeTarget.format.value === TargetFormat.CHECKBOX || activeTarget.frequency.value === TargetFrequency.DAILY)
                && (
                  <>
                    <input type="checkbox" checked={activeTarget.isPhotoRequired} name="photoConfirmation" onChange={() => setActiveTarget({...activeTarget, isPhotoRequired: !activeTarget.isPhotoRequired})}/>
                    <label
                      htmlFor="photoConfirmation"
                      style={{marginLeft: "4px"}}
                    >Require photo confirmation?</label>
                  </>
            )}

            <div className="create-battle-card__btn-cont">
              <Button
                variant="white"
                onClick={() => onBackClick(Stage.TARGET_TITLE)}
              >Back</Button>

              <Button
                variant="default"
                onClick={() => onNextClick(Stage.TARGET_DESCRIPTION, "format")}
              >Next</Button>
            </div>
          </CreateBattleCard>

          <IndvTargetSummary activeTarget={activeTarget} />
      </div>
    )
  }

  if (stage === Stage.TARGET_FREQUENCY) {
    return (
      <div className="create-battle-page">
        <CreateBattleCard
            firstLine="Target Frequency"
            stage="2/4"
          >
            <p>Should this target be monitored daily or at the end of the battle?</p>
            <h4>Daily Target</h4>
            <p>This should be something that is achievable every day. i.e. Exercise for 20 minutes, X number of steps, eat 2 healthy meals.</p>
            <h4>Block Target</h4>
            <p>This should be something that you wish to accomplish over the whole battle, but not necessarily every day. i.e. Run 50km, Reach a new PB.</p>

            <FormElement
              label="Frequency"
              errorMessage={showErrors && !errHandler.frequency.criteria && errHandler.frequency.message}
            >
              <Select
                name="frequency"
                options={[
                  {title: "Daily", value: TargetFrequency.DAILY},
                  {title: "Block", value: TargetFrequency.BLOCK}
                ]}
                defaultValue={activeTarget.frequency}
                onChange={(event) => setActiveTarget({...activeTarget, frequency: event})}
              />
            </FormElement>

            <div className="create-battle-card__btn-cont">
              <Button
                variant="white"
                onClick={() => onBackClick(Stage.TARGET_TITLE)}
              >Back</Button>

              <Button
                variant="default"
                onClick={() => onNextClick(Stage.TARGET_FORMAT, "frequency")}
              >Next</Button>
            </div>
          </CreateBattleCard>

          <IndvTargetSummary activeTarget={activeTarget} />
      </div>
    )
  }

  if (stage === Stage.TARGET_TITLE) {
    return (
      <div className="create-battle-page">
        <CreateBattleCard
            firstLine="Target Title"
            stage="1/4"
          >
            <p>What else do you want to achieve?</p>

            <FormElement
              label="Target Title"
              errorMessage={showErrors && !errHandler.title.criteria && errHandler.title.message}
            >
              <Input
                name="title"
                // passRef={firstRef}
                placeholder="Title..."
                value={activeTarget.title}
                onChange={(event) => setActiveTarget({...activeTarget, title: event.target.value})}
              />
            </FormElement>

            <p className="create-battle-card__example-text">Examples: Run X Distance, Hit the gym, X number of steps.</p>

            <div className="create-battle-card__btn-cont">
              <Button
                variant="white"
                onClick={() => onBackClick(Stage.INITIAL)}
              >Back</Button>

              <Button
                variant="default"
                onClick={() => onNextClick(Stage.TARGET_FREQUENCY, "title")}
              >Next</Button>
            </div>
          </CreateBattleCard>
      </div>
    )
  }

  return (
    <div className="create-battle-page">
      <CreateBattleCard
          firstLine="Create Battle"
        >
          <p>OK, let's set up your battle.</p>
          <p>First of all we will create a target. Targets are at the core of PvP Health and are how battles are won or lost.</p>
          <p>Add a brief title of less than 31 characters. (A more detailed description may be added later).</p>

          <FormElement
            label="1st Target Title"
            errorMessage={showErrors && !errHandler.title.criteria && errHandler.title.message}
          >
            <Input
              name="title"
              placeholder="Title..."
              value={activeTarget.title}
              onChange={(event) => setActiveTarget({...activeTarget, title: event.target.value})}
            />
          </FormElement>

          <p className="create-battle-card__example-text">Examples: Run X Distance, Hit the gym, X number of steps, eat 2 healthy meals.</p>

          <div className="create-battle-card__btn-cont">
            <Button
              variant="white"
              onClick={() => onBackClick()}
            >Back</Button>

            <Button
              variant="default"
              onClick={() => onNextClick(Stage.TARGET_FREQUENCY, "title")}
            >Next</Button>
          </div>
        </CreateBattleCard>
    </div>
  )
}

export default CreateBattlePage;