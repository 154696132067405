export enum TargetFormat {
  CHECKBOX = 'CHECKBOX',
  NUMBER = 'NUMBER'
}

export enum BattleStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  COMPLETE = 'COMPLETE',
  DECLINED = 'DECLINED'
}