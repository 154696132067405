import React, { useEffect, useState } from 'react';
import './BattlesPage.scss';
import Card from '../../ui-components/card/Card';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { BattleType } from '../../types/BattleType';
import BattleEntry from './battle-entry/BattleEntry';
import { useNavigate } from 'react-router-dom';
import { displayToast } from '../../helpers/app-helpers';
import { battlesSelector } from '../../redux/slices/battlesSlice';
import { BattleStatus } from '../../enums/enums';
import { appDataActions } from '../../redux/slices/appDataSlice';

const BattlesPage = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const battles = useAppSelector(battlesSelector);

  const [sortedBattles, setSortedBattles] = useState<BattleType[]>([]);
  const [completedSortedBattles, setCompletedSortedBattles] = useState<BattleType[]>([]);

  useEffect(() => {
    sortBattles();
  }, [])

  const sortBattles = async() => {
    const sortedBattles: BattleType[] = [];
    const completedBattles: BattleType[] = [];

    if (battles.length > 0) {
      for (const battle of battles) {
        if (battle.status === BattleStatus.ACTIVE) sortedBattles.push(battle);

        if (battle.status === BattleStatus.COMPLETE) completedBattles.push(battle);

        if (battle.status === BattleStatus.DECLINED) completedBattles.push(battle);

        if (battle.status === BattleStatus.PENDING) sortedBattles.push(battle);
      }

      sortedBattles.sort((a: BattleType, b: BattleType) => +new Date(b.startDate) - +new Date(a.startDate))
      completedBattles.sort((a: BattleType, b: BattleType) => +new Date(b.startDate) - +new Date(a.startDate))
      setCompletedSortedBattles(completedBattles);
      setSortedBattles(sortedBattles);
    }
    else displayToast(dispatch, "No battles found.");
  }

  const onBattleSelect = (battle: BattleType) => {
    dispatch(appDataActions.setCurrentBattleId(battle.id));

    if (battle.status === BattleStatus.PENDING || battle.status === BattleStatus.DECLINED) {
      navigate(`/battle-response?id=${battle.id}`);
    }

    if (battle.status === BattleStatus.ACTIVE || battle.status === BattleStatus.COMPLETE) {
      dispatch(appDataActions.setCurrentBattleId(battle.id));
      navigate('/battle')
    }
  }

  return (
    <>
      <Card
        variant="LIGHT"
        title="Active Battles"
        backUrl="/home"
      >   
        {sortedBattles?.map((battle: BattleType, index: number) => {
          return (
            <BattleEntry
              battle={battle}
              key={battle.id}
              section="CURRENT"
              onClick={() => onBattleSelect(battle)}
            />
          )
        })}

        {sortedBattles?.length < 1 && (
          <p>No battles found.</p>
        )}
      </Card>

      {completedSortedBattles?.length > 0 && (
        <Card
          variant="DARK"
          title="Previous Battles"
          backUrl=""
        >
          {completedSortedBattles.map((battle: BattleType, index: number) => {
            return (
              <BattleEntry
                battle={battle}
                key={battle.id}
                section="HISTORY"
                onClick={() => onBattleSelect(battle)}
              />
            )
          })}
        </Card>
      )}
    </>
  )
}

export default BattlesPage;