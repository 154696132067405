import dayjs from "dayjs";
import { BattleType } from "../types/BattleType";
import { BattleStatus, TargetFormat } from "../enums/enums";
import { updateBattleStatus, updateBattleStatusAndResult } from "../database/update";
import { ContestantType } from "../types/ContestantType";
import { appDataActions } from "../redux/slices/appDataSlice";
import { BattleTypeDB } from "../types/BattleTypeDB";
import { TargetType } from "../types/TargetType";
import { ContestantTargetType } from "../types/ContestantTargetType";

// export const updateAppPrimaryColor = (color: string) => {
//   const root = document.documentElement;
//   root?.style.setProperty("--primary", color);
// }

export const processBattles = async(battles: BattleType[], userId: string, dispatch: any, onError: any) => {
  if (battles.length > 0) {
    const previousOpponents: ContestantType[] = [];
    const today = dayjs();

    for (const battle of battles) {
      const opponent = battle.challenger.id === userId ? battle.opponent : battle.challenger;
      if (!previousOpponents.some(e => e.id === opponent.id)) previousOpponents.push(opponent);

      const startDate = dayjs(battle.startDate);

      // TODO Streamline this into a Cloud Function
      if (battle.status === BattleStatus.ACTIVE) {
        const challengerHealthZero = (
          battle.challenger.hp < 1 && dayjs(battle.challenger.zeroHpDate).isBefore(dayjs(), 'day')
        );
        const opponentHealthZero = (
          battle.opponent.hp < 1 && dayjs(battle.opponent.zeroHpDate).isBefore(dayjs(), 'day')
        )

        const kO = challengerHealthZero || opponentHealthZero;
        if (kO) {
          const result = calcBattleResult(battle, kO);
          await updateBattleStatusAndResult(battle.id, BattleStatus.COMPLETE, result, onError);
          return;
        }

        const finishDate = startDate.add(battle.numDays - 1, 'day');
        const finished = today.isAfter(finishDate, 'day');

        if (finished) {
          const result = calcBattleResult(battle);
          await updateBattleStatusAndResult(battle.id, BattleStatus.COMPLETE, result, onError);
        }
      }

      if (battle.status === BattleStatus.PENDING) {
        const startedInPast = today.isAfter(startDate, 'day');
        if (startedInPast) {
          await updateBattleStatus(battle.id, BattleStatus.DECLINED, onError);
        }
      }
    }

    dispatch(appDataActions.setPreviousOpponents(previousOpponents));
  }
}

export const calcBattleResult = (battle: BattleTypeDB, kO = false) => {
  const challengerId = battle.challenger.id as keyof TargetType;
  const opponentId = battle.opponent.id as keyof TargetType;

  const daysResult = {
    challenger: 0,
    opponent: 0
  }
  const totalDailyNumbers: any = {};
  const days = battle.days;
  const hasDays = Object.keys(days).length > 0;
  const hasBlockTargets = Object.keys(battle.blockTargets).length > 0;

  if (hasDays) {
    for (const el in days) {
      const day = days[el];
      const dayResult = {
        challenger: 0,
        opponent: 0
      }
  
      for (const el in day) {
        const target: TargetType = day[el];
        const challenger = target[challengerId] as ContestantTargetType;
        const opponent = target[opponentId] as ContestantTargetType;
  
        if (target.format === TargetFormat.CHECKBOX) {
          if (target.isPhotoRequired) {
            if (challenger.photo) dayResult.challenger++;
            if (opponent.photo) dayResult.opponent++;
          } else {
            if (challenger.input) dayResult.challenger++;
            if (opponent.input) dayResult.opponent++;
          }
        }
  
        if (target.format === TargetFormat.NUMBER) {
          if (!totalDailyNumbers[target.id]) totalDailyNumbers[target.id] = {challenger: 0, opponent: 0};
  
          const challengerInput = +challenger.input || 0;
          const opponentInput = +opponent.input || 0;
  
          if (target.isPhotoRequired) {
            if ((challengerInput > opponentInput) && challenger.photo) {
              dayResult.challenger++;
              totalDailyNumbers[target.id].challenger += challengerInput;
            }
            if ((opponentInput > challengerInput) && opponent.photo) {
              dayResult.opponent++;
              totalDailyNumbers[target.id].opponent += opponentInput;
            }
          } else {
            if (challengerInput > opponentInput) {
              dayResult.challenger++;
              totalDailyNumbers[target.id].challenger += challengerInput;
            }
            if (opponentInput > challengerInput) {
              dayResult.opponent++;
              totalDailyNumbers[target.id].opponent += opponentInput;
            }
          }
        }
      }
  
      if (dayResult.challenger > dayResult.opponent) daysResult.challenger++
      if (dayResult.challenger < dayResult.opponent) daysResult.opponent++
    }
  }


  const result = {
    draw: false,
    winner: {},
    challengerDayTally: daysResult.challenger,
    opponentDayTally: daysResult.opponent,
    kOWin: false,
    challengerHp: +battle.challenger.hp,
    opponentHp: +battle.opponent.hp
  }

  if (kO) {
    result.kOWin = true;
    if (battle.challenger.hp < 1 && dayjs(battle.challenger.zeroHpDate).isBefore(dayjs(), 'day')) {
      result.winner = battle.opponent;
    }
    else if (battle.opponent.hp < 1 && dayjs(battle.opponent.zeroHpDate).isBefore(dayjs(), 'day')) {
      result.winner = battle.challenger;
    }
    return result;
  }

  if (hasBlockTargets) {
    for (const el in battle.blockTargets) {
      const target: TargetType = battle.blockTargets[el];
      const challengerTarget = target[challengerId] as ContestantTargetType;
      const opponentTarget = target[opponentId] as ContestantTargetType;
      const completionPoints: number = +battle.numDays;

      if (target.format === TargetFormat.CHECKBOX) {
        if (challengerTarget.input) result.challengerHp += completionPoints;
        if (opponentTarget.input) result.opponentHp += completionPoints;
      }

      if (target.format === TargetFormat.NUMBER) {
        if (challengerTarget.inputArray) {
          const challengerTotal = challengerTarget.inputArray?.reduce((sum: number, el: any) => sum + +el.value, 0);
          const opponentTotal = opponentTarget.inputArray?.reduce((sum: number, el: any) => sum + +el.value, 0);
          
          if (challengerTotal > opponentTotal) result.challengerHp += completionPoints;
          if (opponentTotal > challengerTotal) result.opponentHp += completionPoints;
        }
        if (!challengerTarget.inputArray) {
          if (challengerTarget.input > opponentTarget.input) result.challengerHp += completionPoints;
          if (opponentTarget.input > challengerTarget.input) result.opponentHp += completionPoints;
        }
      }
    }
  }

  if (result.challengerHp > result.opponentHp) {
    result.winner = battle.challenger;
    return result;
  } else if (result.opponentHp > result.challengerHp) {
    result.winner = battle.opponent;
    return result;
  }

  if (daysResult.challenger > daysResult.opponent) {
    result.winner = battle.challenger;
    return result;
  }
  else if (daysResult.challenger < daysResult.opponent) {
    result.winner = battle.opponent;
    return result;
  }

  if (hasDays) {
    const numbersWinner = calcTotalDailyNumbersWinner(totalDailyNumbers);
  
    if (numbersWinner.challenger > numbersWinner.opponent) result.winner = battle.challenger
    else if (numbersWinner.opponent > numbersWinner.challenger) result.winner = battle.opponent
    else result.draw = true;
  }
  else result.draw = true;
  
  return result;
}

const calcTotalDailyNumbersWinner = (totalDailyNumbers: any) => {
  const targetsWon = {
    challenger: 0,
    opponent: 0
  }

  for (const el in totalDailyNumbers) {
    const target = totalDailyNumbers[el];
    if (target.challenger > target.opponent) targetsWon.challenger++;
    if (target.opponent > target.challenger) targetsWon.opponent++;
  }

  return targetsWon;
}