import React, { useEffect, useState } from 'react';
import './DayWidget.scss';
import dayjs from 'dayjs';
import { ReactComponent as Plus  } from '../../../../ui-components/icon/svgs/plus.svg';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { BattleStatus, TargetFormat } from '../../../../enums/enums';
import { BattleType } from '../../../../types/BattleType';
import { appDataActions } from '../../../../redux/slices/appDataSlice';
import { DayType } from '../../../../types/DayType';
import { userSelector } from '../../../../redux/slices/userSlice';
import { TargetType } from '../../../../types/TargetType';
import { ContestantTargetType } from '../../../../types/ContestantTargetType';

const DayWidget: React.FC<{
  setActiveView?: (event: 'CALENDER' | 'DAY') => void;
  day?: DayType;
  battle: BattleType;
}> = ({
  setActiveView,
  day,
  battle
}) => {

  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelector);
  const [background, setBackground] = useState('var(--light)');
  const [score, setScore] = useState<any>();

  useEffect(() => {
    if (day) getAndSetScore();
  }, [])

  useEffect(() => {
    getAndSetBackground();
  }, [score])

  const getAndSetScore = () => {
    if (battle?.result.kOWin) {
      let loserZeroHpDate;
      if (battle.challenger.id === battle.result.winner.id) loserZeroHpDate = battle.opponent.zeroHpDate
      else loserZeroHpDate = battle.challenger.zeroHpDate

      if (dayjs(day?.date).isAfter(dayjs(loserZeroHpDate), 'day')) {
        setScore('KO');
        return;
      }
    }
    
    if (
      dayjs(day?.date).isAfter(dayjs(), 'day')
      || dayjs(day?.date).isSame(dayjs(), 'day')
    ) {
      setScore(null);
      return;
    };

    const tally = {
      challengerScore: 0,
      opponentScore: 0
    }

    if (day?.targets) {
      const challengerId = battle?.challenger.id as keyof TargetType;
      const opponentId = battle?.opponent.id as keyof TargetType;
      
      for (const target of day.targets) {
        const challenger = target[challengerId] as ContestantTargetType;
        const opponent = target[opponentId] as ContestantTargetType;

        if (target.format === TargetFormat.CHECKBOX) {
          if (target.isPhotoRequired) {
            if (challenger.input && challenger.photo) tally.challengerScore++;
            if (opponent.input && opponent.photo) tally.opponentScore++;
          } else {
            if (challenger.input) tally.challengerScore++;
            if (opponent.input) tally.opponentScore++;
          }
        }

        if (target.format === TargetFormat.NUMBER) {
          const challengerInput = +challenger.input || 0;
          const opponentInput = +opponent.input || 0;
          if (target.isPhotoRequired) {
            if ((challengerInput > opponentInput) && challenger.photo) tally.challengerScore++;
            if ((opponentInput > challengerInput) && opponent.photo) tally.opponentScore++;
          } else {
            if (challengerInput > opponentInput) tally.challengerScore++;
            if (opponentInput > challengerInput) tally.opponentScore++;
          }
        }
      }
    }

    if (tally.opponentScore === tally.challengerScore) {
      setScore('D');
      return;
    }

    const isChallenger = battle?.challenger.id === user?.id ? true : false;

    const scores = {
      userScore: isChallenger ? tally.challengerScore : tally.opponentScore,
      opponentScore: isChallenger ? tally.opponentScore : tally.challengerScore
    }
    
    setScore(`${scores.userScore}-${scores.opponentScore}`);
  }

  const getAndSetBackground = () => {
    let background = 'var(--light)';
    if (!day) {
      setBackground('transparent');
      return;
    }
    if (!score) return;
    if (score === 'KO') {
      if (battle.result.winner.id === user.id) {
        background = 'var(--success75)';
      } else {
        background = 'var(--red75)';
      }
    }

    const scoreArr = score.split('-');
    const trimmedArr = [];
    for (let score of scoreArr) trimmedArr.push(score.trim());

    if (+trimmedArr[0] === +trimmedArr[1]) return;
    if (+trimmedArr[0] > +trimmedArr[1]) background = 'var(--success75)';
    if (+trimmedArr[0] < +trimmedArr[1]) background = 'var(--red75)';
    setBackground(background);
  }

  const onClick = (event: any) => {
    event.stopPropagation();
    dispatch(appDataActions.setCurrentDayKey(day?.dayKey));
    if (setActiveView) setActiveView("DAY");
  }

  if (score) return (
    <div
      className="day-widget"
      style={{background: background}}
      onClick={onClick}
    >
      {score}
    </div>
  )

  if (day && dayjs(day.date).isSame(dayjs(), 'day')) return (
    <div
      className="day-widget day-widget--pulse"
      style={{background: "var(--main)"}}
      onClick={(event) => battle.status === BattleStatus.ACTIVE ? onClick(event) : null}
    >
      {day && <Plus stroke="var(--light)" style={{margin: "0 auto"}}/>}
    </div>
  )

  return (
    <div
      className="day-widget"
      style={{background: background}}
    >
      {day && <p>{dayjs(day.date).format('D')}</p>}
      {day && <p>{dayjs(day.date).format('MMM')}</p>}
    </div>
  )
}

export default DayWidget;