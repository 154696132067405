import React, { useEffect, useState } from 'react';
import './BattleCompletePage.scss';
import { useAppSelector } from '../../redux/hooks';
import { userSelector } from '../../redux/slices/userSlice';
import { BattleType } from '../../types/BattleType';
import { ContestantType } from '../../types/ContestantType';
import { TargetFormat } from '../../enums/enums';
import { ReactComponent as Heart } from '../../ui-components/icon/svgs/heart.svg';
import { ReactComponent as BlockTarget } from '../../ui-components/icon/svgs/blockTarget.svg';
import { ReactComponent as DayTarget } from '../../ui-components/icon/svgs/dayTarget.svg';

const BattleCompletePage: React.FC<{
  battle: BattleType;
  otherContestant: ContestantType;
}> = ({
  battle,
  otherContestant
}) => {

  const user = useAppSelector(userSelector);
  const [resultObj, setResultObj] = useState<any>();
  const isChallengerWinner = battle?.result.winner.id === battle?.challenger.id;
  const isUserChallenger = user.id === battle?.challenger.id;
  const hasDays = battle.days.length > 0;
  const hasBlockTargets = battle.blockTargets.length > 0;

  useEffect(() => {
    sortResult();
  }, [otherContestant])

  const sortResult = () => {
    if (!battle?.result.draw && battle?.result.winner.id === user.id) {
      setResultObj({
        winner: {
          username: user.username,
          blockTargetWins: calcBlockTargetWins(user, otherContestant)
        },
        loser: {
          username: otherContestant?.username,
          blockTargetWins: calcBlockTargetWins(otherContestant, user)
        },
        
      })
    };

    if (!battle?.result.draw && battle?.result.winner.id === otherContestant?.id) {
      setResultObj({
        winner: {
          username: otherContestant?.username,
          blockTargetWins: calcBlockTargetWins(otherContestant, user)
        },
        loser: {
          username: user.username,
          blockTargetWins: calcBlockTargetWins(user, otherContestant)
        }
      })
    };
  }

  const calcBlockTargetWins = (contestant: any, otherContestant: any) => {
    let wins = 0;

    if (battle.blockTargets.length > 0) {
      for (const target of battle.blockTargets) {
        if (target.format === TargetFormat.CHECKBOX && target[contestant.id].input) wins++
        if (target.format === TargetFormat.NUMBER && target[contestant.id].inputArray) {
          const contestantTotal = target[contestant.id].inputArray?.reduce((sum: number, el: any) => sum + +el.value, 0);
          const otherContestantTotal = target[otherContestant.id].inputArray?.reduce((sum: number, el: any) => sum + +el.value, 0);
          if (contestantTotal > otherContestantTotal) wins++
        }
        if (target.format === TargetFormat.NUMBER && !target[contestant.id].inputArray) {
          const contestantTotal = target[contestant.id].input;
          const otherContestantTotal = target[otherContestant.id].input;
          if (contestantTotal > otherContestantTotal) wins++
        }
      }
    }

    return wins;
  }

  return (
    <>
      {!battle?.result.draw && resultObj && (
        <div className="battle-complete">
          <div>
            {!battle.result.draw && <p className="battle-complete__winner-icon">Winner!</p>}
            <p className="battle-complete__username">{resultObj.winner.username}</p>
            <div className="battle-complete__stats-cont">
              <div className="battle-complete__stats-cont__stat battle-complete__stats-cont__stat--first">
                <Heart />
                <p className="battle-complete__stats-cont__stat__name">HP</p>
                <p>{isChallengerWinner ? battle?.challenger.hp : battle?.opponent.hp}</p>
              </div>

              {hasBlockTargets && (
                <div className="battle-complete__stats-cont__stat">
                  <BlockTarget />
                  <p className="battle-complete__stats-cont__stat__name">Blocks Won</p>
                  <p>{resultObj.winner.blockTargetWins}</p>
                </div>
              )}

              {hasDays && (
                <div className="battle-complete__stats-cont__stat">
                  <DayTarget />
                  <p className="battle-complete__stats-cont__stat__name">Days Won</p>
                  <p>{isChallengerWinner ? battle?.result.challengerDayTally : battle?.result.opponentDayTally}</p>
                </div>
              )}
            </div>
          </div>
                    
          <div>
            <p className="battle-complete__username">{resultObj.loser.username}</p>
            <div className="battle-complete__stats-cont">
              <div className="battle-complete__stats-cont__stat battle-complete__stats-cont__stat--first">
                <Heart />
                <p className="battle-complete__stats-cont__stat__name">HP</p>
                <p>{isChallengerWinner ? battle?.opponent.hp : battle?.challenger.hp}</p>
              </div>

              {hasBlockTargets && (
                <div className="battle-complete__stats-cont__stat">
                  <BlockTarget />
                  <p className="battle-complete__stats-cont__stat__name">Blocks Won</p>
                  <p>{resultObj.loser.blockTargetWins}</p>
                </div>
              )}

              {hasDays && (
                <div className="battle-complete__stats-cont__stat">
                  <DayTarget />
                  <p className="battle-complete__stats-cont__stat__name">Days Won</p>
                  <p>{isChallengerWinner ? battle?.result.opponentDayTally : battle?.result.challengerDayTally}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

{/* DRAW */}
      {battle?.result.draw && (
        <div className="battle-complete">
          <div>
            <p className="battle-complete__username">You</p>

            <div className="battle-complete__stats-cont">
              <div className="battle-complete__stats-cont__stat battle-complete__stats-cont__stat--first">
                <Heart />
                <p className="battle-complete__stats-cont__stat__name">HP</p>
                <p>{isUserChallenger ? battle?.challenger.hp : battle?.opponent.hp}</p>
              </div>

              {hasBlockTargets && (
                <div className="battle-complete__stats-cont__stat">
                  <BlockTarget />
                  <p className="battle-complete__stats-cont__stat__name">Blocks Won</p>
                  <p>{calcBlockTargetWins(user, otherContestant)}</p>
                </div>
              )}

              {hasDays && (
                <div className="battle-complete__stats-cont__stat">
                  <DayTarget />
                  <p className="battle-complete__stats-cont__stat__name">Days Won</p>
                  <p>{isUserChallenger ? battle?.result.challengerDayTally : battle?.result.opponentDayTally}</p>
                </div>
              )}
            </div>
          </div>
          
          <p className="battle-complete__winner-icon">Draw</p>
          
          <div>
            <p className="battle-complete__username">{otherContestant?.username}</p>
            <div className="battle-complete__stats-cont">
              <div className="battle-complete__stats-cont__stat battle-complete__stats-cont__stat--first">
                <Heart />
                <p className="battle-complete__stats-cont__stat__name">HP</p>
                <p>{isUserChallenger ? battle?.opponent.hp : battle?.challenger.hp}</p>
              </div>

              {hasBlockTargets && (
                <div className="battle-complete__stats-cont__stat">
                  <BlockTarget />
                  <p className="battle-complete__stats-cont__stat__name">Blocks Won</p>
                  <p>{calcBlockTargetWins(otherContestant, user)}</p>
                </div>
              )}

              {hasDays && (
                <div className="battle-complete__stats-cont__stat">
                  <DayTarget />
                  <p className="battle-complete__stats-cont__stat__name">Days Won</p>
                  <p>{isUserChallenger ? battle?.result.opponentDayTally : battle?.result.challengerDayTally}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default BattleCompletePage;