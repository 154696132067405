import React, { useEffect, useState } from 'react';
import './NotificationsModal.scss';
import FormElement from '../../../ui-components/form-element/FormElement';
import Select from '../../../ui-components/select/Select';
import { PersonalUserType } from '../../../types/PersonalUserType';
import { subscribeUserToTopic, unsubscribeUserFromTopic } from '../../../firebase/messaging';
import { useAppSelector } from '../../../redux/hooks';
import { appDataSelector } from '../../../redux/slices/appDataSlice';
import { userSelector } from '../../../redux/slices/userSlice';
import { updatePersonalUserBoolean } from '../../../database/update';
import Button from '../../../ui-components/button/Button';

const NotificationsModal: React.FC<{
  personalUser: PersonalUserType;
  setActiveView: (activeView: "" | "CUSTOMIZE" | "NOTIFICATIONS") => void;
}> = ({
  personalUser,
  setActiveView
}) => {

  const appData = useAppSelector(appDataSelector);
  const user = useAppSelector(userSelector);

  const [notificationsObject, setNotificationsObject] = useState({
    dailyReminder: false,
    targetNotification: false
  })

  useEffect(() => {
    setNotificationsObject({
      dailyReminder: personalUser.topics?.includes('dailyReminder'),
      targetNotification: personalUser.targetNotification
    })
  }, [])

  const onDailyReminderChange = (value: boolean) => {
    if (personalUser) {
      if (value) {
        let topics = [...personalUser.topics];
        if (!personalUser.topics.includes('dailyReminder')) {
          topics = [...topics, 'dailyReminder']
        }
        subscribeUserToTopic(appData.fcmToken, user.id, topics, 'dailyReminder');
        setNotificationsObject({
          ...notificationsObject,
          dailyReminder: true
        })
      }
      else {
        const arr = personalUser.topics.filter(el => el !== 'dailyReminder');
        unsubscribeUserFromTopic(appData.fcmToken, user.id, arr, 'dailyReminder');
        setNotificationsObject({
          ...notificationsObject,
          dailyReminder: false
        })
      }
    }
  }

  const onTargetNotificationChange = (value: boolean) => {
    updatePersonalUserBoolean(user.id, 'targetNotification', value);
    setNotificationsObject({
      ...notificationsObject,
      targetNotification: value
    })
  }

  return (
    <div className="notifications-modal modal">
      <div className="notifications-modal__content">
        <h1>Notifications</h1>

        <FormElement label="Receive a daily notification to update your battles?">
          <Select
            name="dailyReminder"
            variant="DARK"
            options={[
              {title: "Yes", value: true},
              {title: "No", value: false}
            ]}
            defaultValue={
              notificationsObject.dailyReminder
                ? {title: "Yes", value: true}
                : {title: "No", value: false}
            }
            onChange={(event) => onDailyReminderChange(event.value)}
          ></Select>
        </FormElement>

        <FormElement label="Receive a notification when an opponent completes a target?">
          <Select
            name="targetNotification"
            variant="DARK"
            options={[
              {title: "Yes", value: true},
              {title: "No", value: false}
            ]}
            defaultValue={
              notificationsObject.targetNotification
                ? {title: "Yes", value: true}
                : {title: "No", value: false}
            }
            onChange={(event) => onTargetNotificationChange(event.value)}
          ></Select>
        </FormElement>

        <p className="notifications-modal__content__warning">Please be advised that we have experienced issues with notifications when using Chrome on iOS devices. If you are not receiving notifications then please try a different browser.</p>
      </div>

      <div className="notifications-modal__btn">
        <Button
          onClick={() => setActiveView("")}
        >Back</Button>
      </div>

    </div>
  )
}

export default NotificationsModal;