import React, { useEffect, useState } from 'react';
import './CookieBanner.scss';
import { deleteLocalStorage, getConsentFromLocalStorage, removeUserFromIDB, setConsentToLocalStorage } from '../../helpers/storage-helpers';
import Button from '../../ui-components/button/Button';
import { setConsent } from "firebase/analytics";
import { getAnalytics } from 'firebase/analytics';
import { app, auth } from '../..';
import Disclaimer from '../../pages-ancillary/Disclaimer';
import PrivacyPolicy from '../../pages-ancillary/PrivacyPolicy';
import TermsAndConditions from '../../pages-ancillary/TermsAndConditions';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { appDataActions, appDataSelector } from '../../redux/slices/appDataSlice';
import { personalUserSelector } from '../../redux/slices/personalUserSlice';

const CookieBanner = () => {

  const dispatch = useAppDispatch();
  const personalUser = useAppSelector(personalUserSelector);
  const { changeConsentMode } = useAppSelector(appDataSelector);

  const [hasRejectedAll, setHasRejectedAll] = useState(false);
  const [lsConsent, setLsConsent] = useState<undefined | 'NECESSARY' | 'ALL'>(undefined);
  const [isCustomiseMode, setIsCustomiseMode] = useState(false);
  const [analyticStorage, setAnalyticStorage] = useState(true);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  useEffect(() => {
    checkLocalStorage();
  }, [])

  const checkLocalStorage = () => {
    const consent = getConsentFromLocalStorage();
    setLsConsent(consent);
  }

  const onAcceptAllClick = () => {
    setConsent({
      ad_personalization: "granted",
      ad_storage: "granted",
      ad_user_data: "granted",
      analytics_storage: "granted",
      functionality_storage: "granted",
      personalization_storage: "granted",
      security_storage: "granted"
    });
    getAnalytics(app);
    setConsentToLocalStorage('ALL');
    setAnalyticStorage(true)
    checkLocalStorage();
    dispatch(appDataActions.setChangeConsentMode(false));
  }

  const onRejectAllClick = () => {
    setConsent({
      ad_personalization: "denied",
      ad_storage: "denied",
      ad_user_data: "denied",
      analytics_storage: "denied",
      functionality_storage: "denied",
      personalization_storage: "denied",
      security_storage: "denied"
    });
    deleteLocalStorage();
    setHasRejectedAll(true);
    setAnalyticStorage(false)
    checkLocalStorage();
    deleteAnalyticalCookies();
    removeUserFromIDB(personalUser)
    dispatch(appDataActions.setChangeConsentMode(false));
    auth.signOut();
  }

  const deleteAnalyticalCookies = () => {
    document.cookie = "_ga=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }

  const onSavePreferencesClick = () => {
    setIsCustomiseMode(false);
    if (analyticStorage) onAcceptAllClick();
    if (!analyticStorage) {
      setConsent({
        ad_personalization: "denied",
        ad_storage: "denied",
        ad_user_data: "denied",
        analytics_storage: "denied",
        functionality_storage: "granted",
        personalization_storage: "denied",
        security_storage: "granted"
      });
      setConsentToLocalStorage('NECESSARY');
      setAnalyticStorage(false)
      checkLocalStorage();
      deleteAnalyticalCookies();
      dispatch(appDataActions.setChangeConsentMode(false));
    }
  }

  const onAncillaryClose = () => {
    setShowPrivacyPolicy(false);
    setShowDisclaimer(false);
    setShowTermsAndConditions(false);
  }

  if (lsConsent && !changeConsentMode) {
    return <></>;
  }

  return (
    <>
      {showDisclaimer && <Disclaimer onClose={onAncillaryClose}/>}
      {showPrivacyPolicy && <PrivacyPolicy onClose={onAncillaryClose}/>}
      {showTermsAndConditions && <TermsAndConditions onClose={onAncillaryClose}/>}

      <div className="cookie-banner-mask"/>
      <div className="cookie-banner">
        <h3>Consent Preferences</h3>

        {hasRejectedAll && (
          <p className="warning">Necessary storage is required for this app to work. Only necessary storage can be selected within the customise menu.</p>
        )}

        <p>We use cookies and other tracking technologies to improve your experience on our website. We may store and/or access information on a device and process personal data, such as your IP address and browsing data, for personalised advertising and content, advertising and content measurement, audience research and services development. Additionally, we may utilize precise geolocation data and identification through device scanning.</p>
        <p>Please note that your consent will be valid across all our subdomains. You can change or withdraw your consent at any time by updating the relevant parts on the Profile page. We respect your choices and are committed to providing you with a transparent and secure browsing experience.</p>
      
        {isCustomiseMode && (
          <>
            <h4>Necessary Storage</h4>
            <p>Necessary storage is required to enable the basic features of this site, such as providing secure log-in or adjusting your consent preferences. This storage does not store any personally identifiable data.</p>
            <input type="checkbox" checked disabled name="necessaryStorage" />
            <label htmlFor="necessaryStorage">Required</label>

            <div className="cookie-table">
              <p>Table of Necessary Storage</p>
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Purpose</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Local Storage: <strong>pvp-health-local-storage</strong></td>
                    <td>Tracks all the data required for pvp-health to work.</td>
                  </tr>
                  <tr>
                    <td>IndexedDB: <strong>pvp-health</strong></td>
                    <td>Tracks data to determine if app notifications are to be allowed.</td>
                  </tr>
                  <tr>
                    <td>IndexedDB: <strong>firebaseLocalStorageDb</strong></td>
                    <td>Firebase store for firebase login credentials</td>
                  </tr>
                  <tr>
                    <td>IndexedDB: <strong>firebase-messaging-database</strong></td>
                    <td>Firebase store for messaging information - required to handle notifications</td>
                  </tr>
                  <tr>
                    <td>IndexedDB: <strong>firebase-installations-database</strong></td>
                    <td>To allow Firebase to manage the installation of your app</td>
                  </tr>
                  <tr>
                    <td>IndexedDB: <strong>firebase-installations-database</strong></td>
                    <td>Firebase store for heartbeat dates</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h4>Analytic Storage</h4>
            <p>Analytical storage is used to understand how visitors interact with the website. This storage helps provide information on metrics such as the number of visitors, bounce rate, traffic source, etc.</p>
            <input type="checkbox" checked={analyticStorage} name="analyticStorage" onChange={() => setAnalyticStorage(!analyticStorage)}/>
            <label htmlFor="analyticStorage">Allow Analytic Storage</label>

            <div className="cookie-table">
              <p>Table of Analytic Storage</p>
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Purpose</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Cookie: <strong>_ga</strong></td>
                    <td>Used to distinguish users for Google Analytics.</td>
                  </tr>
                  <tr>
                    <td>Cookie: <strong>{"_ga_<container-id>"}</strong></td>
                    <td>Used to persist session state for Google Analytics</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}

        <Button
          variant="primary"
          style={{marginTop: "24px"}}
          onClick={onAcceptAllClick}
        >
          Accept All
        </Button>
      
        {!isCustomiseMode && (
          <Button
            variant="secondary"
            onClick={() => setIsCustomiseMode(true)}
          >
            Customise
          </Button>
        )}
      
        {isCustomiseMode && (
          <Button
            variant="secondary"
            onClick={onSavePreferencesClick}
          >
            Save Preferences
          </Button>
        )}
      
        <Button
          variant="secondary"
          onClick={onRejectAllClick}
        >
          Reject All
        </Button>

        <div className="cookie-banner__links">
          <p
            onClick={() => setShowPrivacyPolicy(true)}
          >
            Privacy Policy
          </p>
          |
          <p
            onClick={(event) => setShowDisclaimer(true)}
          >
            Disclaimer
          </p>
          |
          <p
            onClick={(event) => setShowTermsAndConditions(true)}
          >
            Terms & Conditions
          </p>
        </div>
      </div>
    </>
  )
}

export default CookieBanner;