import React, { useEffect, useState } from 'react';
import './App.scss';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import HomePage from './pages/home-page/HomePage';
import Footer from './components/footer/Footer';
import { appDataActions, appDataSelector } from './redux/slices/appDataSlice';
import Toast from './components/toast/Toast';
import ProfilePage from './pages/profile-page/ProfilePage';
import StartNewBattle from './pages/start-new-battle/StartNewBattle';
import AddContestant from './pages/start-new-battle/add-contestant/AddContestant';
import StartNewBattleWith from './pages/start-new-battle/start-new-battle-with/StartNewBattleWith';
import store from './redux/store';
import { getConsentFromLocalStorage, saveToLocalStorage } from './helpers/storage-helpers';
import BattlesPage from './pages/battles-page/BattlesPage';
import BattleResponsePage from './pages/battle-response-page/BattleResponsePage';
import CredentialsPage from './pages/credentials-page/CredentialsPage';
import { useAuth } from './firebase/Auth';
import { ToastType } from './types/ToastType';
import BattlePage from './pages/battle-page/BattlePage';
import Opponents from './pages/start-new-battle/opponents/Opponents';
import RepeatBattle from './pages/start-new-battle/start-new-battle-with/repeat-battle/RepeatBattle';
import scroll from './ui-components/images/scrollShadows.png';
import Spinner from './ui-components/spinner/Spinner';
import AddPhotoModal from './components/add-photo-modal/AddPhotoModal';
import CookieBanner from './components/cookie-banner/CookieBanner';
import { getAnalytics } from 'firebase/analytics';
import { app } from '.';
import cloud from './ui-components/images/cloud.png';
import useWindowDimensions from './hooks/useWindowDimensions';
import CreateBattlePage from './pages/start-new-battle/create-battle-page/CreateBattlePage';
import { saveMessagingDeviceToken } from './firebase/messaging';
import { personalUserSelector } from './redux/slices/personalUserSlice';
import WelcomePage from './pages/welcome-page/WelcomePage';

const debounce = require('debounce');
store.subscribe(debounce(() => {saveToLocalStorage(store.getState())}, 800));

function App() {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation().pathname;
  const appData = useAppSelector(appDataSelector);
  const width = useWindowDimensions().width;
  
  const { authUser, isLoading } = useAuth();
  const { toasts } = useAppSelector(appDataSelector);
  const personalUser = useAppSelector(personalUserSelector);
  const [showAddPhotoModal, setShowAddPhotoModal] = useState(false);
  const [installEvent, setInstallEvent] = useState<any>();
  const [backgroundClass, setBackgroundClass] = useState('app__background');
  const [maskWidth, setMaskWidth] = useState('');
  const [maskBgColor, setMaskBgColor] = useState('');

  useEffect(() => {
    checkForGaConsent();

    dispatch(appDataActions.setInstallPrompt(false))
    const beforeInstallPromptHandler = (event: any) => {
      event.preventDefault();
      
      if(!window.matchMedia("(display-mode:standalone)").matches) {
        dispatch(appDataActions.setInstallPrompt(true))
        setInstallEvent(event);
      }
    }
    
    window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);
    return () => {
      window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler)
      dispatch(appDataActions.setInstallPrompt(false))
      dispatch(appDataActions.setInstallPWA(false))
      setInstallEvent(false);
    };
  }, [])

  useEffect(() => {
    getMaskStyles();
  }, [width, location])

  useEffect(() => {
    if (appData.installPWA && installEvent) {
      installEvent.prompt();
    }
    dispatch(appDataActions.setInstallPrompt(false))
    setInstallEvent(false);
    dispatch(appDataActions.setInstallPWA(false))
  }, [appData.installPWA])

  useEffect(() => {
    setBackgroundClass(appData.backgroundClass);
  }, [appData.backgroundClass])

  const checkForGaConsent = () => {
    const gaConsent = getConsentFromLocalStorage();
    if (gaConsent && gaConsent === 'ALL') getAnalytics(app);
  }

  useEffect(() => {
    if (personalUser.id) getAndSetFcmToken();
  }, [personalUser.id])
  
  const getAndSetFcmToken = async() => {
    const token = await saveMessagingDeviceToken(personalUser);
    if (token) {
      dispatch(appDataActions.setFcmToken(token));
      handleIndexedDB();
    }
  }

  const handleIndexedDB = () => {
    const indexedDB =
    window.indexedDB
    //@ts-ignore
    || window.mozIndexedDB
    //@ts-ignore
    || window.webkitIndexedDB
    //@ts-ignore
    || window.msIndexedDB
    //@ts-ignore
      || window.shimIndexedDB;

    const request = indexedDB.open('pvp-health', 1);
    request.onerror = function (event) {
      console.error("An error occurred withIndexedDB");
      console.error(event);
    }

    request.onupgradeneeded = function() {
      const db = request.result;

      const store = db.createObjectStore("personalUsers", {keyPath: "id"});

      store.transaction.oncomplete = (event: any) => {
        const personalUsersObjectStore = db
          .transaction("personalUsers", "readwrite")
          .objectStore("personalUsers");
          personalUsersObjectStore.add(personalUser);
      };
    }

    request.onsuccess = function() {
      const idb = request.result;
      if (idb.objectStoreNames.contains("personalUsers")) {
        const transaction = idb.transaction("personalUsers", "readwrite");
        const store = transaction.objectStore("personalUsers");
    
        store.put({...personalUser})
    
        const idQuery = store.get(personalUser.id);
        idQuery.onsuccess = function() {
          // console.log('idQuery', idQuery.result);
        }
      }
    }
  }

  const getMaskStyles = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth > 700) {
      setMaskWidth(((screenWidth - 700) / 2) + 'px');
      setMaskBgColor(location === '/battle' ? '#ede5d2' : 'white')
    }
  }

  return (
    <div className="app">
      
      <div className={backgroundClass} />
      <div
        className="app__mask"
        style={{
          width: maskWidth,
          left: "-5px",
          background: maskBgColor,
          zIndex: 2
        }}
      />
      <div
        className="app__mask"
        style={{
          width: maskWidth,
          right: "-5px",
          background: maskBgColor,
          zIndex: 2
        }}
      />

      {location !== "/battle" && <img src={cloud} alt="cloud" className="app__cloud" />}

      <img
        src={scroll}
        alt="PvP Health"
        className="header"
        onClick={() => navigate('/home')}
      />

      {toasts.length > 0 && (
        <div className="toast-container">
            {toasts.map((toast: ToastType) => {
              return (
                <Toast key={toast.id} id={toast.id}>
                  {toast.content}
                </Toast>
              )
            })}
        </div>
      )}

      {showAddPhotoModal && <AddPhotoModal onClose={() => setShowAddPhotoModal(false)} />}
      
      <Routes>
        {!authUser && <Route path="/" element={<WelcomePage />} />}
        {!isLoading && <Route path="/login" element={<CredentialsPage />} />}
        {isLoading && <Route path="/login" element={<div className="app__loading"><Spinner variant="LIGHT" /></div>} />}

        {authUser && (
          <>
            <Route path="/" element={<HomePage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/start-new-battle" element={<StartNewBattle />} />
            <Route path="/start-new-battle/add-contestant" element={<AddContestant />} />
            <Route path="/start-new-battle/opponents" element={<Opponents />} />
            <Route path="/start-new-battle/start-new-battle-with" element={<StartNewBattleWith />} />
            <Route path="/start-new-battle/repeat-battle" element={<RepeatBattle />} />
            <Route path="/battle" element={<BattlePage />} />
            <Route path="/battles" element={<BattlesPage />} />
            <Route path="/battle-response" element={<BattleResponsePage />} />
            <Route path="/create-battle" element={<CreateBattlePage />} />
          </>
        )}
        <Route path="*" element={<div className="app__loading"><Spinner /></div>} />
      </Routes>

      {authUser && <Footer />}

      <CookieBanner />
    </div>
  );

}

export default App;
