import React, { useEffect, useRef, useState } from 'react';
import './CredentialsPage.scss';
import Input from '../../ui-components/input/Input';
import FormElement from '../../ui-components/form-element/FormElement';
import Button from '../../ui-components/button/Button';
import { useAppDispatch } from '../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../..';
import { displayToast, validateEmail } from '../../helpers/app-helpers';
import { appDataActions } from '../../redux/slices/appDataSlice';
import { userActions } from '../../redux/slices/userSlice';
import { useAuth } from '../../firebase/Auth';
import { battleCreationActions } from '../../redux/slices/battleCreation';
import { createUser } from '../../database/create';
import { checkIfUsernameExists } from '../../database/read';
import { personalUserActions } from '../../redux/slices/personalUserSlice';
import Disclaimer from '../../pages-ancillary/Disclaimer';
import PrivacyPolicy from '../../pages-ancillary/PrivacyPolicy';
import TermsAndConditions from '../../pages-ancillary/TermsAndConditions';
import Guide from '../../pages-ancillary/Guide';

const CredentialsPage = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { authUser, isLoading } = useAuth();

  const logonFormRef = useRef<any>(null);

  const [isSigningUp, setIsSigningUp] = useState(false);
  const [isLoggingOn, setIsLoggingOn] = useState(false);
  const [pageVariant, setPageVariant] = useState<'LOGON' | 'SIGN_UP'>('LOGON');
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showGuide, setShowGuide] = useState(false);

  useEffect(() => {
    dispatch(appDataActions.resetState());
    dispatch(battleCreationActions.resetState());
    dispatch(userActions.resetState());
    dispatch(personalUserActions.resetState());
  }, [])
  
  useEffect(() => {
    if (!isLoading && authUser) navigate('/home');
  }, [authUser, isLoading])

  const onLogonSubmit = async(event: any) => {
    event?.preventDefault();
    setIsLoggingOn(true);
    const email = event.target.email.value.toLowerCase();
    const password = event.target.password.value
    
    if (email && password) {
      signInWithEmailAndPassword(auth, email, password)
      .then(cred => {}, error => {
        console.error(error)
        displayToast(dispatch, error.message)
      })
    }
    
    setIsLoggingOn(false);
  }

  const checkUsernameCriteria = (username: string) => {
    const hasWhiteSpace = /\s/g.test(username);
    const isCorrectLength = username.length >= 3 && username.length <=15; 
    return !hasWhiteSpace && isCorrectLength;
  }

  const onSignUpSubmit = async(event: any) => {
    event?.preventDefault();
    setIsSigningUp(true);    
    const username = event.target.username.value
    const email = event.target.email.value.toLowerCase();
    const password = event.target.password.value;
    const confirm = event.target.confirm.value;

    if (password && password !== confirm) {
      displayToast(dispatch, "Please ensure Password and Confirm Password match.")
      setIsSigningUp(false);
      return;
    }
    
    if (username && email && password && confirm) {
      const isValidUsername = checkUsernameCriteria(username)
      if (!isValidUsername) {
        displayToast(dispatch, 'Invalid Username. Usernames cannot contain white space and must be between 3 and 15 characters');
        setIsSigningUp(false);
        return;
      }
      const usernameExists = await checkIfUsernameExists(username.toLowerCase());

      if (usernameExists) displayToast(dispatch, `${username} is already taken. Please try another username.`);
      else {
        try {
          createUserWithEmailAndPassword(auth, email, password)
          .then(cred => {
            createUser(cred.user.uid, username);
            return {cred: cred, username};
          }, err => {
            console.error(err)
            displayToast(dispatch, err.message)
          }).then((data) => {}, err => {
            console.error(err)
            displayToast(dispatch, err.message)
          })
        } catch (error) {
          console.error(error);
          displayToast(dispatch, 'An error occurred while setting you up, please try again.')
        }
      }
    } else {
      displayToast(dispatch, "Please fill in all four fields.")
    }
    
    setIsSigningUp(false);
  }

  const onAncillaryClose = () => {
    setShowPrivacyPolicy(false);
    setShowDisclaimer(false);
    setShowTermsAndConditions(false);
  }

  const onResetPasswordClick = () => {
    const email = logonFormRef.current.email.value;
    if (email && validateEmail(email)) {
      sendPasswordResetEmail(auth, email)
      .then(() => {
        displayToast(dispatch, "Password reset email sent.")
      })
      .catch((error) => {
        displayToast(dispatch, error.message);
      });
    } else {
      displayToast(dispatch, "Please enter a valid email address.")
    }
  }

  return (
    <div className="auth-page">
      <h1>PvP Health</h1>

      {pageVariant === "LOGON" && (
        <div className="auth-page__credentials-container">
          <form onSubmit={onLogonSubmit} ref={logonFormRef}>
            <FormElement label="Email">
              <Input name="email" type="email" variant="DARK"></Input>
            </FormElement>

            <FormElement label="Password">
              <Input name="password" type="password" variant="DARK"></Input>
            </FormElement>

            <Button
              type="submit"
              isSpinning={isLoggingOn}
            >Sign In</Button>
            <p className="auth-page__credentials-container__no-account">
              Don't have an account yet? Sign up <span onClick={() => setPageVariant("SIGN_UP")}>here</span>
            </p>

            <p className="auth-page__credentials-container__no-account">
              Forgot your password? Reset it <span onClick={onResetPasswordClick}>here</span>
            </p>
          </form>

          <p className="auth-page__guide">Don't know what PvP Health is all about? View the guide <span onClick={() => setShowGuide(true)}>here</span>.</p>

        </div>
      )}

      {pageVariant === "SIGN_UP" && (
        <div className="auth-page__credentials-container">
          <form onSubmit={onSignUpSubmit}>

            <div className="auth-page__credentials-container__log-in-info">
              <h2>Sign Up Information</h2>

              <FormElement label="Email">
                <Input name="email" variant="DARK"></Input>
              </FormElement>

              <FormElement label="Password">
                <Input name="password" type="password" variant="DARK"></Input>
              </FormElement>

              <FormElement label="Confirm Password">
                <Input name="confirm" type="password" variant="DARK"></Input>
              </FormElement>
            </div>

            <div className="auth-page__credentials-container__log-in-info">
              <h2>Profile Information</h2>

              <FormElement label="Choose a username">
                <Input name="username" variant="DARK"></Input>
              </FormElement>
            </div>

            <Button
              type="submit"
              isSpinning={isSigningUp}
            >Sign Up</Button>
            <p className="auth-page__credentials-container__no-account">
              Already have an account? Sign in <span onClick={() => setPageVariant("LOGON")}>here</span>
            </p>
          </form>
          <p className="auth-page__guide">Don't know what PvP Health is all about? View the guide <span onClick={() => setShowGuide(true)}>here</span>.</p>
        </div>
      )}


      {showDisclaimer && <Disclaimer onClose={onAncillaryClose}/>}
      {showPrivacyPolicy && <PrivacyPolicy onClose={onAncillaryClose}/>}
      {showTermsAndConditions && <TermsAndConditions onClose={onAncillaryClose}/>}
      {showGuide && <Guide onClose={() => setShowGuide(false)}/>}

      <div className="auth-page__cookie-banner cookie-banner__links">
        <p
          onClick={() => setShowPrivacyPolicy(true)}
        >
          Privacy Policy
        </p>
        |
        <p
          onClick={(event) => setShowDisclaimer(true)}
        >
          Disclaimer
        </p>
        |
        <p
          onClick={(event) => setShowTermsAndConditions(true)}
        >
          Terms & Conditions
        </p>
      </div>

    </div>
  )
}

export default CredentialsPage;