import { configureStore } from '@reduxjs/toolkit';
import { loadFromLocalStorage } from '../helpers/storage-helpers';
import { userSlice } from './slices/userSlice';
import { appDataSlice } from './slices/appDataSlice';
import { battleCreationSlice } from './slices/battleCreation';
import { battlesSlice } from './slices/battlesSlice';
import { personalUserSlice } from './slices/personalUserSlice';

export const store = configureStore({
  reducer: {
    // @ts-ignore
    user: userSlice.reducer,
    appData: appDataSlice.reducer,
    battleCreation: battleCreationSlice.reducer,
    battles: battlesSlice.reducer,
    personalUser: personalUserSlice.reducer
  },
  preloadedState: loadFromLocalStorage()
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
