import React, { useEffect, useState } from 'react';
import './HomePage.scss';
import NavigationButton from '../../ui-components/navigation-button/NavigationButton';
import { useNavigate } from 'react-router-dom';
import packageJS from '../../../package.json';
import { useAppSelector } from '../../redux/hooks';
import { personalUserSelector } from '../../redux/slices/personalUserSlice';
import Card from '../../ui-components/card/Card';
import SpeechCard from '../../ui-components/speech-card/SpeechCard';
import Guide from '../../pages-ancillary/Guide';
import { battlesSelector } from '../../redux/slices/battlesSlice';
import { findActiveBattles } from './HomePage-Utils';
import { BattleType } from '../../types/BattleType';
import Dashboard from './dashboard/Dashboard';

const HomePage = () => {

  const navigate = useNavigate();
  const personalUser = useAppSelector(personalUserSelector);
  const battles = useAppSelector(battlesSelector);

  const [showDefaultBtn, setShowDefaultBtn] = useState(true);
  const [showGuide, setShowGuide] = useState(false);
  const [activeBattles, setActiveBattles] = useState<BattleType[]>([]);

  useEffect(() => {
    setActiveBattles(findActiveBattles(battles));
  }, [battles])

  return (
    <div className="home-page">
      <p className="home-page__version">{packageJS.version}</p>

      {!personalUser.hideWelcomeMessage && (
        <SpeechCard
          firstLine="&quot;Welcome!"
          originPage="HOME"
        >
          <p>To get started, here are a few ideas:</p>
          <ul>
            <li>Head to the profile section to customise your avatar.</li>
            <li>Read the Guide <span onClick={() => setShowGuide(true)}>here</span>.</li>
            <li>Or jump right in and start a new battle from the menu below.</li>
          </ul>
          <p>Whatever you choose, have fun! I truly hope PvP Health helps you on your way to health and fitness.</p>
          <p>Cheers, Eon McC&quot;</p>
        </SpeechCard>
      )}

      {showGuide && <Guide onClose={() => setShowGuide(false)}/>}

      {activeBattles.length < 1 && (
        <Card
          backUrl=""
          title="Please Select"
          center={personalUser.hideWelcomeMessage}
          onMouseOut={() => setShowDefaultBtn(true)}
        >
          <NavigationButton
            isDefault={showDefaultBtn}
            onMouseOver={() => setShowDefaultBtn(false)}
            onClick={() => navigate('/battles')}
          >
            Battles
          </NavigationButton>

          <NavigationButton
            onMouseOver={() => setShowDefaultBtn(false)}
            onClick={() => navigate('/start-new-battle')}
          >
            Start New Battle
          </NavigationButton>
        </Card>
      )}

      {activeBattles.length > 0 && <Dashboard battles={battles}/>}
    </div>
  )
}

export default HomePage;