import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { BattleCreationType } from '../../types/BattleCreationType';

const initialState: BattleCreationType = {
  id: '',
  title: '',
  startDate: '',
  numDays: 1,
  description: '',
  prize: '',
  teamBattle: false,
  challenger: {
    id: '',
    username: '',
    hp: 1,
    zeroHpDate: null
  },
  opponent: {
    id: '',
    username: '',
    hp: 1,
    zeroHpDate: null
  }
}

export const battleCreationSlice = createSlice({
  name: 'battleCreation',
  initialState,
  reducers: {
    addOpponent: (state, action) => {
      return {
        ...state,
        opponent: action.payload
      };
    },
    updateBattleField: (state, action) => {
      return {
        ...state,
        [action.payload.field]: action.payload.value
      };
    },
    setBattle: (state, action) => {
      return {...action.payload};
    },
    setBattleWithoutOpponent: (state, action) => {
      return {
        ...action.payload,
        opponent: state.opponent
      };
    },
    resetState: (state) => {
      return {...initialState};
    }
  },
})

export const battleCreationSelector = (state: RootState) => state.battleCreation;
export const battleCreationActions = battleCreationSlice.actions;