import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { BattleType } from '../../types/BattleType';

const initialState: BattleType[] = []

export const battlesSlice = createSlice({
  name: 'battles',
  initialState,
  reducers: {
    setBattles: (state, action) => {
      return [...action.payload];
    },
    updateBattlesField: (state, action) => {
      return {
        ...state,
        [action.payload.field]: action.payload.value
      };
    },
    resetState: (state) => {
      return {...initialState};
    }
  },
})

export const battlesSelector = (state: RootState) => state.battles;
export const battlesActions = battlesSlice.actions;