import { TargetFormat } from "../enums/enums";

export const enum TargetFrequency {
  DAILY = "DAILY",
  BLOCK = "BLOCK"
}
export interface TargetType {
  id: string;
  title: string;
  description: string;
  frequency: TargetFrequency;
  format: TargetFormat;
  dayKey: string;
  targetKey: string;
  isPhotoRequired: boolean;
  [key: string]: any;
}