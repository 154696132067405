import React, { useEffect, useState } from 'react';
import './HealthBar.scss';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { displayToast } from '../../helpers/app-helpers';
import { userSelector } from '../../redux/slices/userSlice';
import { BattleType } from '../../types/BattleType';
import { BattleStatus } from '../../enums/enums';

const HealthBar: React.FC<{
  username: string;
  maxHp: number;
  currentHp: number;
  showPulse?: boolean;
  showExclamation?: boolean;
  battle: BattleType | undefined;
}> = ({
  username,
  maxHp,
  currentHp,
  showPulse = true,
  showExclamation = true,
  battle
}) => {

  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelector);

  const WIDTH_PX = 140;
  const intervalPx = WIDTH_PX / maxHp;
  const [borderRightPx, setBorderRightPx] = useState(maxHp);
  const [classList, setClassList] = useState('health-bar');
  const [shake, setShake] = useState(false);
  const [previousHp, setPreviousHp] = useState(currentHp);
  const [showMinus, setShowMinus] = useState<any>(false);
  const [showPlus, setShowPlus] = useState<any>(false);
  const [showHealthWarning, setShowHealthWarning] = useState(false);
  const [plusMinus, setPlusMinus] = useState(0);

  useEffect(() => {
    const borderRight = WIDTH_PX - (currentHp * intervalPx) + 2;
    setBorderRightPx(borderRight < 140 ? borderRight : 140);
    if (currentHp < previousHp) setShowMinus(previousHp - currentHp);
    if (currentHp > previousHp) setShowPlus(currentHp - previousHp);
    setPreviousHp(currentHp);
    setShake(true);
    if (currentHp < 1) {
      setPlusMinus(currentHp);
      setShowHealthWarning(true);
      if (battle && battle.status === BattleStatus.ACTIVE) {
        if (user.username === username) displayToast(dispatch, `Your health is dangerously low. If your health remains this low overnight then the battle is over and you will lose by KO!`, 5000)
        else displayToast(dispatch, `Opponent's health is dangerously low. If their health remains this low overnight then the battle is over and you will win by KO!`, 5000)
      }
    } else {
      setShowHealthWarning(false);
      if (currentHp > maxHp) setPlusMinus(currentHp - maxHp)
      else setPlusMinus(0)
    }    

    setTimeout(() => {
      setShake(false);
      setShowMinus(false);
      setShowPlus(false);
    }, 1000);
  }, [currentHp])

  useEffect(() => {
    getAndSetClass();
  }, [shake])

  const getAndSetClass = () => {
    let classList = 'health-bar ';
    if (shake) classList = classList.concat('health-bar--shake ');
    if (showHealthWarning && showPulse) classList = classList.concat('health-bar--pulse');
    setClassList(classList);
  }

  return (
    <>
      <div
        className={classList}
        style={{
          borderRight: `${borderRightPx}px solid var(--dark)`,
          position: "relative"
        }}
        >
        {plusMinus > 0 && <h2 className="health-bar__plusMinus health-bar__plusMinus--plus">+{plusMinus}</h2>}
        {plusMinus < 0 && <h2 className="health-bar__plusMinus health-bar__plusMinus--minus">{plusMinus}</h2>}
        {showMinus && <h1 className="health-bar__event health-bar__event--minus-1">-{showMinus}</h1>}
        {showPlus && <h1 className="health-bar__event health-bar__event--plus-1">+{showPlus}</h1>}
      </div>

      {showHealthWarning && showExclamation && <h1 className="health-bar__warning">!</h1>}
    </>
  )
}

export default HealthBar;