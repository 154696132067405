import React, { useEffect, useRef, useState } from 'react';
import './NumberInputModal.scss';
import FormElement from '../../../../../../ui-components/form-element/FormElement';
import { TargetType } from '../../../../../../types/TargetType';
import Input from '../../../../../../ui-components/input/Input';
import Button from '../../../../../../ui-components/button/Button';
import { displayToast } from '../../../../../../helpers/app-helpers';
import { useAppDispatch } from '../../../../../../redux/hooks';
import PhotoInputModal from '../photo-input-modal/PhotoInputModal';
import { BattleType } from '../../../../../../types/BattleType';
import { uploadPhoto } from '../Utils-Checkbox';
import { v4 as uuid } from 'uuid';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../../../../..';

const NumberInputModal: React.FC<{
  target: TargetType;
  currentValue: string;
  hideNumber: boolean;
  battle: BattleType;
  photoPath: string;
  // opponentColor: string | undefined;
  isUser: boolean;
  onCancel: () => void;
  onClear: (event: string) => void;
  onConfirm: (input: string, hideNumberCheck: boolean, photo: string) => void;
}> = ({
  target,
  currentValue,
  hideNumber,
  battle,
  photoPath,
  // opponentColor,
  isUser,
  onCancel,
  onClear,
  onConfirm
}) => {

  const dispatch = useAppDispatch();
  const inputModalRef = useRef<any>(null);
  const numberInputRef = useRef<any>();
  const [value, setValue] = useState('');
  const [showPhotoInputModal, setShowPhotoInputModal] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const [tempPhotoURL, setTempPhotoURL] = useState<string>('');
  const [existingPhotoUrl, setExistingPhotoUrl] = useState<string>('');
  const [hideNumberCheck, setHideNumberCheck] = useState<any>();

  useEffect(() => {
    setHideNumberCheck(hideNumber ? hideNumber : false);
  }, [])

  useEffect(() => {
    if (photoPath) getPhotoUrl();
    setValue(currentValue);
    setTimeout(() => numberInputRef.current?.select(), 200)
  }, [photoPath])

  const getPhotoUrl = async() => {
    const pathRef = await ref(storage, photoPath);
    try {
      setExistingPhotoUrl(await getDownloadURL(pathRef));
    } catch (error) {
      console.error(error);
      displayToast(dispatch, 'Photo cannot be downloaded.')
    }
  }

  const onInputConfirm = async(value: string) => {
    if (!+value) {
      displayToast(dispatch, 'Please enter a valid number.');
      return;
    }

    if (!target.isPhotoRequired) {
      onConfirm(value, hideNumberCheck, '');
      return;
    }

    if (target.isPhotoRequired && file) {
      if (file) {
        const uploadedPhoto = await uploadPhoto(
          file,
          uuid,
          dispatch,
          battle
        )
        if (uploadedPhoto) onConfirm(value, hideNumberCheck, uploadedPhoto.metadata.fullPath);
      }
      return;
    }

    if (target.isPhotoRequired && !file) {
      if (inputModalRef.current) {
        inputModalRef.current.style.opacity = '0'
      }
      setShowPhotoInputModal(true);
      return;
    }
  }

  const onPhotoReturn = (file: File, tempPhotoURL: string) => {
    setTempPhotoURL(tempPhotoURL);
    inputModalRef.current.style.opacity = '1';
    setFile(file);
    setShowPhotoInputModal(false);
  }

  const checkChange = (event: any) => {
    setHideNumberCheck(!hideNumberCheck);
  }

  return (
    <>
      <div className="full-screen-mask" onClick={onCancel}/>

      <div ref={inputModalRef} className="number-input-modal">
        <div className="number-input-modal__content">
          {isUser && (
            <FormElement label={target.title}>
              <Input
                name="targetInput"
                passRef={numberInputRef}
                placeholder={currentValue ? currentValue : "Enter number ..."}
                type="number"
                value={value}
                variant="DARK"
                onChange={(event) => setValue(event.target.value)}
              />
            </FormElement>
          )}

          {isUser && (
            <div className="number-input-modal__content__hide">
              <input
                type="checkbox"
                onChange={checkChange}
                checked={hideNumberCheck}
                name="numberHide"
              />
              <label htmlFor="numberHide">Hide number value from opponent</label>
            </div>
          )}

          {(tempPhotoURL || existingPhotoUrl) && <img src={tempPhotoURL ? tempPhotoURL : existingPhotoUrl} alt="Target" />}
          {file && <p className="number-input-modal__content__with-text">With photo <span>{file.name}</span></p>}
        </div>


        {isUser && (
          <div className="number-input-modal__btns">
            <Button
              variant="text"
              onClick={onCancel}
            >
              Cancel
            </Button>

            {existingPhotoUrl && (
              <Button
                variant="text"
                style={{color: "var(--red)"}}
                onClick={onClear}
              >
                Delete
              </Button>
            )}

            <Button
              variant="text"
              onClick={() => onInputConfirm(value)}
            >
              {target.isPhotoRequired && !file ? "Continue" : "Confirm"}
            </Button>
          </div>
        )}
      </div>

      {showPhotoInputModal && (
        <PhotoInputModal
          battle={battle}
          confirmEvent={target.isPhotoRequired ? "RETURN" : "UPLOAD"}
          onClose={() => setShowPhotoInputModal(false)}
          onUpload={() => null}
          onReturn={(file, tempPhotoURL) => onPhotoReturn(file, tempPhotoURL)}
        />
      )}
    </>
  )
}

export default NumberInputModal;