import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { PersonalUserType } from '../../types/PersonalUserType';

const initialState: PersonalUserType = {
  id: '',
  topics: [],
  fcmToken: false,
  targetNotification: false,
  hideWelcomeMessage: true
}

export const personalUserSlice = createSlice({
  name: 'personalUser',
  initialState,
  reducers: {
    setPersonalUser: (state, action) => {
      return {...action.payload};
    },
    updatePersonalUser: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    hideWelcomeMessage: (state) => {
      return {
        ...state,
        hideWelcomeMessage: true
      };
    },
    resetState: (state) => {
      return {...initialState};
    }
  },
})

export const personalUserSelector = (state: RootState) => state.personalUser;
export const personalUserActions = personalUserSlice.actions;