import React from 'react';
import './WelcomePage.scss';
import SpeechCard from '../../ui-components/speech-card/SpeechCard';
import Button from '../../ui-components/button/Button';
import { useNavigate } from 'react-router-dom';
import battleCards from '../../ui-components/images/battleCards.png';
import customise from '../../ui-components/images/customise.png';
import yourWay from '../../ui-components/images/yourWay.png';
import Card from '../../ui-components/card/Card';

const WelcomePage = () => {

  const navigate = useNavigate();


  return (
    <div className="welcome-page">
      <SpeechCard
        firstLine="&quot;Welcome!"
        originPage="WELCOME"
        style={{marginTop: "40px"}}
      >
        <p>This is PvP Health - the place to battle your way to health.</p>
        <p>PvP Health aims to harness your competitive spirit and apply it to keeping fit and healthy.</p>
        <p>Set up battles with friends where you aim to outperform them in targets and challenges.</p>
        <p>See more below, or register now to get started!</p>
      </SpeechCard>

      <section className="welcome-page__see-more">
        <Card
          backUrl=""
          title="Why PvP Health?"
          variant="LIGHT"
          className="dashboard"
        >
          <div className="welcome-page__see-more__block">
            <h4>Health Gamified</h4>
            <img src={battleCards} alt="Fun UI" />
            <p>Enjoy battling with your friends in a fun app that combines elements from old games and an easy to use interface</p>
          </div>

          <div className="welcome-page__see-more__block">
            <h4>Your Way</h4>
            <p>Set up battles in a way that suits you by deciding what each target should be and how it should be recorded</p>
            <img src={yourWay} alt="Create battles your way" />
          </div>

          <div className="welcome-page__see-more__block">
            <h4>Customizable Avatars</h4>
            <img src={customise} alt="Customisable Avatars" />
            <p>Customize an avatar to represent you in your battles</p>
          </div>

        </Card>
      </section>

      <Button
        style={{
          marginLeft: "50%",
          transform: "translateX(-50%)"
        }}
        onClick={() => navigate("/login")}
      >
        Register/Login
      </Button>

    </div>
  )
}

export default WelcomePage;