import { db } from "..";
import { collection, doc, endAt, getDoc, getDocs, limit, orderBy, query, startAt } from "firebase/firestore";
import { UserType } from "../types/UserType";

export const getUsersByUsername = async(username: string) => {
  const start = username.toLowerCase();
  const end = start + "~";
  const q = query(collection(db, "users"),
    orderBy("lowerUsername"),
    startAt(start),
    endAt(end),
    limit(5)
  );
  const querySnapshot = await getDocs(q);
  
  let users: any[] = [];
  querySnapshot.forEach((doc) => {
    users.push(doc.data());
  });

  return users;
}

export const checkIfUsernameExists = async(username: string) => {
  let exists = true;
  const ref = doc(db, `usernames/${username}`);
  
  await getDoc(ref).then((data) => {
    if (!data.exists()) exists = false;
  })
  .catch(err => console.error(err));
  return exists;
}

export const getPersonalUser = async(userId: string, onError: any) => {
  let user;
  try {
    const ref = doc(db, "users", userId, "personalUsers", userId);
    await getDoc(ref).then((data) => {
      user = data.data();
    });
  } catch (error) {
    console.error(error);
    return onError();
  }

  return user;
}

export const getUser = async(userId: string) => {
  let user;
  try {
    const ref = doc(db, "users", userId);
    await getDoc(ref).then((data) => {
      user = data.data();
    });
  } catch (error) {
    console.error(error);
  }

  return user;
}

export const getStoreFcmToken = async(userId: string) => {
  let token;
  try {
    const ref = doc(db, "fcmTokens", userId);
    await getDoc(ref).then((data) => {
      token = data.data();
    });
  } catch (error) {
    console.error(error);
  }

  return token;
}