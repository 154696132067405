import React, { useEffect, useState } from 'react';
import './DayView.scss';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { userSelector } from '../../../redux/slices/userSlice';
import { TargetType } from '../../../types/TargetType';
import { updateTarget } from '../../../database/update';
import { displayToast, getOtherUserId } from '../../../helpers/app-helpers';
import { appDataActions, appDataSelector } from '../../../redux/slices/appDataSlice';
import { BattleType } from '../../../types/BattleType';
import { DayType } from '../../../types/DayType';
import { ContestantType } from '../../../types/ContestantType';
import BattleCard from './battle-card/BattleCard';
import { convertBooleanToString } from '../../../database/database-utils';
import Spinner from '../../../ui-components/spinner/Spinner';
import { UserType } from '../../../types/UserType';

const DayView: React.FC<{
  otherContestant: ContestantType;
  otherUser: UserType;
  battle: BattleType;
  setActiveView: (event: 'CALENDER' | 'DAY') => void;
}> = ({
  otherContestant,
  otherUser,
  battle,
  setActiveView
}) => {

  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelector);
  const appData = useAppSelector(appDataSelector);

  const [targets, setTargets] = useState<TargetType[]>([]);
  const [day, setDay] = useState<any>();
  const [isUpdatingTarget, setIsUpdatingTarget] = useState(false);

  useEffect(() => {
    dispatch(appDataActions.setBackgroundClass('app__background app__background--table'));
    return () => {dispatch(appDataActions.setBackgroundClass('app__background'))};
  }, [])

  useEffect(() => {
    const day = battle.days.filter((day: DayType) => day.dayKey === appData.currentDayKey)[0];
    setDay(day);
  }, [battle])

  useEffect(() => {
    if (day) {
      sortAndSetTargets();
    }
  }, [day])

  const sortAndSetTargets = () => {
    const targets: TargetType[] = [...day.targets];
    const sortedTargets = sortTargets(targets);

    setTargets(sortedTargets);
  }

  const sortTargets = (targets: TargetType[]) => {
    const sortedTargets = targets.sort((a: any, b: any) => {
      let aNumber = a.targetKey.match(/\d/g);
      aNumber = +aNumber.join("");
      let bNumber = b.targetKey.match(/\d/g);
      bNumber = +bNumber.join("");
      return bNumber - aNumber;
    });
    return sortedTargets;
  }

  const onChange = async(input: boolean, target: TargetType, hideNumberCheck: boolean, photo: string = '') => {
    let stillUpdating = true;
    setTimeout(() => {
      if (stillUpdating) setIsUpdatingTarget(true);
    }, 500);
    const success = await updateTarget(battle.id, day.dayKey, target.targetKey, convertBooleanToString(input), user.id, otherUser.id, hideNumberCheck, battle, target, photo);
    if (!success) displayToast(dispatch, 'Failed to update target. Please try again.')
    stillUpdating = false;
    setIsUpdatingTarget(false);
  }

  return (
    <div className="day-view">
      {isUpdatingTarget && (
        <>
          <div className="full-screen-mask" />
          <div className="day-view__spinner">
            <Spinner />
          </div>
        </>
      )}

      <div className="day-view__back-card" onClick={() => setActiveView("DAY")}>Back</div>

      <BattleCard
        contestant={otherContestant}
        cardUser={otherUser}
        targets={targets}
        day={day}
        battle={battle}
        onChange={onChange}
      />
      
      <BattleCard
        contestant={battle.challenger.id === user.id ? battle.challenger : battle.opponent}
        cardUser={user}
        targets={targets}
        day={day}
        battle={battle}
        onChange={onChange}
      />
      
    </div>
  )
}

export default DayView;