import React, { useState, useRef } from 'react';
import './AddContestant.scss';
import FormElement from '../../../ui-components/form-element/FormElement';
import Input from '../../../ui-components/input/Input';
import Card from '../../../ui-components/card/Card';
import Button from '../../../ui-components/button/Button';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { battleCreationActions } from '../../../redux/slices/battleCreation';
import { UserType } from '../../../types/UserType';
import { userSelector } from '../../../redux/slices/userSlice';
import { displayToast } from '../../../helpers/app-helpers';
import { useNavigate } from 'react-router-dom';
import { getUsersByUsername } from '../../../database/read';
import { ReactComponent as ChevronRight  } from '../../../ui-components/icon/svgs/chevronRight.svg';

const AddContestant = () => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const formRef = useRef<any>();
  const userId = useAppSelector(userSelector).id

  const [opponents, setOpponents] = useState<UserType[]>([]);

  const onSubmit = async(event: any) => {
    event.preventDefault();
    const opponents = await getUsersByUsername(event.target.username.value);

    if (opponents.length < 1) displayToast(dispatch, 'No opponents found that match this username.');
    else setOpponents(opponents);
  }

  const onClick = (opponent: UserType) => {
    dispatch(battleCreationActions.addOpponent({
      id: opponent.id,
      username: opponent.username
    }));
    navigate('/start-new-battle/start-new-battle-with');
  }

  return (
      <Card
        title="Start New Battle"
        backUrl="/start-new-battle"
        className="add-contestant"
      >
        <h4>Add a New Opponent</h4>
        <form onSubmit={onSubmit} ref={formRef}>
          <FormElement
            label="Username Search"
          >
            <Input
              name="username"
              variant="DARK"
            />
          </FormElement>

          <Button type="submit">Search</Button>
        </form>

        {opponents.length > 0 && (
          <div className="add-contestant__opponent-list">
            {opponents.map((opponent) => {
              if (opponent.id !== userId) {
                return (
                  <div
                    key={opponent.id}
                    onClick={() => onClick(opponent)}
                  >
                    {opponent.username}
                    <ChevronRight />
                  </div>
                )
              }
            })}
          </div>
        )}
      </Card>
  )
}

export default AddContestant;