import React, { ReactNode, useEffect, useState } from 'react';
import './Select.scss';
import Option, { SelectOption } from './option/Option';

interface Props {
  name: string;
  disabled?: boolean;
  variant?: 'LIGHT' | 'DARK';
  style?: React.CSSProperties;
  className?: string;
  id?: string;
  options: {title: string; value: string | number | boolean, disabled?: boolean}[] | undefined;
  defaultValue?: SelectOption;
  isSearchable?: boolean;
  placeholder?: string;
  onChange?: (event: any) => void;
  children?: ReactNode;
}

const Select: React.FC<Props> = ({
  name,
  disabled,
  variant = 'LIGHT',
  style,
  className,
  id,
  options,
  defaultValue,
  isSearchable,
  placeholder,
  onChange
}) => {

  const [isFocused, setIsFocused] = useState(false);
  const [searchString, setSearchString] = useState(defaultValue?.title ? defaultValue?.title : '');
  const [filteredOptions, setFilteredOptions] = useState(options);

  const getClasses = () => {
    let classes = `${className} select `;

    if (variant === 'DARK') classes = classes.concat('select--dark ');

    if (disabled) {
      classes = classes.concat('select--disabled ');
    }

    return classes;
  }

  const selectClasses = getClasses();

  const getDropdownClass = () => {
    let classes = 'select__dropdown ';

    if (!isFocused) classes.concat('select__dropdown--hidden ');

    if (variant === 'DARK') classes = classes.concat('select__dropdown--dark ');

    return classes;
  }

  const dropDownClass = getDropdownClass();

  const handleSelect = (event: any) => {
    setSearchString(event.title);
    onChange?.(event);
    setIsFocused(false);
  }

  const onSearch = (event: any) => {
    setSearchString(event.target.value)
    setFilteredOptions(options?.filter((option) => {
      return option.title.toLowerCase().includes(event.target.value.toLowerCase());
    }))
  }

  useEffect(() => {
    setFilteredOptions(options);
  }, [options])

  useEffect(() => {
    setSearchString(defaultValue?.title ? defaultValue?.title : '')
  }, [defaultValue])

  return (
    <>
      <input
        name={name}
        className={selectClasses}
        id={id}
        style={style}
        disabled={disabled}
        value={searchString}
        onFocus={() => setIsFocused(true)}
        readOnly={!isSearchable}
        autoComplete="off"
        onChange={onSearch}
        placeholder={placeholder}
      />
      {isFocused && <div className="full-screen-mask" onClick={() => setIsFocused(false)} />}
      {isFocused && (
        <div className={dropDownClass}>
          {filteredOptions?.map(opt => {
            return (
              <Option
                value={opt}
                key={opt.title}
                variant={variant}
                onSelect={handleSelect}
                disabled={opt.disabled}
              >
                {opt.title}
              </Option>
            )
          })}
        </div>
      )}
    </>
  )
}

export default Select;