import React, { useEffect, useState } from 'react';
import './Dashboard.scss';
import { BattleType } from '../../../types/BattleType';
import Card from '../../../ui-components/card/Card';
import { findTargets, getPendingBattles } from './Dashboard-Utils';
import Checkbox from '../../battle-page/day-view/battle-card/checkbox/Checkbox';
import { TargetType } from '../../../types/TargetType';
import { ReactComponent as Eye } from '../../../ui-components/icon/svgs/eye.svg'
import { ReactComponent as Plus } from '../../../ui-components/icon/svgs/plus.svg';
import { ReactComponent as ChevronUp } from '../../../ui-components/icon/svgs/chevronUp.svg';
import { ReactComponent as DayTarget } from '../../../ui-components/icon/svgs/dayTarget.svg';
import { ReactComponent as BlockTarget } from '../../../ui-components/icon/svgs/blockTarget.svg';
import { ReactComponent as CheckboxTarget } from '../../../ui-components/icon/svgs/checkbox.svg';
import { ReactComponent as Hash } from '../../../ui-components/icon/svgs/hash.svg';
import { ReactComponent as Camera } from '../../../ui-components/icon/svgs/camera.svg';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { userSelector } from '../../../redux/slices/userSlice';
import Spinner from '../../../ui-components/spinner/Spinner';
import { updateBlockCheckboxTarget, updateBlockNumberTarget, updateTarget } from '../../../database/update';
import { ContestantTargetType } from '../../../types/ContestantTargetType';
import { convertBooleanToString } from '../../../database/database-utils';
import { displayToast, getOtherContestant } from '../../../helpers/app-helpers';
import { useNavigate } from 'react-router-dom';
import { appDataActions } from '../../../redux/slices/appDataSlice';
import { TargetFormat } from '../../../enums/enums';
import dayjs from 'dayjs';
import Input from '../../../ui-components/input/Input';
import { findActiveBattles } from '../HomePage-Utils';

const Dashboard: React.FC<{
  battles: BattleType[]
}> = ({
  battles
}) => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(userSelector);

  const [dailyTargets, setDailyTargets] = useState<any>([]);
  const [blockTargets, setBlockTargets] = useState<any>([]);
  const [isUpdatingTarget, setIsUpdatingTarget] = useState(false);
  const [blockToShowId, setBlockToShowId] = useState<string>('');
  const [pendingBattles, setPendingBattles] = useState<BattleType[]>([]);
  const [activeBattles, setActiveBattles] = useState<BattleType[]>([]);

  useEffect(() => {
    setActiveBattles(findActiveBattles(battles))
    setPendingBattles(getPendingBattles(battles));
  }, [battles])

  useEffect(() => {
    const allTargets = findTargets(activeBattles);
    const dailyTargets = allTargets.targets.sort((a: TargetType, b: TargetType) => a.id.localeCompare(b.id));
    const blockTargets = allTargets.blockTargets.sort((a: TargetType, b: TargetType) => a.id.localeCompare(b.id));
    setDailyTargets(dailyTargets);
    setBlockTargets(blockTargets);
  }, [activeBattles])

  const onChange = async(input:any, target: TargetType, hideNumberCheck: boolean, photo: string, battle: BattleType, dayKey: string) => {
    const otherUserId = battle.userIds.filter((el: string) => el !== user.id)[0];
    let stillUpdating = true;
    setTimeout(() => {
      if (stillUpdating) setIsUpdatingTarget(true);
    }, 500);
    const success = await updateTarget(battle.id, dayKey, target.targetKey, convertBooleanToString(input), user.id, otherUserId, hideNumberCheck, battle, target, photo);
    if (!success) displayToast(dispatch, 'Failed to update target. Please try again.')
    stillUpdating = false;
    setIsUpdatingTarget(false);
  }

  const onBlockTargetChange = async(input:any, target: TargetType, hideNumberCheck: boolean, photo: string, battle: BattleType) => {
    let stillUpdating = true;
    setTimeout(() => {
      if (stillUpdating) setIsUpdatingTarget(true);
    }, 500);
    const success = await updateBlockCheckboxTarget(convertBooleanToString(input), battle, target, user, hideNumberCheck, photo);
    if (!success) displayToast(dispatch, 'Failed to update target. Please try again.')
    stillUpdating = false;
    setIsUpdatingTarget(false);
  }

  const onEyeClick = (battle: BattleType) => {
    dispatch(appDataActions.setCurrentBattleId(battle.id));
    navigate('/battle');
  }

  const onAddClick = async(event: any, target: TargetType, battle: BattleType) => {
    event.preventDefault();
    let stillUpdating = true;
    setTimeout(() => {
      if (stillUpdating) setIsUpdatingTarget(true);
    }, 500);
    const success = await updateBlockNumberTarget(event.target[0].value, battle, target, user);
    if (success) displayToast(dispatch, 'Entry recorded. Well done!')
    if (!success) displayToast(dispatch, 'Failed to update target. Please try again.')
    stillUpdating = false;
    event.target.reset();
    setIsUpdatingTarget(false);
  }

  return (
    <Card
      backUrl=""
      title="Dashboard"
      variant="LIGHT"
      className="dashboard"
    >
      {isUpdatingTarget && (
        <>
          <div className="full-screen-mask" />
          <div className="day-view__spinner">
            <Spinner />
          </div>
        </>
      )}

{/* THIS IS ONLY PENDING BATTLES ATM BUT COULD BE MORE IN FUTURE */}
      {pendingBattles.length > 0 && (
        <div className="dashboard__notifications">
          <h4>Notifications</h4>
          <p>You have a battle request! Check the <span onClick={() => navigate("/battles")}>battles page</span> to view the request.</p>
        </div>
      )}

      {dailyTargets.length > 0 && user.id &&  (
        <div style={{marginBottom: "32px"}}>
          <div className="dashboard__title">
            <DayTarget />
            <h4>Daily Targets</h4>
          </div>
          {dailyTargets.map((target: any) => {
            const contestantTarget: ContestantTargetType = target[user.id];
            const battle = target.battle;
            const dayKey = target.dayKey;
            const otherUserUsername = getOtherContestant(user.id, battle).username;
            const isCheckbox = target.format === TargetFormat.CHECKBOX;
            return (
              <div className="dashboard__battle" key={target.id}>
                <div className="dashboard__battle__row">
                  <Checkbox
                    checked={!!contestantTarget.input}
                    inputValue={contestantTarget.input}
                    variant={target.format}
                    target={target}
                    battle={battle}
                    contestantTarget={contestantTarget}
                    isUser={true}
                    onChange={(input: any, hideNumberCheck: boolean, photo: string) => onChange(input, target, hideNumberCheck, photo, battle, dayKey)}
                  />
                  <div className="dashboard__battle__text">
                    <p className="dashboard__battle__text__title">{target.title}</p>
                    <div className="dashboard__battle__icon-line">
                      <p>{otherUserUsername} |</p>
                      {isCheckbox && (
                        <div>
                          <CheckboxTarget style={{transform: "scale(0.6)"}} />
                          <p>Checkbox</p>
                        </div>
                      )}
                      {!isCheckbox && (
                        <div>
                          <Hash style={{transform: "scale(0.6)"}}/>
                          <p>Number</p>
                        </div>
                      )}
                      {target.isPhotoRequired && (
                        <div>
                          |
                          <Camera
                            stroke="var(--dark)"
                            style={{width: "24px", height: "24px", transform: "scale(0.6)"}}
                          />
                          <p style={{color: "var(--dark)"}}>Photo</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="icon" onClick={() => onEyeClick(battle)}>
                  <Eye fill="var(--dark)" />
                </div>
              </div>
            )
          })}
        </div>
      )}

      {blockTargets.length > 0 && user.id && (
        <div>
          <div className="dashboard__title">
            <BlockTarget />
            <h4>Block Targets</h4>
          </div>
          {blockTargets.map((target: any) => {
            const contestantTarget: ContestantTargetType = target[user.id];
            const battle: BattleType = target.battle;
            const otherUserUsername = getOtherContestant(user.id, battle).username;
            const endDate = dayjs(battle.startDate).add(battle.numDays - 1, 'day').format('DD MMM');
            const accumulatedTotal = target[user.id].inputArray?.reduce((sum: number, el: any) => sum + +el.value, 0);
            const isCheckbox = target.format === TargetFormat.CHECKBOX;

            if (target.format === TargetFormat.CHECKBOX) {
              return (
                <div className="dashboard__battle" key={target.id}>
                  <div className="dashboard__battle__row">
                    <Checkbox
                      checked={!!contestantTarget.input}
                      inputValue={contestantTarget.input}
                      variant={target.format}
                      target={target}
                      battle={battle}
                      contestantTarget={contestantTarget}
                      isUser={true}
                      onChange={(input: any, hideNumberCheck: boolean, photo: string) => onBlockTargetChange(input, target, hideNumberCheck, photo, battle)}
                    />
  
                    <div className="dashboard__battle__text">
                      <p className="dashboard__battle__text__title">{target.title}</p>
                      <div className="dashboard__battle__icon-line">
                        <p>{otherUserUsername} |</p>
                        {isCheckbox && (
                          <div>
                            <CheckboxTarget style={{transform: "scale(0.6)"}} />
                            <p>Checkbox</p>
                          </div>
                        )}
                        {!isCheckbox && (
                          <div>
                            <Hash style={{transform: "scale(0.6)"}}/>
                            <p>Number</p>
                          </div>
                        )}
                        <p> | {endDate}</p>
                        {target.isPhotoRequired && (
                          <div>
                            |
                            <Camera
                              stroke="var(--dark)"
                              style={{width: "24px", height: "24px", transform: "scale(0.6)"}}
                            />
                            <p style={{color: "var(--dark)"}}>Photo</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
  
                  <div className="icon" onClick={() => onEyeClick(battle)}>
                    <Eye fill="var(--dark)" />
                  </div>
                </div>
              )
            }

            if (target.format === TargetFormat.NUMBER) {
              return (
                <>
                  <div className="dashboard__battle" key={target.id}>
                    <div className="dashboard__battle__row" onClick={() => setBlockToShowId(blockToShowId ? "" : target.id)}>
                      <div
                        className="icon"
                        style={{
                          marginRight: "4px",
                          marginLeft: "-4px",
                          alignSelf: "center",
                          border: blockToShowId !== target.id ? "1px solid var(--dark)" : "none",
                          borderRadius: "3px"
                        }}
                      >
                        {blockToShowId !== target.id && <Plus stroke="var(--dark)" width="23px" height="23px" style={{transform: "scale(0.9)"}} />}
                        {blockToShowId === target.id && <ChevronUp stroke="var(--main)" width="24px" height="24px" />}
                      </div>
    
                      <div className="dashboard__battle__text">
                        <p className="dashboard__battle__text__title">{target.title}</p>
                        <div className="dashboard__battle__icon-line">
                        <p>{otherUserUsername} |</p>
                          {isCheckbox && (
                            <div>
                              <CheckboxTarget style={{transform: "scale(0.6)"}} />
                              <p>Checkbox</p>
                            </div>
                          )}
                          {!isCheckbox && (
                            <div>
                              <Hash style={{transform: "scale(0.6)"}}/>
                              <p>Number</p>
                            </div>
                          )}
                          <p> | {endDate}</p>
                          {target.isPhotoRequired && (
                            <div>
                              |
                              <Camera
                                stroke="var(--dark)"
                                style={{width: "24px", height: "24px", transform: "scale(0.6)"}}
                              />
                              <p style={{color: "var(--dark)"}}>Photo</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
    
                    <div className="icon" onClick={() => onEyeClick(battle)}>
                      <Eye fill="var(--dark)" />
                    </div>
    
                  </div>
                    {blockToShowId === target.id && (
                      <div className="dashboard__battle__extend">
                        <p style={{opacity: "0.7"}}><strong>Running Total: {accumulatedTotal}</strong></p>
                        <form onSubmit={(event) => onAddClick(event, target, battle)}>
                          <Input
                            name="block-number-input"
                            type="number"
                            placeholder="Enter..."
                          />
                          <button type="submit" className="dashboard__battle__extend__submit">+ Add</button>
                        </form>

                        {target[user.id].inputArray.length > 0 && (
                          <>
                            <p style={{opacity: "0.5"}}>Previous entries:</p>
                            {target[user.id].inputArray.map((el: any) => {
                              return (
                                <p>{dayjs(el.date).format('DD MMM')}: {el.value}</p>
                              )
                            }).reverse()}
                          </>
                        )}
                      </div>
                    )}
                </>
              )
            }
          })}
        </div>
      )}
    </Card>

  )
}

export default Dashboard;