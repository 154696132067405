import React, { ReactNode } from 'react';
import './FormElement.scss';

const FormElement: React.FC<{
  label?: any,
  errorMessage?: string | null | boolean,
  id?: string,
  className?: string,
  style?: React.CSSProperties,
  children?: ReactNode
  onClick?: () => void
}> = ({
  label,
  errorMessage,
  id,
  className,
  style,
  children,
  onClick
}) => {

  const getClasses = () => {
    let classes = `${className} form-element `;

    if (errorMessage) {
      classes = classes.concat('form-element--error ')
    }
    
    return classes;
  }

  let formElementClasses = getClasses();

  return (
    <div
      id={id}
      className={formElementClasses}
      style={style}
      onClick={onClick}
    >
      <div className="form-element__label">{label}</div>
      {children}
      <p className="form-element__error-message">{errorMessage}</p>
    </div>
  )
}

export default FormElement;