import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { TargetType } from '../../types/TargetType';
import { ToastType } from '../../types/ToastType';
import { ContestantType } from '../../types/ContestantType';
import { v4 as uuid } from 'uuid';

export interface AppData {
  toasts: ToastType[];
  tempTargets: TargetType[];
  previousOpponents: ContestantType[];
  currentDayKey: string;
  currentBattleId: string;
  installPrompt: boolean;
  installPWA: boolean;
  fcmToken: string;
  changeConsentMode: boolean;
  backgroundClass: string;
}

const initialState: AppData = {
  toasts: [],
  tempTargets: [],
  previousOpponents: [],
  currentDayKey: '',
  currentBattleId: '',
  installPrompt: false,
  installPWA: false,
  fcmToken: '',
  changeConsentMode: false,
  backgroundClass: 'app__background'
}

export const appDataSlice = createSlice({
  name: 'appData',
  initialState,
  reducers: {
    displayToast: (state, action) => {
      return {
        ...state,
        toasts: [
          ...state.toasts,
          action.payload
        ]
      };
    },
    removeToast: (state, action) => {
      const toastsCopy = [...state.toasts];
      const remainingToasts: ToastType[] = toastsCopy.filter((toast: ToastType) => toast.id !== action.payload);
      return {
        ...state,
        toasts: remainingToasts
      };
    },
    clearToasts: (state) => {
      return {
        ...state,
        toasts: []
      }
    },
    addTempTarget: (state, action) => {
      return {
        ...state,
        tempTargets: [
          ...state.tempTargets,
          action.payload
        ]
      }
    },
    updateTempTarget: (state, action) => {
      const index = state.tempTargets.findIndex((obj => obj.id === action.payload.id));
      const newTempTargets = [...state.tempTargets];
      newTempTargets.splice(index, 1, action.payload);
      return {
        ...state,
        tempTargets: newTempTargets
      }
    },
    setTempTargets: (state, action) => {
      return {
        ...state,
        tempTargets: action.payload
      }
    },
    clearTempTargets: (state) => {
      return {
        ...state,
        tempTargets: []
      };
    },
    deleteTempTargetById: (state, action) => {
      const targetsCopy = [...state.tempTargets];
      const remainingTargets: TargetType[] = targetsCopy.filter((target: TargetType) => target.id !== action.payload);
      return {
        ...state,
        tempTargets: remainingTargets
      };
    },
    duplicateTempTarget: (state, action) => {
      const targetsCopy = [...state.tempTargets];
      targetsCopy.push({
        ...action.payload,
        id: uuid()
      });
      return {
        ...state,
        tempTargets: targetsCopy
      };
    },
    setPreviousOpponents: (state, action) => {
      return {
        ...state,
        previousOpponents: action.payload
      };
    },
    setCurrentBattleId: (state, action) => {
      return {
        ...state,
        currentBattleId: action.payload
      };
    },
    setCurrentDayKey: (state, action) => {
      return {
        ...state,
        currentDayKey: action.payload
      };
    },
    setInstallPrompt: (state, action) => {
      return {
        ...state,
        installPrompt: action.payload
      }
    },
    setInstallPWA: (state, action) => {
      return {
        ...state,
        installPWA: action.payload
      }
    },
    setFcmToken: (state, action) => {
      return {
        ...state,
        fcmToken: action.payload
      }
    },
    setChangeConsentMode: (state, action) => {
      return {
        ...state,
        changeConsentMode: action.payload
      }
    },
    setBackgroundClass: (state, action) => {
      return {
        ...state,
        backgroundClass: action.payload
      }
    },
    resetState: (state) => {
      return {...initialState};
    }
  },
})

export const appDataSelector = (state: RootState) => state.appData;
export const appDataActions = appDataSlice.actions;