import React, { ReactNode } from "react";
import './Card.scss';
import { useNavigate } from "react-router-dom";

const Card: React.FC<{
  title?: string;
  backUrl: string;
  variant?: 'LIGHT' | 'DARK' | 'MENU';
  className?: string;
  center?: boolean;
  onMouseOut?: () => void;
  children: ReactNode;
}> = ({
  title,
  backUrl,
  variant = 'DARK',
  className,
  center = false,
  onMouseOut,
  children
}) => {

  const navigate = useNavigate();

  return (
    <div
      className="card-container"
      style={center ? {marginTop: '150px'} : {marginTop: '60px'}}
      onMouseOut={onMouseOut}
    >
      <div className="card-title">{title}</div>

      <div className={`card  card--${variant === 'LIGHT' ? 'light' : ''} ${className} `}>
        <div className="card__content">
          {children}
        </div>
      </div>

      {backUrl && <div className="card-back-btn" onClick={() => navigate(backUrl)}>&lt;Back</div>}
    </div>
  )
}

export default Card;