import React, { useEffect, useRef, useState } from 'react';
import './BattleResponsePage.scss';
import Card from '../../ui-components/card/Card';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { userSelector } from '../../redux/slices/userSlice';
import Button from '../../ui-components/button/Button';
import { updateBattleStatus } from '../../database/update';
import { convertCurrentBattleToArrays, displayToast, getOtherContestant } from '../../helpers/app-helpers';
import { BattleStatus } from '../../enums/enums';
import { battlesSelector } from '../../redux/slices/battlesSlice';
import { BattleType } from '../../types/BattleType';
import { appDataActions } from '../../redux/slices/appDataSlice';
import { TargetType } from '../../types/TargetType';
import TargetLine from '../../components/target-line/TargetLine';
import { ContestantType } from '../../types/ContestantType';
import { ReactComponent as Edit } from '../../ui-components/icon/svgs/edit.svg';
import { ReactComponent as Book } from '../../ui-components/icon/svgs/book.svg';
import { ReactComponent as Target } from '../../ui-components/icon/svgs/target.svg';
import { ReactComponent as Calendar } from '../../ui-components/icon/svgs/calendar.svg';
import { ReactComponent as Present } from '../../ui-components/icon/svgs/present.svg';
import { ReactComponent as Swords } from '../../ui-components/icon/svgs/swords.svg';
import { BattleCreationType } from '../../types/BattleCreationType';
import { battleCreationActions } from '../../redux/slices/battleCreation';
import dayjs from 'dayjs';

const BattleResponsePage = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const battleIdParam = searchParams.get('id');
  const textareaRef = useRef<any>();
  
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelector);
  const userId = user.id;
  const battles = useAppSelector(battlesSelector);
  const [otherContestant, setOtherContestant] = useState<ContestantType>();
  const [battle, setBattle] = useState<BattleType>();
  const [isAccepting, setIsAccepting] = useState(false);
  const [isDeclining, setIsDeclining] = useState(false);
  const [battleTitle, setBattleTitle] = useState<string>('');
  
  useEffect(() => {
    getAndSetBattle();
  }, [])

  useEffect(() => {
    if (battle) setOtherContestant(getOtherContestant(userId, battle));
    if (textareaRef.current) textareaRef.current.style.height = textareaRef.current.scrollHeight + "px";
  }, [battle])

  const getAndSetBattle = () => {
    let battle;
    if (battleIdParam) {
      battle = convertCurrentBattleToArrays(battles, battleIdParam);
      setBattle(battle);
    }
    dispatch(appDataActions.setCurrentBattleId(battleIdParam))

    if (battle) {
      const opponent = battle.challenger.id === user.id ? battle.opponent : battle.challenger;
      setBattleTitle(`vs ${opponent.username} | ${dayjs(battle.startDate).format('DD MMM')}`);
    }
  }

  const onBattleDecline = async() => {
    setIsDeclining(true);
    let success = false;
    if (battle) success = await updateBattleStatus(battle.id, BattleStatus.DECLINED);
    if (!success) {
      displayToast(dispatch, 'Battle decline failed, please try again.');
      setIsDeclining(false);
      return;
    }
    dispatch(appDataActions.setCurrentBattleId(''))
    navigate('/battles');
  }

  const onBattleAccept = async() => {
    setIsAccepting(true);
    let success = false;
    if (battle) success = await updateBattleStatus(battle.id, BattleStatus.ACTIVE);
    if (!success) {
      displayToast(dispatch, 'Battle acceptance failed, please try again.');
      setIsAccepting(false);
      return;
    }
    navigate('/battle');
  }

  const onProposeChangesClick = () => {
    if (battle) {
      const isChallenger = battle.challenger.id === user.id;
      const battleCreationObj: BattleCreationType = {
        id: battle.id,
        title: battle.title,
        startDate: battle.startDate,
        numDays: battle.numDays,
        description: battle.description,
        prize: battle.prize,
        teamBattle: battle.teamBattle,
        challenger: isChallenger ? {...battle.challenger} : {...battle.opponent},
        opponent: isChallenger ? {...battle.opponent} : {...battle.challenger},
      }

      dispatch(battleCreationActions.setBattle(battleCreationObj));
      generateTempTargets();

      navigate('/create-battle');
    }
  }

  const generateTempTargets = () => {
    if (battle) {
      const hasDays = Object.keys(battle.days).length > 0;
      const hasBlockTargets = Object.keys(battle.blockTargets).length > 0;
      dispatch(appDataActions.clearTempTargets())
      if (hasDays) {
        for (const target of battle.days[0].targets) {
          dispatch(appDataActions.addTempTarget({
            id: target.id,
            title: target.title,
            description: target.description ? target.description : '',
            format: target.format,
            frequency: target.frequency,
            isPhotoRequired: target.isPhotoRequired
          }));
        }
      }
      if (hasBlockTargets) {
        for (const target of battle.blockTargets) {
          dispatch(appDataActions.addTempTarget({
            id: target.id,
            title: target.title,
            description: target.description ? target.description : '',
            format: target.format,
            frequency: target.frequency,
            isPhotoRequired: target.isPhotoRequired
          }));
        }
      }
    }
  }  

  if (battle?.status === BattleStatus.DECLINED) {
    return (
      <Card
        title={battleTitle}
        variant="LIGHT"
        backUrl={"/battles"}
      >
        This battle was declined.
      </Card>
    )
  }

  return (
    <Card
      title="Battle Request"
      variant="LIGHT"
      backUrl={"/battles"}
    >
      <div className="battle-response-page">

        {battle && (
          <div className="battle-details__entry-container">
            <div className="battle-details__entry">
              <Swords stroke="var(--dark)"/>
              <p className="battle-details__entry__title">Challenger</p>
              <p>{otherContestant?.username}</p>
            </div>

            <div className="battle-details__entry">
              <Present />
              <p className="battle-details__entry__title">Prize/Forfeit</p>
              <p>{battle.prize ? battle.prize : "n/a"}</p>
            </div>

            <div className="battle-details__entry">
              <Calendar />
              <p className="battle-details__entry__title">Start Date</p>
              <p>{dayjs(battle.startDate).format("DD MMM YYYY")}</p>
            </div>

            <div className="battle-details__entry">
              <Calendar />
              <p className="battle-details__entry__title">Finish Date</p>
              <p>{dayjs(battle.startDate).add(battle.numDays - 1, 'day').format("DD MMM YYYY")}</p>
            </div>

          </div>
        )}

        <div className="battle-details__targets-title">
          <Target />
          <p>Targets</p>    
        </div>

        {battle && Object.keys(battle.days).length > 0 && (
          <>
            {battle.days[0].targets.map((target: TargetType) => {
              return (
                <TargetLine
                  key={target.id}
                  target={target}
                />
              )}
            )}
          </>
        )}

        {battle && Object.keys(battle.blockTargets).length > 0 && (
          <>
            {battle.blockTargets.map((target: TargetType) => {
              return (
                <TargetLine
                  key={target.id}
                  target={target}
                />
              )}
            )}
          </>
        )}

        {battle?.description && (
          <div>
            <div className="battle-details__description-title">
              <Book />
              <p>Description/Rules</p>
            </div>
            <textarea
              ref={textareaRef}
              value={battle.description}
              readOnly 
            />
          </div>
        )}

        {battle?.challenger.id !== userId && (
          <div className="battle-response-page__btns">
            <Button
              isSpinning={isAccepting}
              onClick={onBattleAccept}
            >Accept</Button>

            <Button onClick={onProposeChangesClick}>
              <Edit fill="var(--dark)" />
              Propose Changes
            </Button>
            
            <Button
              variant="cancel"
              isSpinning={isDeclining}
              onClick={onBattleDecline}
            >
              Decline
            </Button>
          </div>
        )}

        {battle?.challenger.id === userId && (
          <>
            <h3>Pending approval by {otherContestant?.username}</h3>
            <Button onClick={onProposeChangesClick}>
              <Edit fill="var(--dark)" />
              Edit Battle
            </Button>
          </>
        )}

      </div>
    </Card>
  )
}

export default BattleResponsePage;