import React, { ReactNode } from "react";
import './Button.scss';
import Spinner from '../spinner/Spinner';

interface Props {
  variant?: 'default' | 'primary' | 'secondary' | 'cancel' | 'text' | 'brick' | 'white',
  type?: 'button' | 'reset' | 'submit',
  size?: 'small' | 'large',
  disabled?: boolean,
  style?: React.CSSProperties,
  className?: string,
  id?: string,
  iconColor?: string,
  isSpinning?: boolean,
  spinnerVariant?: 'LIGHT' | 'DARK'
  tooltip?: string,
  onClick?: (event: any) => void,
  children?: ReactNode
}

const Button: React.FC<Props> = ({
  variant,
  type,
  size = 'large',
  disabled,
  style,
  className,
  id,
  isSpinning,
  spinnerVariant = 'DARK',
  tooltip,
  children,
  onClick
}) => {

  const getClasses = () => {
    let classes = `btn `;

    if (variant && !disabled) classes = classes.concat(`btn-${variant} `);
    if (disabled) classes = classes.concat('btn-disabled ');
    if (isSpinning) classes = classes.concat('btn-is-spinning ');
    if (size === 'small') classes = classes.concat('btn--small ');

    classes = classes.concat(`${className}`);

    return classes;
  }

  const buttonClasses = getClasses();

  return (
    <>
      <button
        onClick={onClick}
        className={buttonClasses}
        id={id}
        style={style}
        disabled={disabled}
        type={type}
        data-tip={tooltip}
        data-delay-show='300'
      >
        {!isSpinning && children}

        {isSpinning
        && <>
          <Spinner variant={spinnerVariant}/> {children}
        </>}
      </button>
    </>
  )
};

export default Button;