import React, { ReactNode, useEffect, useRef, useState } from 'react';
import './Checkbox.scss';
import { TargetFrequency, TargetType } from '../../../../../types/TargetType';
import { TargetFormat } from '../../../../../enums/enums';
import PhotoInputModal from './photo-input-modal/PhotoInputModal';
import { ReactComponent as Eye  } from '../../../../../ui-components/icon/svgs/eye.svg';
import { ReactComponent as Camera  } from '../../../../../ui-components/icon/svgs/camera.svg';
import ViewPhotoModal from './view-photo-modal/ViewPhotoModal';
import { displayToast } from '../../../../../helpers/app-helpers';
import { useAppDispatch } from '../../../../../redux/hooks';
import { BattleType } from '../../../../../types/BattleType';
import NumberInputModal from './number-input-modal/NumberInputModal';
import dayjs from 'dayjs';
import { DayType } from '../../../../../types/DayType';
import { ContestantTargetType } from '../../../../../types/ContestantTargetType';
import ReactPortal from '../../../../../components/react-portal/ReactPortal';
import useSound from 'use-sound';

const Checkbox: React.FC<{
  id?: string;
  className?: string;
  style?: React.CSSProperties;
  checked: boolean;
  inputValue: any;
  text?: string;
  variant: TargetFormat;
  target: TargetType;
  day?: DayType;
  battle: BattleType;
  contestantTarget: ContestantTargetType;
  isUser: boolean;
  onChange: (input: any, hideNumberCheck: boolean, photo: string) => void;
  children?: ReactNode;
}> = ({
  id,
  className,
  style,
  checked,
  inputValue,
  text,
  variant,
  target,
  day,
  battle,
  contestantTarget,
  isUser,
  onChange,
  children
}) => {

  const dispatch = useAppDispatch();

  const numberInputRef = useRef<any>(null);
  const [playSound] = useSound(require('./tick.wav'));

  const [showNumberInputModal, setShowNumberInputModal] = useState(false);
  const [showPhotoInputModal, setShowPhotoInputModal] = useState<boolean>(false);
  const [showViewPhotoModal, setShowViewPhotoModal] = useState<boolean>(false);
  const [existingImage, setExistingImage] = useState<any>(null);
  const [numberInputMargin, setNumberInputMargin] = useState('18px');
  const [alreadyChecked, setAlreadyChecked] = useState(false);
  const [firstRun, setFirstRun] = useState(true);

  useEffect(() => {
    if (firstRun) {
      setFirstRun(false);
      if (checked) setAlreadyChecked(true);
    } else if (!alreadyChecked && checked) playSound();
  }, [checked])

  useEffect(() => {
    setExistingImage(target.isPhotoRequired && contestantTarget.photo)
  }, [target])

  const onClick = () => {
    if (dayjs(day?.date).isSame(dayjs(), 'day') || target.frequency === TargetFrequency.BLOCK) {
      if (variant === TargetFormat.NUMBER) {
        if (isUser) {
          setShowNumberInputModal(true);
          return;
        }
        if (!isUser && target.isPhotoRequired && contestantTarget.photo) {
          setShowNumberInputModal(true);
          return;
        }
        if (!isUser && !target.isPhotoRequired) {
          displayToast(dispatch, 'This does not belong to you.')
        }
      }
  
      if (target.isPhotoRequired) {
        if (checked) setShowViewPhotoModal(true);
        if (!checked) {
          if (!isUser) displayToast(dispatch, 'This does not belong to you.');
          else setShowPhotoInputModal(true);
        }
        return;
      }
  
      if (variant === TargetFormat.CHECKBOX) {
        if (isUser) onChange(!checked, false, '');
        else displayToast(dispatch, 'This does not belong to you.');
      }
    } else if (contestantTarget.photo) {
        setShowViewPhotoModal(true);
        return;
    } else {
      displayToast(dispatch, 'You cannot edit previous days targets')
    }
  }

  const onInputConfirm = (value: string, hideNumberCheck: boolean, imageRef: string = '') => {
    onChange(+value, hideNumberCheck, imageRef);
    setShowNumberInputModal(false);
  }

  const onInputClear = () => {
    onChange('', false, '');
    setShowNumberInputModal(false);
  }

  const nullEvent = () => {
    return;
  }

  const onPhotoDelete = () => {
    onChange('', false, '');
    setShowViewPhotoModal(false);
  }

  useEffect(() => {
    if (numberInputRef?.current?.offsetWidth > 18) setNumberInputMargin((numberInputRef?.current?.offsetWidth) + 6 + "px")
    else setNumberInputMargin('24px')
  }, [numberInputRef.current?.offsetWidth])

  return (
    <>
      <div className="checkbox-container" onClick={onClick}>
        {variant === TargetFormat.CHECKBOX && (
          <div
            id={id}
            className={!isUser ? `checkbox checkbox--otherUser ${className}` : `checkbox ${className}`}
            style={style}
          >
            <input
              type="checkbox"
              checked={checked}
              onChange={() => nullEvent}
            />
            <span className={!isUser ? "checkmark checkmark--otherUser" : "checkmark"}>
              {target.isPhotoRequired && checked && (
                <Eye
                  fill="var(--success)"
                  style={{transform: "translate(1px, 1px)"}}
                  className="checkmark__icon"
                />
              )}

              {target.isPhotoRequired && !checked && (
                <Camera
                  fill="var(--success)"
                  className="checkmark__icon"
                />
              )}
            </span>
          </div>
        )}

        {variant === TargetFormat.NUMBER && (
          <div className="checkbox-number-container" ref={numberInputRef}>
            <div
              className={
                inputValue
                  ? !isUser
                      ? "checkbox-number-input checkbox-number-input--active checkbox-number-input--active--otherUser"
                      : "checkbox-number-input checkbox-number-input--active"
                  : !isUser
                    ? "checkbox-number-input checkbox-number-input--inactive checkbox-number-input--inactive--otherUser"
                    : "checkbox-number-input checkbox-number-input--inactive"
              }
            >
              {!isUser && contestantTarget.hide ? "?" : inputValue}
            </div>

            {target.isPhotoRequired && checked && (
              <Eye
                fill="var(--success)"
                style={{transform: "translate(1px, 1px)"}}
                className="checkmark__icon"

              />
            )}

            {target.isPhotoRequired && !checked && (
              <Camera
                fill="var(--success)"
                className="checkmark__icon"
              />
            )}
          </div>
        )}
        <p
          className="checkbox-container__text"
          style={{marginLeft: numberInputMargin}}
        >{text ? text : children}</p>
      </div>

      {/* <TestModal isOpen={true} handleClose={() => null}>g</TestModal> */}

      {showNumberInputModal && (
        <ReactPortal wrapperId="react-portal-modal-container">
          <NumberInputModal
            target={target}
            currentValue={inputValue}
            hideNumber={!isUser && contestantTarget.hide}
            battle={battle}
            isUser={isUser}
            photoPath={contestantTarget.photo}
            onCancel={() => setShowNumberInputModal(false)}
            onClear={() => onInputClear()}
            onConfirm={(input, hideNumberCheck, imageRef) => onInputConfirm(input, hideNumberCheck, imageRef)}
          />
        </ReactPortal>
      )}

      {showPhotoInputModal && (
        <ReactPortal wrapperId="react-portal-modal-container">
          <PhotoInputModal
            battle={battle}
            confirmEvent="UPLOAD"
            onClose={() => setShowPhotoInputModal(false)}
            onUpload={(imageRef) => onChange(true, false, imageRef)}
            onReturn={() => null}
          />
        </ReactPortal>
      )}

      {showViewPhotoModal && (
        <ReactPortal wrapperId="react-portal-modal-container">
          <ViewPhotoModal
            existingImage={existingImage}
            photoPath={contestantTarget.photo}
            isUser={isUser}
            battle={battle}
            day={day}
            isBlockTarget={target.frequency === TargetFrequency.BLOCK}
            onClose={() => setShowViewPhotoModal(false)}
            onDelete={onPhotoDelete}
            onUpload={(imageRef) => onChange(true, false, imageRef)}
          />
        </ReactPortal>
      )}
    </>
  )
}

export default Checkbox;