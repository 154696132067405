import React, { ReactNode, useEffect, useState } from 'react';
import './NavigationButton.scss';

const NavigationButton: React.FC<{
  style?: React.CSSProperties;
  children: ReactNode;
  isDefault?: boolean;
  onMouseOver?: (event: any) => void;
  onClick: (event: any) => void;
}> = ({
  style,
  children,
  isDefault = false,
  onMouseOver,
  onClick
}) => {

  const [flicker, setFlicker] = useState<boolean>(false);
  const [classes, setClasses] = useState<string>('navigation-button');

  const onNavClick = (event: any) => {
    setFlicker(true);
    setTimeout(() => onClick(event), 300);
  }

  useEffect(() => {
    let classes = 'navigation-button ';

    if (isDefault) classes = classes.concat('navigation-button--is-default ');
    if (flicker) classes = classes.concat('navigation-button--flicker ')

    setClasses(classes);
  }, [isDefault, flicker])

  return (
    <div
      className={classes}
      style={style}
      onMouseOver={onMouseOver}
      onClick={(event) => onNavClick(event)}
    >
      {children}
    </div>
  )
}

export default NavigationButton;