import { Dispatch } from "@reduxjs/toolkit";
import { appDataActions } from "../redux/slices/appDataSlice";
import { v4 as uuidv4 } from 'uuid';
import { BattleType } from "../types/BattleType";
import { BattleTypeDB } from "../types/BattleTypeDB";
import { DayType } from "../types/DayType";
import dayjs from "dayjs";
import { UserType } from "../types/UserType";

export const displayToast = (dispatch: Dispatch, content: string, time = 3000) => {
  const id = uuidv4();

  dispatch(appDataActions.displayToast({id, content}));
  setTimeout(() => dispatch(appDataActions.removeToast(id)), time);
}

export const getOtherContestant = (userId: string, battle: BattleType) => {
  return battle.challenger.id === userId ? battle.opponent : battle.challenger;
}

export const getCurrentUserContestantObj = (userId: string, battle: BattleType) => {
  return battle.challenger.id === userId ? battle.challenger : battle.opponent;
}

export const isErrors = (errHandler: any) => {
  let returnVal = false;
  for (const el in errHandler) {
    if (!(errHandler[el].criteria) as any) returnVal = true;
  }
  return returnVal;
}

export const convertCurrentBattleToArrays = (battles: BattleTypeDB[], currentBattleId: string) => {
  const currentBattle = battles.filter((battle: BattleType) => battle.id === currentBattleId)[0];
  return convertBattleToArrays(currentBattle);
}

export const convertBattleToArrays = (battle: BattleType) => {
  const days: DayType[] = [];

  for (const key in battle.days) {
    const day = battle.days[key]

    const targets = [];

    for (const key in day) {
      // @ts-ignore
      if (day[key].id) {
        // @ts-ignore
        targets.push(day[key]);
      }
    }
    days.push({
      date: day.date,
      dayKey: day.dayKey,
      targets
    })
  }

  const blockTargets = [];

  for (const key in battle.blockTargets) {
    const blockTarget = battle.blockTargets[key]
    blockTargets.push(blockTarget);
  }

  return {...battle, days, blockTargets};
}

export const createPhotoMetadata = (challengerId: any, opponentId: any) => {
  return {
    customMetadata: {
        "challengerId": challengerId,
        "opponentId": opponentId
    }
  }
}

export const getCurrentBattleDay = (battle: BattleType) => {
  const todayDate = dayjs().format('YYYY-MM-DD');
  const day = battle.days.filter((day: DayType) => day.date === todayDate)[0];
  return day;
}

export const getOtherUserId = (battle: BattleType, currentUser: UserType) => {
  return battle.userIds.filter((id: string) => id !== currentUser.id)[0];
}

export function validateEmail(email: string) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}