import React, { ChangeEvent, FocusEvent } from 'react';
import './Input.scss';

interface Props {
  name: string;
  id?: string;
  className?: string;
  placeholder?: string;
  value?: string;
  type?: string;
  min?: string;
  max?: string;
  integer?: boolean;
  disabled?: boolean;
  variant?: 'LIGHT' | 'DARK';
  style?: React.CSSProperties;
  readOnly?: boolean;
  passRef?: any;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}

const Input: React.FC<Props> = ({
  name,
  placeholder,
  value,
  id,
  type,
  min,
  max,
  integer,
  disabled,
  variant = 'LIGHT',
  style,
  className,
  readOnly,
  passRef,
  onChange,
  onBlur
}) => {

  const getClasses = () => {
    let classes = `${className} input `;

    if (disabled) {
      classes = classes.concat('input--disabled ');
    }

    if (variant === 'DARK') classes = classes.concat('input--dark ');
    return classes;
  }

  const inputClasses = getClasses();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event);
  }

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    onBlur?.(event);
  }

  return (
    <div className="input-container">
      <input
        id={id}
        name={name}
        type={type}
        min={min}
        max={max}
        onKeyDown={(event) => {
          if(integer && event.key==='.'){event.preventDefault();}}
        }
        className={inputClasses}
        style={style}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        readOnly={readOnly}
        ref={passRef}
        onChange={handleChange}
        onBlur={handleBlur}
      ></input>
    </div>
  )
}

export default Input;