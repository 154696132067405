import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { Provider } from 'react-redux';
import store from './redux/store';
import './theme.scss';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage, ref } from 'firebase/storage';
import { AuthUserProvider } from './firebase/Auth';
import { getMessaging, isSupported } from "firebase/messaging";


export const app = initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
}, {automaticDataCollectionEnabled: false});

export const db = getFirestore();
export const auth = getAuth();
export const storage = getStorage();
export const storageRef = ref(storage);
export const cloudMessaging: any = async () => await isSupported() && getMessaging();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <AuthUserProvider>
        <App />
      </AuthUserProvider>
    </Provider>
  </BrowserRouter>
);

reportWebVitals();
