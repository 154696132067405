import React, { useEffect, useRef, useState } from 'react';
import './BattlePage.scss';
import { useAppSelector } from '../../redux/hooks';
import Card from '../../ui-components/card/Card';
import { userSelector } from '../../redux/slices/userSlice';
import dayjs from 'dayjs';
import { convertCurrentBattleToArrays, getOtherContestant } from '../../helpers/app-helpers';
import DayView from './day-view/DayView';
import Spinner from '../../ui-components/spinner/Spinner';
import BattleCalender from './battle-calender/BattleCalender';
import { battlesSelector } from '../../redux/slices/battlesSlice';
import { BattleType } from '../../types/BattleType';
import { appDataSelector } from '../../redux/slices/appDataSlice';
import { DayType } from '../../types/DayType';
import { ContestantType } from '../../types/ContestantType';
import { getUser } from '../../database/read';

const BattlePage = () => {

  const battles = useAppSelector(battlesSelector);
  const user = useAppSelector(userSelector);
  const appData = useAppSelector(appDataSelector);
  const textareaRef = useRef<any>();

  const [battle, setBattle] = useState<BattleType>();
  const [otherContestant, setOtherContestant] = useState<ContestantType>({} as ContestantType);
  const [days, setDays] = useState<DayType[]>();
  const [blankDays, setBlankDays] = useState<number>();
  const [activeView, setActiveView] = useState<'CALENDER' | 'DAY'>('CALENDER')
  const [isLoading, setIsLoading] = useState(false);
  const [otherUser, setOtherUser] = useState<any>();

  useEffect(() => {
    getAndSetData();
    if (textareaRef.current) textareaRef.current.style.height = textareaRef.current.scrollHeight + "px";
  }, [battle])

  useEffect(() => {
    if (appData.currentBattleId) setBattle(convertCurrentBattleToArrays(battles, appData.currentBattleId));
  }, [battles])

  const getAndSetData = () => {
    if (battle) {
      setIsLoading(true);
      const otherContestant = getOtherContestant(user.id, battle)
      setOtherContestant(otherContestant);
      getAndSetOtherUser(otherContestant.id)
      setDays(battle.days);
      getAndSetBlankDays();
      setIsLoading(false);
    }
  }

  const getAndSetOtherUser = async(otherContestantId: string) => {
    const otherUserObj: any = await getUser(otherContestantId);
    if (otherUserObj) setOtherUser(otherUserObj);
  }

  const getAndSetBlankDays = () => {
    const firstDOW = dayjs(battle?.startDate).get('day');
    if (firstDOW < 1) setBlankDays(6);
    if (firstDOW === 1) setBlankDays(0);
    if (firstDOW > 1) setBlankDays(firstDOW - 1);
  }

  if (isLoading) {
    return <Card backUrl="/battles"><Spinner /></Card>
  }

  return (
    <>
      <div className="battle-page">

        {activeView === "CALENDER" && days && battle && (
          <BattleCalender
            days={days}
            blankDays={blankDays}
            battle={battle}
            otherContestant={otherContestant}
            otherUser={otherUser}
            setActiveView={(event) => setActiveView(event)}
          />
        )}

        {activeView === "DAY" && battle && (
          <DayView
            battle={battle}
            otherContestant={otherContestant}
            otherUser={otherUser}
            setActiveView={() => setActiveView("CALENDER")}
          />
        )}

      </div>
    </>
  )
}

export default BattlePage;