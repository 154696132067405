import React, { useState } from 'react';
import Card from '../../../ui-components/card/Card';
import { useAppSelector } from '../../../redux/hooks';
import { battleCreationSelector } from '../../../redux/slices/battleCreation';
import { useNavigate } from 'react-router-dom';
import { BattleCreationType } from '../../../types/BattleCreationType';
import NavigationButton from '../../../ui-components/navigation-button/NavigationButton';

const StartNewBattleWith = () => {

  const navigate = useNavigate();
  const battleCreation: BattleCreationType = useAppSelector(battleCreationSelector);
  const { opponent } = battleCreation;

  const [showDefaultBtn, setShowDefaultBtn] = useState(true);

  return (
    <>
      <Card
        title="Start New Battle"
        variant="MENU"
        backUrl="/start-new-battle"
        onMouseOut={() => setShowDefaultBtn(true)}
      >
        <p>with</p>
        {opponent && (
          <h3 style={{margin: "12px 0 24px 0"}}>{opponent.username}</h3>
        )}

        <NavigationButton
          isDefault={showDefaultBtn}
          onMouseOver={() => setShowDefaultBtn(false)}
          onClick={() => navigate('/start-new-battle/repeat-battle')}
        >
          Repeat a Battle
        </NavigationButton>

        <NavigationButton
          onMouseOver={() => setShowDefaultBtn(false)}
          onClick={() => navigate('/create-battle')}
        >
          Create New Battle
        </NavigationButton>
      </Card>
    </>
  )
}

export default StartNewBattleWith;