import { doc, writeBatch } from "firebase/firestore";
import { UserType } from "../types/UserType";
import { db } from "..";
import { displayToast } from "../helpers/app-helpers";

export const deleteUserAndAccount = async(user: UserType, dispatch: any) => {
  let success = false;
  try {
    let batch = writeBatch(db);
    batch.delete(doc(db, "users", user.id));
    // batch.delete(doc(db, "usernames", user.lowerUsername));
    await batch.commit();
    success = true;
  } catch (error) {
    console.error('Delete account error: ', error);
    displayToast(dispatch, 'An error occurred deleting your account. Please try again.');
    success = false;
  }
  return success;
}