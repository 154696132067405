import React, { useEffect, useState } from 'react';
import './BattleLine.scss';
import { BattleType } from '../../types/BattleType';
import { ReactComponent as ChevronRight  } from '../../ui-components/icon/svgs/chevronRight.svg';
import dayjs from 'dayjs';
import { useAppSelector } from '../../redux/hooks';
import { userSelector } from '../../redux/slices/userSlice';

const BattleLine: React.FC<{
  battle: BattleType;
  onBattleClick: () => void
}> = ({
  battle,
  onBattleClick
}) => {

  const user = useAppSelector(userSelector);
  const [battleTitle, setBattleTitle] = useState<string>('');

  useEffect(() => {
    const opponent = battle.challenger.id === user.id ? battle.opponent : battle.challenger;
    setBattleTitle(`vs ${opponent.username} | ${dayjs(battle.startDate).format('DD MMM')}`);
  }, [])

  return (
    <div
      className="battle-line"
      onClick={onBattleClick}
    >
      {battleTitle}
      <ChevronRight />
    </div>
  )
}

export default BattleLine;