import React, { useEffect, useRef, useState } from 'react';
import './AddPhotoModal.scss';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { onFileSubmit } from '../../pages/battle-page/day-view/battle-card/checkbox/Utils-Checkbox';
import Button from '../../ui-components/button/Button';
import { battlesSelector } from '../../redux/slices/battlesSlice';
import { BattleType } from '../../types/BattleType';
import BattleLine from '../battle-line/BattleLine';
import { convertCurrentBattleToArrays, createPhotoMetadata, displayToast, getCurrentBattleDay, getOtherUserId } from '../../helpers/app-helpers';
import { BattleStatus, TargetFormat } from '../../enums/enums';
import { TargetType } from '../../types/TargetType';
import { ReactComponent as ChevronRight  } from '../../ui-components/icon/svgs/chevronRight.svg'; 
import { updateTarget } from '../../database/update';
import { userSelector } from '../../redux/slices/userSlice';
import { v4 as uuid } from 'uuid';
import { ref, uploadBytes } from 'firebase/storage';
import { storage } from '../..';
import ReactPortal from '../react-portal/ReactPortal';
import Spinner from '../../ui-components/spinner/Spinner';

const AddPhotoModal: React.FC<{onClose: () => void}> = ({onClose}) => {

  const dispatch = useAppDispatch();
  const addPictureRef = useRef<any>();
  const takePhotoRef = useRef<any>();
  // const scrollRef = useRef<any>(null);
  const battles = useAppSelector(battlesSelector);
  const user = useAppSelector(userSelector);

  const [newPhoto, setNewPhoto] = useState<any>(null);
  const [tempPhotoURL, setTempPhotoURL] = useState<any>(null);
  const [currentBattle, setCurrentBattle] = useState<BattleType>();
  const [currentDay, setCurrentDay] = useState<any>(null);
  const [isChallenger, setIsChallenger] = useState<boolean>(false);
  const [activeBattles, setActiveBattles] = useState<BattleType[]>([]);
  const [updatableTargets, setUpdatableTargets] = useState<TargetType[] | null>(null);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    setActiveBattles(
      battles.filter((battle: BattleType) => {
        let isPhotoBattle = false;
        for (const el in battle.days) {
          const day = battle.days[el];
          for (const el in day) {
            if (el.includes('target')) {
              // @ts-ignore
              const target: TargetType = day[el];
              const isBattleChallenger = battle.challenger.id === user.id ? true : false;
              if (target.isPhotoRequired && target.format === TargetFormat.CHECKBOX && !(isBattleChallenger ? target.challengerInput : target.opponentInput)) {
                isPhotoBattle = true;
                break;
              }
            }
          } 
        }
        return battle.status === BattleStatus.ACTIVE && isPhotoBattle;
      })
    );
  }, [battles])

  // useEffect(() => {
  //   setTimeout(() => scrollRef.current?.scrollIntoView({ behavior: "smooth", block: "end" }), 100);
  // }, [newPhoto, updatableTargets])
  
  const onAddPictureClick = () => {
    addPictureRef.current.click();
  }
  
  const onTakePhotoClick = () => {
    takePhotoRef.current.click();
  }

  const onBattleClick = (battle: BattleType) => {
    const isBattleChallenger = battle.challenger.id === user.id ? true : false;
    setIsChallenger(isBattleChallenger);
    
    const convertedBattle = convertCurrentBattleToArrays(activeBattles, battle.id);
    setCurrentBattle(convertedBattle);
    const currentBattleDay = getCurrentBattleDay(convertedBattle);
    setCurrentDay(currentBattleDay);

    const updatableTargets: TargetType[] = [];
    for (const target of currentBattleDay.targets) {
      if (target.isPhotoRequired && !(isBattleChallenger ? target.challengerInput : target.opponentInput)) {
        updatableTargets.push(target);
      }
    }
    setUpdatableTargets(updatableTargets);
  }

  const uploadPhoto = async() => {
    const metadata: any = createPhotoMetadata(currentBattle?.challenger.id, currentBattle?.opponent.id);

    const targetConfirmationsRef = ref(storage, `targetConfirmations/${newPhoto.name + uuid()}`);
    try {
      const uploadedPhoto = await uploadBytes(targetConfirmationsRef, newPhoto, metadata);
      if (uploadedPhoto) {
        displayToast(dispatch, 'Photo uploaded');
        return uploadedPhoto;
      } else displayToast(dispatch, 'Image failed to upload. Please try again.');
    } catch (error) {
      console.error(error);
      displayToast(dispatch, 'Image failed to upload. Please try again.');
    }
  }

  const onTargetClick = async(target: TargetType) => {
    setIsUploading(true);
    const uploadedPhoto = await uploadPhoto();
    let otherUserId = '';
    if (currentBattle) otherUserId = getOtherUserId(currentBattle, user);

    let success;
    if (uploadedPhoto && currentBattle) success = updateTarget(currentBattle.id, currentDay.dayKey, target.targetKey, 'true', user.id, otherUserId, false, currentBattle, target, uploadedPhoto.metadata.fullPath);
    else displayToast(dispatch, "Failed to upload new photo. Please try again.");
    if (!success) displayToast(dispatch, 'Failed to save photo. Please try again.');
    setIsUploading(false);

    onClose();
  }

  const onPhoto = (file?: File) => {
    onFileSubmit(file, dispatch, setNewPhoto, setTempPhotoURL);
  }

  return (
    <>
      <ReactPortal wrapperId="react-portal-modal-container">

        <div className="full-screen-mask" onClick={onClose}/>

        <div className="add-photo-modal">
          <h3>Add Photo</h3>
          <div className="add-photo-modal__content">
            <p>Add a photo to use in one of your open battles.</p>

            <input
              name="photoInput"
              accept="image/*"
              type="file"
              capture="environment"
              ref={takePhotoRef}
              onChange={(event => onPhoto(event.target.files?.[0]))}
            />

            <input
              name="photoInput"
              accept="image/*"
              type="file"
              ref={addPictureRef}
              onChange={(event => onPhoto(event.target.files?.[0]))}
            />

            {newPhoto && (
              <>
                <p>Choose a battle to add this photo to:</p>
                {activeBattles.length > 0 && (
                  <div className="add-photo-modal__content__battle-list">
                    {activeBattles.map((battle) => {
                      return (
                        <div key={battle.id}>
                          <BattleLine
                            key={battle.id}
                            battle={battle}
                            onBattleClick={() => onBattleClick(battle)}
                          />
                            <div className="add-photo-modal__content__battle-list__target-list">
                              {
                                updatableTargets
                                && updatableTargets.length > 0
                                && currentBattle?.id === battle.id
                                && updatableTargets.map((target: TargetType) => {
                                return (
                                  <div
                                    key={target.id}
                                    onClick={() => onTargetClick(target)}
                                  >
                                    <div style={{
                                      display: "flex",
                                      alignItems: "center",
                                      paddingLeft: "12px"
                                    }}>
                                      {isUploading && <Spinner variant="LIGHT" />}
                                      <h4>{target.title}</h4>
                                    </div>
                                    <ChevronRight
                                      fill="var(--light)"
                                      style={{width: "12px", height: "12px"}}
                                    />
                                  </div>
                                )
                              }
                              )}
                              {
                                updatableTargets
                                && updatableTargets.length < 1
                                && currentBattle?.id === battle.id
                                && <p>No targets left to update.</p>
                              }
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )}
                {activeBattles.length < 1 && <p>You currently have no active battles.</p>}
              </>
            )}
            {newPhoto && <p className="add-photo-modal__content__upload-text"><span>{newPhoto?.name}</span></p>}

            {newPhoto && <img src={tempPhotoURL} alt="Target confirmation" />}

          </div>

          {!newPhoto && (
            <div className="add-photo-modal__btns">
              <Button variant="text" onClick={onAddPictureClick}>Add From Gallery</Button>
              <Button variant="text" onClick={onTakePhotoClick}>Take Photo</Button>
            </div>
          )}


          {/* <div className="scroll-to" ref={scrollRef} /> */}

        </div>
      </ReactPortal>
    </>
  )
}

export default AddPhotoModal;