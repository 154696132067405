import React, { useEffect, useRef, useState } from 'react';
import './ViewPhotoModal.scss';
import Button from '../../../../../../ui-components/button/Button';
import { StorageReference, deleteObject, getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../../../../..';
import { useAppDispatch } from '../../../../../../redux/hooks';
import { v4 as uuid } from 'uuid';
import { onFileSubmit, uploadPhoto } from '../Utils-Checkbox';
import { BattleType } from '../../../../../../types/BattleType';
import { displayToast } from '../../../../../../helpers/app-helpers';
import dayjs from 'dayjs';
import { DayType } from '../../../../../../types/DayType';

const ViewPhotoModal: React.FC<{
  existingImage: StorageReference;
  photoPath: string;
  isUser: boolean;
  battle: BattleType;
  day?: DayType;
  isBlockTarget: boolean;
  onDelete: () => void;
  onClose: () => void;
  onUpload: (event: string) => void;
}> = ({
  existingImage,
  photoPath,
  isUser,
  battle,
  day,
  isBlockTarget = false,
  onDelete,
  onClose,
  onUpload
}) => {

  const dispatch = useAppDispatch();
  const addPictureRef = useRef<any>();
  const takePhotoRef = useRef<any>();
  const [existingPhotoUrl, setExistingPhotoUrl] = useState<string>('');
  const [newPhoto, setNewPhoto] = useState<any>(null);
  const [tempPhotoURL, setTempPhotoURL] = useState<any>(null);
  const [show2ndTierBtns, setShow2ndTierBtns] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (photoPath) getPhotoUrl();
  }, [photoPath])

  const getPhotoUrl = async() => {
    const pathRef = await ref(storage, photoPath);
    try {
      setExistingPhotoUrl(await getDownloadURL(pathRef));
    } catch (error) {
      console.error(error);
      displayToast(dispatch, 'Photo cannot be downloaded.')
    }
  }

  const onAddPictureClick = () => {
    addPictureRef.current.click();
  }

  const onTakePhotoClick = () => {
    takePhotoRef.current.click();
  }

  const onPictureDelete = async() => {
    const pictureRef = ref(storage, existingImage.toString());
    deleteObject(pictureRef).then((data) => {      
      onDelete();
    }, error => console.error('error', error))
  }

  const onUploadPhoto = async() => {
    setIsUploading(true);
    const uploadedPhoto = await uploadPhoto(newPhoto, uuid, dispatch, battle);
    if (uploadedPhoto) onUpload(uploadedPhoto.metadata.fullPath);
    setIsUploading(false);
    onClose();
  }

  return (
    <>
      <div className="full-screen-mask" onClick={onClose}/>

      <div className="view-photo-modal">
        <div className="view-photo-modal__content">
          <img src={tempPhotoURL ? tempPhotoURL : existingPhotoUrl} alt="Target confirmation" />

          <input
            name="photoInput"
            accept="image/*"
            type="file"
            ref={addPictureRef}
            onChange={(event => onFileSubmit (
              event.target.files?.[0],
              dispatch,
              setNewPhoto,
              setTempPhotoURL
            ))}
          />

          <input
            name="photoInput"
            accept="image/*"
            type="file"
            capture="environment"
            ref={takePhotoRef}
            onChange={(event => onFileSubmit (
              event.target.files?.[0],
              dispatch,
              setNewPhoto,
              setTempPhotoURL
            ))}
          />
        </div>

        {!newPhoto && isUser && !show2ndTierBtns && (
          <div className="photo-input-modal__btns">
            <Button variant="text" onClick={onClose}>Cancel</Button>

            <Button
              style={{color: "var(--red)"}}
              variant="text" onClick={onPictureDelete}
            >Delete</Button>

            {(dayjs(day?.date).isSame(dayjs(), 'day') || isBlockTarget) && <Button variant="text" onClick={() => setShow2ndTierBtns(true)}>Change</Button>}
          </div>
        )}

        {!newPhoto && isUser && show2ndTierBtns && (
          <div className="photo-input-modal__btns">
            <Button variant="text" onClick={onAddPictureClick}>Add From Gallery</Button>

            <Button variant="text" onClick={onTakePhotoClick}>Take Photo</Button>
          </div>
        )}

        {!newPhoto && !isUser && (
          <div className="photo-input-modal__btns">
            <Button variant="text" onClick={onClose}>Close</Button>
          </div>
        )}

        {newPhoto && <p className="photo-input-modal__upload-text">Upload <span>{newPhoto?.name}</span>?</p>}

        {newPhoto && (
          <div className="photo-input-modal__btns">
            <Button variant="text" onClick={() => setNewPhoto(null)}>No</Button>

            <Button
              variant="text"
              isSpinning={isUploading}
              spinnerVariant="LIGHT"
              onClick={onUploadPhoto}
            >Yes</Button>
          </div>
        )}
      </div>
    </>
  )
}

export default ViewPhotoModal;