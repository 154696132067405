import React, { ReactNode } from 'react';
import './CreateBattleCard.scss';

const CreateBattleCard: React.FC<{
  firstLine: string;
  stage?: string;
  children: ReactNode;
}> = ({
  firstLine,
  stage,
  children
}) => {

  return (
    <div className="create-battle-card">
      <h4>{firstLine}</h4>
      {stage && <p className="create-battle-card__stage">Stage {stage}</p>}

      <div className="create-battle-card__content">
        {children}
      </div>
    </div>
  )
}

export default CreateBattleCard;