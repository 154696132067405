import React, { useEffect, useState } from 'react';
import './BattleEntry.scss';
import { BattleType } from '../../../types/BattleType';
import dayjs from 'dayjs';
import exclamationRed from '../../../ui-components/icon/svgs/exclamationRed.svg';
import { BattleStatus } from '../../../enums/enums';
import { useAppSelector } from '../../../redux/hooks';
import { userSelector } from '../../../redux/slices/userSlice';

const BattleEntry: React.FC<{
  battle: BattleType;
  section: 'CURRENT' | 'HISTORY';
  onClick: (event: any) => void;
}> = ({
  battle,
  section,
  onClick
}) => {

  const user = useAppSelector(userSelector);

  const [dateLine, setDateLine] = useState<string>();
  const [showExclamation, setShowExclamation] = useState(false);
  const [vsLine, setVsLine] = useState<any>();
  const [battleTitle, setBattleTitle] = useState<string>('');

  useEffect(() => {
    calcInfo();
  }, [])

  const calcInfo = () => {
    let startDate = dayjs(battle.startDate);
    const opponent = battle.challenger.id === user.id ? battle.opponent : battle.challenger;
    setBattleTitle(`${opponent.username}`)

    if (startDate.isAfter(dayjs(), 'day')) setDateLine(`Start Date: ${dayjs(battle.startDate).format('DD MMM')}`);
    if (startDate.isSame(dayjs(), 'day')) setDateLine('Starts Today!')
    if (startDate.isBefore(dayjs(), 'day')) {
      const diffToStartDate = dayjs().diff(startDate, 'day')

      if (diffToStartDate < battle.numDays && battle.status === BattleStatus.ACTIVE) {
        setDateLine(`Day ${diffToStartDate + 1}`);
      }
      else {
        setDateLine('Finished');
        getAndSetVsLine(true);
      }
    }
    
    if (
      battle.status === BattleStatus.PENDING
      || (section === "CURRENT" && (battle.status === BattleStatus.DECLINED || battle.status === BattleStatus.COMPLETE))
    ) {
      setShowExclamation(true);
    }
  }

  const getAndSetVsLine = (finished: boolean) => {
    const opponent = battle.challenger.id === user.id ? battle.opponent : battle.challenger;

    if (!finished) {
      setVsLine({
        text: `vs ${opponent.username}`,
        color: 'var(--dark)'
      });
      return;
    }

    let result = {
      text: `Draw`,
      color: 'var(--light50)'
    };

    if (battle.status === BattleStatus.DECLINED) {
      result = {
        text: 'Declined',
        color: 'var(--light50)'
      }
    } else if (battle.result.kOWin) {
      if (battle.result.winner.id === user.id) {
        result = {
          text: `Won by KO`,
          color: 'var(--success)'
        };
      } else if (battle.result.winner.id === opponent.id) {
        result = {
          text: `Lost by KO`,
          color: 'var(--red)'
        };
      }
    } else if (battle.result.winner.id === user.id) {
      result = {
        text: `Won`,
        color: 'var(--success)'
      };
    } else if (battle.result.winner.id === opponent.id) {
      result = {
        text: `Lost`,
        color: 'var(--red)'
      };
    }

    setVsLine(result);
  }

  return (
    <div
      className="battle-entry"
      onClick={onClick}
    >
      <h4>{battleTitle}</h4>

      {(battle.status === BattleStatus.DECLINED || showExclamation) && <img src={exclamationRed} alt="Exclamation" />}

      <p style={section === "CURRENT" ? {color: "var(--dark)"} : {color: "var(--light50)"}}>{dateLine}</p>
      {vsLine && <p style={{color: vsLine.color}}>{vsLine.text}</p>}
    </div>
  )
}

export default BattleEntry;