import dayjs from "dayjs";
import { v4 as uuid } from 'uuid';
import { TargetFrequency, TargetType } from "../../../types/TargetType";
import { TargetFormat } from "../../../enums/enums";

export const createDays = (startDate: string, numDays: number, tempTargets: TargetType[], userId: string, opponentId: string) => {
  const targets: any = {};
  let targetNumber = 1;

  for (const target of tempTargets) {
    if (target.frequency === TargetFrequency.DAILY) {
      targets[`target${targetNumber}`] = {
        ...target,
        targetKey: `target${targetNumber}`,
        [userId]: {
          userId: userId,
          input: '',
          photo: '',
          hide: ''
        },
        [opponentId]: {
          userId: opponentId,
          input: '',
          photo: '',
          hide: ''
        },
        id: uuid()
      }
      targetNumber++;
    }
  }

  if (Object.keys(targets).length === 0) return {};

  const days: any = {};
  let date = dayjs(startDate);
  
  let count = 1;
  while(count <= numDays) {
    days[`day${count}`] = {
      date: date.format('YYYY-MM-DD'),
      ...{
        ...targets,
        dayKey: `day${count}`
      }
    }
    count++;
    date = date.add(1, 'day')
  }

  return days;
}

export const createDates = () => {
  const today = dayjs();
  
  const dates = [
    {title: 'Today ' + today.format('DD MMM'), value: today},
    {title: 'Tomorrow ' + dayjs(today).add(1, 'day').format('DD MMM'), value: dayjs(today).add(1, 'day')},
    {title: dayjs(today).add(2, 'day').format('DD MMM'), value: dayjs(today).add(2, 'day')},
    {title: dayjs(today).add(3, 'day').format('DD MMM'), value: dayjs(today).add(3, 'day')},
    {title: dayjs(today).add(4, 'day').format('DD MMM'), value: dayjs(today).add(4, 'day')},
    {title: dayjs(today).add(5, 'day').format('DD MMM'), value: dayjs(today).add(5, 'day')},
    {title: dayjs(today).add(6, 'day').format('DD MMM'), value: dayjs(today).add(6, 'day')},
    {title: dayjs(today).add(7, 'day').format('DD MMM'), value: dayjs(today).add(7, 'day')}
  ];

  return dates;
}

export const checkCanContinue = (errHandler: any, valField?: string) => {
  if (valField && errHandler[valField].criteria) return true;
  else if (!valField) return true; 
  else if (valField && !errHandler[valField].criteria) return false;
}

export const createTempTargetFormatDropdownItem = (format: TargetFormat) => {
  const title = format === TargetFormat.CHECKBOX ? 'Checkbox' : 'Number';
  return {title, value: format};
}

export const createTempTargetFrequencyDropdownItem = (frequency: any) => {
  const title = frequency === TargetFrequency.DAILY ? 'Daily' : 'Block';
  return {title, value: frequency};
}

export const createBlockTargets = (tempTargets: TargetType[], userId: string, opponentId: string) => {
  const targets: any = {};
  let targetNumber = 1;

  for (const target of tempTargets) {
    if (target.frequency === TargetFrequency.BLOCK) {
      targets[`blockTarget${targetNumber}`] = {
        ...target,
        targetKey: `blockTarget${targetNumber}`,
        [userId]: {
          userId: userId,
          input: '',
          photo: '',
          hide: '',
          inputArray: []
        },
        [opponentId]: {
          userId: opponentId,
          input: '',
          photo: '',
          hide: '',
          inputArray: []
        },
        id: uuid()
      }
      targetNumber++;
    }
  }

  return targets;
}