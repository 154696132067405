import React, { ReactNode } from 'react';
import './SpeechCard.scss';
import Button from '../button/Button';
import { updatePersonalUserBoolean } from '../../database/update';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { userSelector } from '../../redux/slices/userSlice';
import mePixel from '../../ui-components/images/mePixel.png';
import { personalUserActions } from '../../redux/slices/personalUserSlice';
import { useNavigate } from 'react-router-dom';

const SpeechCard: React.FC<{
  firstLine: string;
  originPage: 'WELCOME' | 'HOME';
  style?: React.CSSProperties;
  children: ReactNode;
}> = ({
  firstLine,
  originPage,
  style,
  children
}) => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(userSelector);
  

  const onHideMessageClick = () => {
    updatePersonalUserBoolean(user.id, 'hideWelcomeMessage', true);
    dispatch(personalUserActions.hideWelcomeMessage());
  }

  return (
    <div className="speech-card" style={style}>
      <div className="speech-card__top-line">
        <img src={mePixel} alt="Eon Avatar" />
        <h3>{firstLine}</h3>
      </div>

      <div className="speech-card__content">
        {children}
      </div>

      <div className="speech-card__btn-cont">
        {originPage === "HOME" && (
          <Button
            onClick={onHideMessageClick}
          >
            Hide Message
          </Button>
        )}

        {originPage === "WELCOME" && (
          <Button
            onClick={() => navigate("/login")}
          >
            Register/Login
          </Button>
        )}
      </div>
    </div>
  )
}

export default SpeechCard;