import React, { useEffect, useState } from 'react';
import './ProfilePage.scss';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { userSelector } from '../../redux/slices/userSlice';
import Button from '../../ui-components/button/Button';
import { auth } from '../..';
import { battlesSelector } from '../../redux/slices/battlesSlice';
import { BattleStatus } from '../../enums/enums';
import { appDataActions, appDataSelector } from '../../redux/slices/appDataSlice';
import { ReactComponent as IOS  } from '../../ui-components/icon/svgs/iOS.svg';
import { deleteUserAndAccount } from '../../database/delete';
import { personalUserSelector } from '../../redux/slices/personalUserSlice';
import { removeUserFromIDB } from '../../helpers/storage-helpers';
import Disclaimer from '../../pages-ancillary/Disclaimer';
import PrivacyPolicy from '../../pages-ancillary/PrivacyPolicy';
import TermsAndConditions from '../../pages-ancillary/TermsAndConditions';
import { ReactComponent as ChevronRight  } from '../../ui-components/icon/svgs/chevronRight.svg'; 
import { useNavigate } from 'react-router-dom';
import NotificationsModal from './notifications-modal/NotificationsModal';
import Avatar from '../../components/avatar/Avatar';
import { ReactComponent as Edit } from '../../ui-components/icon/svgs/edit.svg';
import CustomiseModal from './customize-modal/CustomizeModal';
import Guide from '../../pages-ancillary/Guide';
import { sendPasswordResetEmail } from 'firebase/auth';
import Input from '../../ui-components/input/Input';
import { displayToast, validateEmail } from '../../helpers/app-helpers';

const ProfilePage = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(userSelector);
  const battles = useAppSelector(battlesSelector);
  const appData = useAppSelector(appDataSelector);
  const personalUser = useAppSelector(personalUserSelector);

  const [results, setResults] = useState<{
    total: number;
    win: number;
    draw: number;
    loss: number;
  }>();
  const [isIOS, setIsIOS] = useState(false);
  const [isDeleteAccountOpen, setIsDeleteModalOpen] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
  const [email, setEmail] = useState<string>();
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [activeView, setActiveView] = useState<'' | 'CUSTOMIZE' | 'NOTIFICATIONS'>('');
  const [showGuide, setShowGuide] = useState(false);


  useEffect(() => {
    setIsIOS(checkIfIOS());
  }, [])

  useEffect(() => {
    calcStats();
  }, [battles])

  const checkIfIOS = (): boolean => {
    // @ts-ignore
    if (navigator.standalone) return false;

    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  const calcStats = () => {
    let resultObj = {
      total: 0,
      win: 0,
      draw: 0,
      loss: 0
    };

    for (const battle of battles) {
      if (battle.status !== BattleStatus.COMPLETE) continue;
      if (battle.result.draw || battle.result.winner.id) resultObj.total++;
      if (battle.result.draw) resultObj.draw++;
      if (battle.result.winner.id === user.id) resultObj.win++;
      if (battle.result.winner.id && battle.result.winner.id !== user.id) resultObj.loss++;
    }

    setResults(resultObj);
  }

  const onLogoutClick = async() => {
    removeUserFromIDB(personalUser);
    await auth.signOut();
  }

  const onDeleteAccountConfirm = async() => {
    const deleted = await deleteUserAndAccount(user, dispatch);
    if (deleted) {
      localStorage.removeItem('pvp-health-local-storage');
      auth.signOut();
    }
  }

  const onChangeConsentPreferencesClick = () => {
    dispatch(appDataActions.setChangeConsentMode(true));
  }

  const onChangePassword = () => {
    if (email && validateEmail(email)) {
      sendPasswordResetEmail(auth, email)
      .then(() => {
        displayToast(dispatch, "Password reset email sent.")
      })
      .catch((error) => {
        displayToast(dispatch, error.message);
      });
    } else {
      displayToast(dispatch, "Please enter a valid email address.")
    }
  }

  const onAncillaryClose = () => {
    setShowPrivacyPolicy(false);
    setShowDisclaimer(false);
    setShowTermsAndConditions(false);
  }

  if (activeView === 'NOTIFICATIONS' && personalUser) return (
    <NotificationsModal
      personalUser={personalUser}
      setActiveView={(event) => setActiveView(event)}
    />
  )

  if (activeView === 'CUSTOMIZE') return (
    <CustomiseModal
      setActiveView={(event) => setActiveView(event)}
    />
  )

  return (
    <div className="profile-page modal">

      {showDisclaimer && <Disclaimer onClose={onAncillaryClose}/>}
      {showPrivacyPolicy && <PrivacyPolicy onClose={onAncillaryClose}/>}
      {showTermsAndConditions && <TermsAndConditions onClose={onAncillaryClose}/>}
      {showGuide && <Guide onClose={() => setShowGuide(false)}/>}

      <div className="profile-page__content">

        <h1>{user.username}</h1>

        <p
          className="profile-page__content__close"
          onClick={() => navigate(-1)}
        >X</p>

        {user.avatar && (<div className="profile-page__content__avatar">
          <Avatar id={user.avatar.id} newColors={user.avatar.colors} />
        </div>)}
        
        <div
          className="profile-page__content__edit"
          onClick={() => setActiveView("CUSTOMIZE")}  
        >
          <Edit fill="var(--light50)" />
          Customise
        </div>

        {results && (
          <div className="profile-page__content__statistics">
            <table>
              <thead>
                <tr>
                  <th>Total Battles</th>
                  <th>Won</th>
                  <th>Tied</th>
                  <th>Lost</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{results.total}</td>
                  <td>{results.win}</td>
                  <td>{results.draw}</td>
                  <td>{results.loss}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>

      <div className="profile-page__content">

        {appData.installPrompt && (
          <div className="profile-page__content__install-btn">
            <Button
              className="profile-page__content__install-btn"
              onClick={() => dispatch(appDataActions.setInstallPWA(true))}
            >
              + Install App
            </Button>
            <p>Installing PvP-Health as an app will improve visual experience and add an icon to your home screen/app launcher for quicker access.</p>
          </div>
        )}

        {isIOS && (
          <div className="profile-page__content__install-btn">
            <h2>Install App?</h2>
            <p>Installing PvP-Health as an app will improve visual experience and add an icon to your home screen/app launcher for quicker access.</p>
            <p>To install on iOS, tap <IOS stroke="var(--light)" className="profile-page__content__install-btn__icon" /> and then "Add to Home Screen".</p>
          </div>
        )}

        {isChangePasswordModalOpen && (
          <>
            <div className="full-screen-mask" />
            <div className="profile-page__content__modal">
              <h2>Change Password</h2>
              <p>To change your password, please enter the email address that you used to sign in.</p>

              <Input
                name="password-change-email"
                variant="DARK"
                style={{marginTop: "12px"}}
                onChange={(event) => setEmail(event?.target.value)}
              />

              <div className="profile-page__content__modal__btns">
                <Button
                  variant="text"
                  onClick={() => setIsChangePasswordModalOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="text"
                  style={{color: "var(--main)"}}
                  onClick={onChangePassword}
                >
                  Send
                </Button>
              </div>
            </div>
          </>
        )}

        {isDeleteAccountOpen && (
          <>
            <div className="full-screen-mask" />
            <div className="profile-page__content__modal">
              <h2>Delete Account</h2>
              <p>Are you sure you want to delete your account? This action cannot be undone.</p>

              <div className="profile-page__content__modal__btns">
                <Button
                  variant="text"
                  style={{color: "var(--red)"}}
                  onClick={onDeleteAccountConfirm}
                >
                  Delete
                </Button>
                <Button
                  variant="text"
                  onClick={() => setIsDeleteModalOpen(false)}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </>
        )}

        <h2>Menu</h2>

        <div className="menu">
          <div className="menu__item" onClick={() => setShowGuide(true)}>
              <p>View the Guide</p>
              <ChevronRight
                fill="var(--light)"
                style={{width: "12px", height: "12px"}}
              />
          </div>
        </div>

        <h2>Account</h2>

        <div className="menu">
          <div className="menu__item" onClick={() => setActiveView("NOTIFICATIONS")}>
              <p>Notifications</p>
              <ChevronRight
                fill="var(--light)"
                style={{width: "12px", height: "12px"}}
              />
          </div>

          <div className="menu__item" onClick={onChangeConsentPreferencesClick}>
              <p>Change Consent Preferences</p>
              <ChevronRight
                fill="var(--light)"
                style={{width: "12px", height: "12px"}}
              />
          </div>

          <div className="menu__item" onClick={() => setIsChangePasswordModalOpen(true)}>
              <p>Change Password</p>
              <ChevronRight
                fill="var(--light)"
                style={{width: "12px", height: "12px"}}
              />
          </div>

          <div className="menu__item" onClick={onLogoutClick}>
              <p>Logout</p>
              <ChevronRight
                fill="var(--light)"
                style={{width: "12px", height: "12px"}}
              />
          </div>

          <div className="menu__item" onClick={() => setIsDeleteModalOpen(true)}>
              <p>Delete Account</p>
              <ChevronRight
                fill="var(--light)"
                style={{width: "12px", height: "12px"}}
              />
          </div>
        </div>

        <div className="cookie-banner__links">
          <p
            onClick={() => setShowPrivacyPolicy(true)}
          >
            Privacy Policy
          </p>
          |
          <p
            onClick={(event) => setShowDisclaimer(true)}
          >
            Disclaimer
          </p>
          |
          <p
            onClick={(event) => setShowTermsAndConditions(true)}
          >
            Terms & Conditions
          </p>
        </div>
        <Button
          onClick={() => navigate(-1)}
          style={{marginTop: "24px", width: "100%"}}
        >Back</Button>
      </div>

    </div>
  )
}

export default ProfilePage;